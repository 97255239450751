/* eslint-disable no-console */
import { useEffect } from 'react';

import mixpanelBrowser from 'mixpanel-browser';

import { mixpanelFake } from './mixpanelFake';

const apiToken = process.env.MIXPANEL_PROJECT_TOKEN;

if (!apiToken) {
  console.error(
    'No Mixpanel API token found.  If this is production, please verify .env file used.'
  );
}

const debug = process.env.ENVIRONMENT !== 'production' && process.env.DEBUG === 'true';

const isMockMixpanel = !apiToken;
const mixpanel = isMockMixpanel ? mixpanelFake : mixpanelBrowser;

mixpanel.init(apiToken, { debug });

mixpanel.pageView = (pageName: string, properties?: Record<string, any>) => {
  mixpanel.track(`Page View: ${pageName}`, properties);
};

function usePageView(
  pageName: string,
  properties?: Record<string, any>,
  dependency: boolean = true
) {
  useEffect(() => {
    if (dependency) {
      mixpanel.pageView(pageName, properties);
    }
  }, [dependency]);
}

function trackEvent(eventTag: string, properties?: Record<string, any>) {
  mixpanel.track(`Event: ${eventTag}`, properties);
}

export { isMockMixpanel, mixpanel, usePageView, trackEvent };
