import * as React from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, CircularProgress, Button } from '@mui/material';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { navigateToUrl } from 'single-spa';
import { makeStyles } from 'tss-react/mui';

import { elapsedTime, getElapsedTimeGroup } from 'utils';

import { useNotificationsContext } from './context';
import { useMarkAllAsRead, useNotificationCounts, useUserNotifications } from './hooks';
import ReibusLogo from './images/ReibusNotificationLogo.svg';
import NotificationItem, { NotificationItemProps } from './NotificationItem';

const useStyles = makeStyles()({
  modalContainer: {
    border: '1px solid #fff',
    backgroundColor: '#fff',
    width: 520,
    maxHeight: 736,
    '& li': {
      listStyle: 'none',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalTitle: {
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    margin: '18.5px 20px 18.5px 16px',
    justifyContent: 'space-between',
  },
  modalButtons: {
    margin: 16,
    '& button': {
      marginLeft: 8,
      borderRadius: 8,
      border: 'none',
      padding: '4px 10px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.16px',
    },
  },
  primaryBtn: {
    backgroundColor: '#0A7371',
    color: 'white',
  },
  secondaryBtn: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  notificationContainer: {
    overflowY: 'scroll',
    borderTop: '1px solid #EEEEEE',
    maxHeight: 600,
    marginTop: 20,
    marginBottom: 20,
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
  interactionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 2,
  },
  markAllAsReadBtn: {
    height: 30,
    marginRight: 15,
    color: '#0D908D',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'initial',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  endMessageWrapper: {
    textAlign: 'center',
    marginTop: '0.5rem',
    marginBottom: '33px',
  },
  endMessageLogo: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  notificationItemWrapper: {
    marginLeft: '22px',
    marginBottom: '22px',
    marginTop: '0.5rem',
  },
});

type NotificationListGroup = {
  [key: string]: NotificationItemProps[];
};

type NotificationListProps = {
  unreadNotifications: NotificationListGroup;
  emptyUnreadMessages: boolean;
  fetchMoreData: () => void;
  hasMore: boolean;
};

const NotificationList = ({
  unreadNotifications,
  emptyUnreadMessages,
  fetchMoreData,
  hasMore,
}: NotificationListProps) => {
  const { t } = useTranslation('notifications');
  const { classes } = useStyles();

  const notifications = Object.keys(unreadNotifications);
  const notificationsLength = notifications.reduce((acc, curr) => {
    return acc + unreadNotifications[curr].length;
  }, 0);

  const endMessage = (
    <div className={classes.endMessageWrapper}>
      <img className={classes.endMessageLogo} src={ReibusLogo} alt={t('logo')} />
      <div>{t('allCaughtUp')}</div>
    </div>
  );

  if (emptyUnreadMessages) {
    return <div className={classes.notificationContainer}>{endMessage}</div>;
  }

  return (
    <div id="scrollableDiv" className={notificationsLength ? classes.notificationContainer : ''}>
      <InfiniteScroll
        dataLength={notificationsLength}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className={classes.spinnerContainer}>
            <CircularProgress />
          </div>
        }
        endMessage={endMessage}
        scrollableTarget="scrollableDiv"
      >
        {notifications.map(key => (
          <div key={key}>
            <div className={classes.notificationItemWrapper}>{t(key)}</div>
            {unreadNotifications[key].map(notification => (
              <NotificationItem key={notification.id} {...notification} />
            ))}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

const NotificationsPanel = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('notifications');
  const {
    data: { unread },
  } = useNotificationCounts();
  const { mutate: markAllAsRead } = useMarkAllAsRead();
  const [notificationView, setNotificationView] = React.useState('all');
  const { closeMenu } = useNotificationsContext();
  const { notifications, hasNextPage, fetchNextPage } = useUserNotifications(notificationView);

  const unreadNotificationsByTime = notifications.reduce((previous, current) => {
    const { unit, elapsedTimeValue } = elapsedTime(parseISO(current.createdAt), new Date());
    const key = getElapsedTimeGroup(elapsedTimeValue, unit);
    const previousNotifications = previous[key]?.length > 0 ? previous[key] : [];
    return {
      ...previous,
      [key]: [
        ...previousNotifications,
        {
          ...current,
          elapsedTimeFormatted: t(`${unit}_ago`, { count: elapsedTimeValue }),
        },
      ],
    };
  }, {});

  const handlerMarkAllAsRead = () => {
    markAllAsRead({ updateType: 'markAllAsRead' });
  };

  const emptyUnreadMessages = unread === 0 && notificationView === 'unread';

  return (
    <div data-testid="notification-dropdown" className={classes.modalContainer}>
      <div className={classes.flexContainer}>
        <div className={classes.modalTitle}>{t('title')}</div>
        <div className={classes.interactionsContainer}>
          <Button
            size="small"
            variant="text"
            onClick={handlerMarkAllAsRead}
            disabled={unread === 0}
            className={classes.markAllAsReadBtn}
          >
            {t('markAllAsRead')}
          </Button>
          <IconButton
            onClick={() => {
              closeMenu();
              navigateToUrl('/app/dashboard/account?selected=notifications');
            }}
            edge="start"
            color="default"
            data-testid="notification-settings-btn"
            size="large"
          >
            <SettingsIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.modalButtons}>
        {['all', 'unread'].map(option => (
          <button
            key={option}
            className={option === notificationView ? classes.primaryBtn : classes.secondaryBtn}
            onClick={() => setNotificationView(option)}
          >
            {t(option)}
          </button>
        ))}
      </div>
      <NotificationList
        unreadNotifications={unreadNotificationsByTime}
        emptyUnreadMessages={emptyUnreadMessages}
        fetchMoreData={fetchNextPage}
        hasMore={hasNextPage === undefined ? true : hasNextPage}
      />
    </div>
  );
};

export default NotificationsPanel;
