import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const getDay = () => {
  const date = new Date();
  const day = date.getDate();
  switch (day) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const getMonth = () => {
  const date = new Date();
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][date.getMonth()];
};

const useStyles = makeStyles()({
  banner: {
    backgroundColor: '#707070',
    height: '33px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const MaintenanceBanner = () => {
  const { t: tMaintenanceBanner } = useTranslation('common');
  // Banner Time shown is set via Launch Darkly
  const { feMaintenanceBannerFlag: bannerTime } = useFlags();
  const { classes } = useStyles();

  if (!bannerTime) {
    return null;
  }

  return (
    <div className={classes.banner}>
      <Typography variant="body2">
        {tMaintenanceBanner('maintenance_banner.message', {
          month: getMonth(),
          day: getDay(),
          time: bannerTime,
        })}
      </Typography>
    </div>
  );
};

export default MaintenanceBanner;
