import { makeStyles } from 'tss-react/mui';

import useForceLogin from 'hooks/useForceLogin';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';

import { useNavContext } from './context';

interface Props {
  label: String;
}

const useStyles = makeStyles()({
  menuItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px 14.5px',
  },
  active: {
    background: '#3b0b31',
  },
  label: {
    color: '#fff',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    cursor: 'pointer',
  },
});

const NoDropDownMenuItem = ({ label }: Props) => {
  const { currentDropdown, setCurrentDropdown } = useNavContext();
  const forceLogin = useForceLogin();
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.menuItem, { [classes.active]: currentDropdown === label })}
      onMouseEnter={() => setCurrentDropdown(label)}
      onMouseLeave={() => setCurrentDropdown(null)}
    >
      <div
        className={classes.label}
        onClick={() => {
          trackEvent(TOP_MENU_CLICK_EVENT, {
            title: label,
            url: 'N/A',
          });
          forceLogin();
        }}
      >
        <span>{label}</span>
      </div>
    </div>
  );
};

export default NoDropDownMenuItem;
