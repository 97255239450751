import React from 'react';

import { useUserSession } from '@reibus/frontend-utility';

const LOGIN_LINK = '/login';
const LIMITED_ACCESS_WHITELIST_REGEX: RegExp[] = [
  /^\/login$/,
  /^\/app\/requests-for-quote\/auction\/.*\/bid\/.*$/,
  /^\/app\/dashboard\/requests-for-quote\/.*\/bid-response(\?.*)?$/,
  /^\/app\/listings\/auction\/.*\/bid\/.*$/,
];

export const checkLAWhiteListLink = (pathname: string) =>
  LIMITED_ACCESS_WHITELIST_REGEX.some(allowedRoute => allowedRoute.test(pathname));

type Props = {
  children: React.ReactNode;
};

export const RedirectLimitedAccessUsers = ({ children }: Props) => {
  const {
    location: { pathname },
  } = window;
  const { data: userSession } = useUserSession();
  const isLimitedAccess = userSession?.cognitoGroups?.includes('limited-access');
  const isAWhitelistLink = checkLAWhiteListLink(pathname);

  if (isLimitedAccess && !isAWhitelistLink) {
    window.location.pathname = LOGIN_LINK;
    return null;
  }

  return children;
};
