import * as React from 'react';

import { useAuthorizationToken } from '@reibus/frontend-utility';
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';

import { getUserNotifications, markAllNotificationsAsRead, markNotificationAsRead } from './api';

export const useNotificationCounts = () => {
  const authToken = useAuthorizationToken();
  const rawUnread = localStorage.getItem('unreadNotificationCount');
  const initialUnread = rawUnread ? Number.parseInt(rawUnread, 10) : 0;

  return useQuery(
    ['notificationCounts'],
    async () => {
      const { unreadNotificationsCount, unviewedNotificationsCount } = await getUserNotifications(
        authToken,
        'all'
      );
      return {
        unread: unreadNotificationsCount as number,
        unviewed: unviewedNotificationsCount as number,
      };
    },
    {
      enabled: !!authToken,
      initialData: {
        unread: initialUnread,
        unviewed: 0,
      },
      staleTime: 0,
      cacheTime: 1000 * 60 * 5,
    }
  );
};

type MarkAsReadRequest = {
  id: string;
  updateType: string;
};

export const useMarkAsRead = () => {
  const { data: currentCounts } = useNotificationCounts();
  const queryClient = useQueryClient();
  const authToken = useAuthorizationToken();

  return useMutation(
    (request: MarkAsReadRequest) => {
      if (authToken) {
        return markNotificationAsRead(authToken, request.id, request.updateType);
      }
    },
    {
      onMutate: async request => {
        await queryClient.cancelQueries('notificationCounts');
        const newUnread = Math.max(currentCounts.unread - 1, 0);
        queryClient.setQueryData('notificationCounts', { ...currentCounts, unread: newUnread });
        localStorage.setItem('unreadNotificationCount', `${newUnread}`);
      },
      onError: (_error, _variables, _context) => {
        queryClient.setQueryData('notificationCounts', currentCounts);
        localStorage.setItem('unreadNotificationCount', `${currentCounts.unread}`);
      },
      retry: 3,
    }
  );
};

type MarkAllAsReadRequest = {
  updateType?: string;
};

export const useMarkAllAsRead = () => {
  const { data: currentCounts } = useNotificationCounts();
  const queryClient = useQueryClient();
  const authToken = useAuthorizationToken();

  return useMutation(
    (request: MarkAllAsReadRequest) => {
      if (authToken) {
        return markAllNotificationsAsRead(authToken, request.updateType);
      }
    },
    {
      onMutate: async request => {
        await queryClient.cancelQueries('notificationCounts');
        queryClient.setQueryData('notificationCounts', { ...currentCounts, unread: 0 });
        localStorage.setItem('unreadNotificationCount', '0');
      },
      onError: (_error, _variables, _context) => {
        queryClient.setQueryData('notificationCounts', currentCounts);
        localStorage.setItem('unreadNotificationCount', `${currentCounts.unread}`);
      },
      retry: 3,
    }
  );
};

type NotificationData = Record<string, any> & { id: string; source: string };

export const useUserNotifications = (viewType: string) => {
  const authToken = useAuthorizationToken();
  const { data, ...props } = useInfiniteQuery(
    ['notifications', viewType],
    ({ pageParam = 1 }) => getUserNotifications(authToken, viewType, pageParam),
    {
      getNextPageParam: result => {
        const queryParams = new URLSearchParams(result.source ?? '');
        const page = parseInt(queryParams.get('page') ?? '1');
        return result.notifications?.length ? page + 1 : null;
      },
      refetchOnWindowFocus: false,
    }
  );
  const pages = data?.pages;

  const notifications: NotificationData[] = React.useMemo(
    () => (pages && pages.length ? pages.flatMap(page => page.notifications || []) : []),
    [pages]
  );

  return { notifications, ...props };
};
