import { useUserSession } from '@reibus/frontend-utility';

import useForceLogin from 'hooks/useForceLogin';

const NonUserValidator = ({ children }) => {
  const { data: userSession } = useUserSession();
  const forceLogin = useForceLogin();

  const redirectIfNotLoggedIn = e => {
    if (userSession) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    localStorage.setItem('verified', 'true');
    forceLogin();
  };

  return <div onClick={redirectIfNotLoggedIn}>{children}</div>;
};

export default NonUserValidator;
