import { FeaturedFormat } from 'types';
import { steel_gradesByFormSubcategoryDesignation } from 'utils/grades/grades';

const multiFieldsToURL = (searchName: string, valueArray: string[]) => {
  const urlArray = valueArray.map((item, i) => {
    return `&${searchName}[${i}]=${item}`;
  });
  return urlArray.join('');
};

type Region = 'NA' | 'EU';

export type LinkNavEntry = {
  title: string;
  url: string;
  euUrl?: string;
  validRegions?: Region | Region[];
};

export type NavEntrySubMenu = {
  title?: string;
  url?: string;
  euUrl?: string;
  subItems: LinkNavEntry[];
};

export type LotNavMenuEntry = {
  title: string;
  format: FeaturedFormat;
  navTitle?: LinkNavEntry[];
  subGroups?: NavEntrySubMenu[];
  col1?: LinkNavEntry[];
  col2?: LinkNavEntry[];
  link?: string;
};

export type RfqNavMenuEntry = {
  title: string;
  subGroups: NavEntrySubMenu[];
  link?: string;
};

export type RecyclablesMenuEntry = {
  title: string;
  link: string;
};

export type NavMenuData = {
  steel: LotNavMenuEntry[];
  aluminum: LotNavMenuEntry[];
  stainless: LotNavMenuEntry[];
  copper: LotNavMenuEntry[];
  brass: LotNavMenuEntry[];
  bronze: LotNavMenuEntry[];
  recyclables: RecyclablesMenuEntry[];
  rfqs: RfqNavMenuEntry[];
};

export const navMenuData: NavMenuData = {
  steel: [
    {
      title: 'steelCoils',
      format: 'COIL',
      navTitle: [
        {
          title: 'allSteelCoils',
          url: '/app/listings/steel/coil',
        },
      ],
      subGroups: [
        {
          title: 'coated',
          url: '/app/listings/steel/coil/coated/',
          subItems: [
            { title: 'galvanized', url: '/app/listings/steel/coil/galvanized/' },
            { title: 'galvalume', url: '/app/listings/steel/coil/galvalume/' },
            { title: 'galvanneal', url: '/app/listings/steel/coil/galvanneal/' },
            { title: 'electrogalvanized', url: '/app/listings/steel/coil/electrogalvanized/' },
            { title: 'aluminized', url: '/app/listings/steel/coil/aluminized/' },
            { title: 'zincMagnesium', url: '/app/listings/steel/coil/zm/', validRegions: 'EU' },
            { title: 'jetVaporDeposit', url: '/app/listings/steel/coil/jvd/', validRegions: 'EU' },
          ],
        },
        {
          title: 'uncoated',
          url: '/app/listings/steel/coil/uncoated/',
          subItems: [
            { title: 'hotRoll', url: '/app/listings/steel/coil/hr/' },
            { title: 'hotRollP&O', url: '/app/listings/steel/coil/hrpo/' },
            { title: 'coldRoll', url: '/app/listings/steel/coil/cr/' },
            { title: 'floorPlate', url: '/app/listings/steel/coil/floor-plate/' },
          ],
        },
        {
          title: 'painted',
          url: '/app/listings/steel/coil/painted/',
          subItems: [
            { title: 'galvanized', url: '/app/listings/steel/coil/painted/galvanized/' },
            { title: 'galvalume', url: '/app/listings/steel/coil/painted/galvalume/' },
            { title: 'hotRoll', url: '/app/listings/steel/coil/painted/hr/' },
          ],
        },
      ],
    },
    {
      title: 'steelSheets',
      format: 'SHEET',
      navTitle: [
        {
          title: 'allSteelSheets',
          url: '/app/listings/steel/sheet',
        },
      ],
      subGroups: [
        {
          title: 'coated',
          url: '/app/listings/steel/sheet/coated/',
          subItems: [
            { title: 'galvanized', url: '/app/listings/steel/sheet/galvanized/' },
            { title: 'galvalume', url: '/app/listings/steel/sheet/galvalume/' },
            { title: 'galvanneal', url: '/app/listings/steel/sheet/galvanneal/' },
            { title: 'electrogalvanized', url: '/app/listings/steel/sheet/electrogalvanized/' },
            { title: 'aluminized', url: '/app/listings/steel/sheet/aluminized/' },
            { title: 'zincMagnesium', url: '/app/listings/steel/sheet/zm/', validRegions: 'EU' },
            { title: 'jetVaporDeposit', url: '/app/listings/steel/sheet/jvd/', validRegions: 'EU' },
          ],
        },
        {
          title: 'uncoated',
          url: '/app/listings/steel/sheet/uncoated/',
          subItems: [
            { title: 'hotRoll', url: '/app/listings/steel/sheet/hr/' },
            { title: 'hotRollP&O', url: '/app/listings/steel/sheet/hrpo/' },
            { title: 'coldRoll', url: '/app/listings/steel/sheet/cr/' },
            { title: 'floorPlate', url: '/app/listings/steel/sheet/floor-plate/' },
          ],
        },
        {
          title: 'painted',
          url: '/app/listings/steel/sheet/painted/',
          subItems: [
            { title: 'galvanized', url: '/app/listings/steel/sheet/painted/galvanized/' },
            { title: 'galvalume', url: '/app/listings/steel/sheet/painted/galvalume/' },
            { title: 'hotRoll', url: '/app/listings/steel/sheet/painted/hr/' },
          ],
        },
      ],
    },
    {
      title: 'steelPlates',
      format: 'PLATE',
      navTitle: [
        {
          title: 'allSteelPlates',
          url: '/app/listings/steel/plate',
        },
      ],
      subGroups: [
        {
          subItems: [
            {
              title: 'astmA36',
              url: '/app/listings/steel/plate/hr/a36',
            },
            {
              title: 'astmA516',
              url: '/app/listings/steel/plate/hr/a516',
            },
            {
              title: 'astmA572',
              url: '/app/listings/steel/plate/hr/a572',
            },
            {
              title: 'astmA573',
              url: '/app/listings/steel/plate/hr/a573',
            },
            {
              title: 'astmA588',
              url: '/app/listings/steel/plate/hr/a588',
            },
            {
              title: 'astmA656',
              url: '/app/listings/steel/plate/hr/a656',
            },
            {
              title: 'abrasionResistant',
              url: '/app/listings/steel/plate/hr/abrasion-resistant',
            },
          ],
        },
      ],
    },
  ],
  aluminum: [
    {
      title: 'aluminumCoils',
      format: 'COIL',
      navTitle: [
        {
          title: 'allAluminumCoils',
          url: '/app/listings/aluminum/coil',
        },
      ],
      subGroups: [
        {
          title: 'millFinish',
          url: '/app/listings/aluminum/coil/mill-finish/?&painted=false',
          subItems: [
            { title: 'series1000', url: '/app/listings/aluminum/coil/mill-finish/1000-series/' },
            { title: 'series2000', url: '/app/listings/aluminum/coil/mill-finish/2000-series/' },
            { title: 'series3000', url: '/app/listings/aluminum/coil/mill-finish/3000-series/' },
            { title: 'series4000', url: '/app/listings/aluminum/coil/mill-finish/4000-series/' },
            { title: 'series5000', url: '/app/listings/aluminum/coil/mill-finish/5000-series/' },
            { title: 'series6000', url: '/app/listings/aluminum/coil/mill-finish/6000-series/' },
            { title: 'series7000', url: '/app/listings/aluminum/coil/mill-finish/7000-series/' },
            {
              title: 'secondary',
              url: '/app/listings/aluminum/coil/mill-finish/secondary/?&f=lotType:SE&painted=false',
            },
          ],
        },
        {
          title: 'painted',
          url: '/app/listings/aluminum/coil/painted/?&painted=true',
          subItems: [
            {
              title: 'domesticSource',
              url: '/app/listings/aluminum/coil/painted/domestic/?&f=originCountry:USA&painted=true',
            },
            {
              title: 'secondary',
              url: '/app/listings/aluminum/coil/painted/secondary/?&f=lotType:SE&painted=true',
            },
          ],
        },
      ],
    },
    {
      title: 'aluminumSheets',
      format: 'SHEET',
      navTitle: [
        {
          title: 'allAluminumSheets',
          url: '/app/listings/aluminum/sheet',
        },
      ],
      subGroups: [
        {
          title: 'millFinish',
          url: '/app/listings/aluminum/sheet/mill-finish/?&painted=false',
          subItems: [
            { title: 'series1000', url: '/app/listings/aluminum/sheet/mill-finish/1000-series/' },
            { title: 'series2000', url: '/app/listings/aluminum/sheet/mill-finish/2000-series/' },
            { title: 'series3000', url: '/app/listings/aluminum/sheet/mill-finish/3000-series/' },
            { title: 'series4000', url: '/app/listings/aluminum/sheet/mill-finish/4000-series/' },
            { title: 'series5000', url: '/app/listings/aluminum/sheet/mill-finish/5000-series/' },
            { title: 'series6000', url: '/app/listings/aluminum/sheet/mill-finish/6000-series/' },
            { title: 'series7000', url: '/app/listings/aluminum/sheet/mill-finish/7000-series/' },
            {
              title: 'secondary',
              url: '/app/listings/aluminum/sheet/mill-finish/secondary/?&f=lotType:SE&painted=false',
            },
          ],
        },
        {
          title: 'painted',
          url: '/app/listings/aluminum/sheet/painted/?&painted=true',
          subItems: [
            {
              title: 'domesticSource',
              url: '/app/listings/aluminum/sheet/painted/domestic/?&f=originCountry:USA&painted=true',
            },
            {
              title: 'secondary',
              url: '/app/listings/aluminum/sheet/painted/secondary/?&f=lotType:SE&painted=true',
            },
          ],
        },
      ],
    },
    {
      title: 'aluminumPlates',
      format: 'PLATE',
      navTitle: [
        {
          title: 'allAluminumPlates',
          url: '/app/listings/aluminum/plate',
        },
      ],
      subGroups: [],
    },
  ],
  stainless: [
    {
      title: 'stainlessCoils',
      format: 'COIL',
      navTitle: [
        {
          title: 'allStainlessCoils',
          url: '/app/listings/stainless/coil',
        },
      ],
      col1: [
        {
          title: 'series200/300',
          url: '/app/listings/stainless/coil/200-300-series/',
          validRegions: 'NA',
        },
        {
          title: 'series400',
          url: '/app/listings/stainless/coil/400-series/',
          validRegions: 'NA',
        },
        {
          title: 'martensitics',
          url: '/app/listings/stainless/coil/martensitics/',
        },
        {
          title: 'precipitationHardening',
          url: '/app/listings/stainless/coil/precipitation-hardening/',
        },
        {
          title: 'duplex',
          url: '/app/listings/stainless/coil/duplex/',
        },
        {
          title: 'alloys',
          url: '/app/listings/stainless/coil/alloys/',
        },
      ],
      col2: [
        {
          title: 'secondary',
          url: '/app/listings/stainless/coil/secondary/',
        },
        {
          title: 'domestic',
          url: '/app/listings/stainless/coil/domestic/',
        },
      ],
    },
    {
      title: 'stainlessSheets',
      format: 'SHEET',
      navTitle: [
        {
          title: 'allStainlessSheets',
          url: '/app/listings/stainless/sheet',
        },
      ],
      col1: [
        {
          title: 'series200/300',
          url: '/app/listings/stainless/sheet/200-300-series/',
          validRegions: 'NA',
        },
        {
          title: 'series400',
          url: '/app/listings/stainless/sheet/400-series/',
          validRegions: 'NA',
        },
        {
          title: 'martensitics',
          url: '/app/listings/stainless/sheet/martensitics/',
        },
        {
          title: 'precipitationHardening',
          url: '/app/listings/stainless/sheet/precipitation-hardening/',
        },
        {
          title: 'duplex',
          url: '/app/listings/stainless/sheet/duplex-alloy/',
        },
        {
          title: 'alloys',
          url: '/app/listings/stainless/sheet/alloys/',
        },
      ],
      col2: [
        {
          title: 'secondary',
          url: '/app/listings/stainless/sheet/secondary/',
        },
        {
          title: 'domestic',
          url: '/app/listings/stainless/sheet/domestic/',
        },
      ],
    },
    {
      title: 'stainlessPlates',
      format: 'PLATE',
      navTitle: [
        {
          title: 'allStainlessPlates',
          url: '/app/listings/stainless/plate',
        },
      ],
      col1: [
        {
          title: 'series200/300',
          url: '/app/listings/stainless/plate/200-300-series/',
          validRegions: 'NA',
        },
        {
          title: 'series400',
          url: '/app/listings/stainless/plate/400-series/',
          validRegions: 'NA',
        },
        {
          title: 'martensitics',
          url: '/app/listings/stainless/plate/martensitics/',
        },
        {
          title: 'precipitationHardening',
          url: '/app/listings/stainless/plate/precipitation-hardening/',
        },
        {
          title: 'duplex',
          url: '/app/listings/stainless/plate/duplex/',
        },
        {
          title: 'alloys',
          url: '/app/listings/stainless/plate/alloys/',
        },
      ],
      col2: [
        {
          title: 'secondary',
          url: '/app/listings/stainless/plate/secondary/',
        },
        {
          title: 'domestic',
          url: '/app/listings/stainless/plate/domestic/',
        },
      ],
    },
  ],
  rfqs: [
    {
      title: 'steelRFQs',
      subGroups: [
        {
          title: 'steelCoils',
          url: '/app/requests-for-quote/?category=steel&format[0]=coil',
          subItems: [
            {
              title: 'coated',
              url: '/app/requests-for-quote/?category=steel&format[0]=coil&subcategory[0]=galvanneal&subcategory[1]=galvanized&subcategory[2]=electrogalvanized&subcategory[3]=aluminized&subcategory[4]=galvalume',
              euUrl:
                '/app/requests-for-quote/?category=steel&format[0]=coil&subcategory[0]=galvanneal&subcategory[1]=galvanized&subcategory[2]=electrogalvanized&subcategory[3]=aluminized&subcategory[4]=galvalume&subcategory[5]=zm&subcategory[6]=jvd',
            },
            {
              title: 'uncoated',
              url: '/app/requests-for-quote/?category=steel&format[0]=coil&subcategory[0]=hr&subcategory[1]=hrpo&subcategory[2]=cr&subcategory[3]=floor_plate',
            },
            {
              title: 'painted',
              url: '/app/requests-for-quote/?category=steel&painted=painted&format[0]=coil',
            },
          ],
        },
        {
          title: 'steelSheets',
          url: '/app/requests-for-quote/?category=steel&format[0]=sheet',
          subItems: [
            {
              title: 'coated',
              url: '/app/requests-for-quote/?category=steel&format[0]=sheet&subcategory[0]=galvanneal&subcategory[1]=galvanized&subcategory[2]=electrogalvanized&subcategory[3]=aluminized&subcategory[4]=galvalume',
              euUrl:
                '/app/requests-for-quote/?category=steel&format[0]=sheet&subcategory[0]=galvanneal&subcategory[1]=galvanized&subcategory[2]=electrogalvanized&subcategory[3]=aluminized&subcategory[4]=galvalume&subcategory[5]=zm&subcategory[6]=jvd',
            },
            {
              title: 'uncoated',
              url: '/app/requests-for-quote/?category=steel&format[0]=sheet&subcategory[0]=hr&subcategory[1]=hrpo&subcategory[2]=cr&subcategory[3]=floor_plate',
            },
            {
              title: 'painted',
              url: '/app/requests-for-quote/?category=steel&painted=painted&format[0]=sheet',
            },
          ],
        },
        {
          title: 'steelPlates',
          url: '/app/requests-for-quote/?category=steel&format[0]=plate',
          subItems: [],
        },
      ],
    },
    {
      title: 'aluminumRFQs',
      subGroups: [
        {
          title: 'aluminumCoil',
          url: '/app/requests-for-quote/?category=aluminum&format[0]=coil',
          subItems: [
            {
              title: 'millFinish',
              url: '/app/requests-for-quote/?category=aluminum&painted=unpainted&format[0]=coil',
            },
            {
              title: 'painted',
              url: '/app/requests-for-quote/?category=aluminum&painted=painted&format[0]=coil',
            },
          ],
        },
        {
          title: 'aluminumSheet',
          url: '/app/requests-for-quote/?category=aluminum&format[0]=sheet',
          subItems: [
            {
              title: 'millFinish',
              url: '/app/requests-for-quote/?category=aluminum&painted=unpainted&format[0]=sheet',
            },
            {
              title: 'painted',
              url: '/app/requests-for-quote/?category=aluminum&painted=painted&format[0]=sheet',
            },
          ],
        },
        {
          title: 'aluminumPlates',
          url: '/app/requests-for-quote/?category=aluminum&format[0]=Plate',
          subItems: [],
        },
      ],
    },
    {
      title: 'stainlessRFQs',
      subGroups: [
        {
          title: 'stainlessCoil',
          url: '/app/requests-for-quote/?category=stainless&format[0]=coil',
          subItems: [
            {
              title: 'series200/300',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.austenitic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'series400',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.ferritic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'martensitics',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.martensitic ?? []
              )}`,
            },
            {
              title: 'precipitationHardening',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.[
                  'precipitation hardening'
                ] ?? []
              )}`,
            },
            {
              title: 'duplex',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.duplex ?? []
              )}`,
            },
            {
              title: 'alloys',
              url: `/app/requests-for-quote/?category=stainless&format[0]=coil${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.COIL.STAINLESS?.alloys ?? []
              )}`,
            },
            {
              title: 'secondary',
              url: '/app/requests-for-quote/?category=stainless&format[0]=coil&lotType[0]=secondary',
            },
          ],
        },
        {
          title: 'stainlessSheet',
          url: '/app/requests-for-quote/?category=stainless&format[0]=sheet',
          subItems: [
            {
              title: 'series200/300',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.austenitic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'series400',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.ferritic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'martensitics',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.martensitic ?? []
              )}`,
            },
            {
              title: 'precipitationHardening',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.[
                  'precipitation hardening'
                ] ?? []
              )}`,
            },
            {
              title: 'duplex',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.duplex ?? []
              )}`,
            },
            {
              title: 'alloys',
              url: `/app/requests-for-quote/?category=stainless&format[0]=sheet${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.SHEET.STAINLESS?.alloys ?? []
              )}`,
            },
            {
              title: 'secondary',
              url: '/app/requests-for-quote/?category=stainless&format[0]=sheet&lotType[0]=secondary',
            },
          ],
        },
        {
          title: 'stainlessPlates',
          url: '/app/requests-for-quote/?category=stainless&format[0]=plate',
          subItems: [
            {
              title: 'series200/300',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.austenitic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'series400',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.ferritic ?? []
              )}`,
              validRegions: ['NA'],
            },
            {
              title: 'martensitics',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.martensitic ?? []
              )}`,
            },
            {
              title: 'precipitationHardening',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.[
                  'precipitation hardening'
                ] ?? []
              )}`,
            },
            {
              title: 'duplex',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.duplex ?? []
              )}`,
            },
            {
              title: 'alloys',
              url: `/app/requests-for-quote/?category=stainless&format[0]=plate${multiFieldsToURL(
                'materialGrade',
                steel_gradesByFormSubcategoryDesignation.PLATE.STAINLESS?.alloys ?? []
              )}`,
            },
            {
              title: 'secondary',
              url: '/app/requests-for-quote/?category=stainless&format[0]=plate&lotType[0]=secondary',
            },
          ],
        },
      ],
    },
  ],
};
