import type { GradeCategories } from '../types';

export const coldRolledSteel: Partial<GradeCategories> = {
  gmw2m: ['GMW2M-ST-S-CR1', 'GMW2M-ST-S-CR2', 'GMW2M-ST-S-CR3', 'GMW2M-ST-S-CR4', 'GMW2M-ST-S-CR5'],
  'gmw3032m bake hardened / dent resistant': [
    'GMW3032M-ST-S-CR180B0',
    'GMW3032M-ST-S-CR180B2',
    'GMW3032M-ST-S-CR180P',
    'GMW3032M-ST-S-CR210B0',
    'GMW3032M-ST-S-CR210B2',
    'GMW3032M-ST-S-CR210P',
    'GMW3032M-ST-S-CR240B0',
    'GMW3032M-ST-S-CR240B2',
    'GMW3032M-ST-S-CR270B0',
    'GMW3032M-ST-S-CR270B2',
    'GMW3032M-ST-S-CR300B0',
    'GMW3032M-ST-S-CR300B2',
  ],
  'gmw3032m hsla': [
    'GMW3032M-ST-S-CR270LA',
    'GMW3032M-ST-S-CR300LA',
    'GMW3032M-ST-S-CR340LA',
    'GMW3032M-ST-S-CR380LA',
    'GMW3032M-ST-S-CR500LA',
    'GMW3032M-ST-S-CR550LA',
    'GMW3032M-ST-S-CR4200LA',
  ],
  'gmw3399m dual phase': [
    'GMW3399M-ST-S-CR450T/250Y-DP',
    'GMW3399M-ST-S-CR490T/290Y-DP',
    'GMW3399M-ST-S-CR590T/280Y-DP',
    'GMW3399M-ST-S-CR590T/340Y-DP',
    'GMW3399M-ST-S-CR590T/420Y-DP',
    'GMW3399M-ST-S-CR690T/550Y-DP',
    'GMW3399M-ST-S-CR780T/420Y-DP',
    'GMW3399M-ST-S-CR780T/500Y-DP',
    'GMW3399M-ST-S-CR980T/550Y-DP',
    'GMW3399M-ST-S-CR980T/650Y-DP',
  ],
  'gmw3399m trip': [
    'GMW3399M Cold Rolled Trip Steel',
    'GMW3399M-ST-S-CR690T/410Y-TR',
    'GMW3399M-ST-S-CR780T/440Y-TR',
  ],
  'gmw3399m martensitic': [
    'GMW3399M-ST-S-CR900T/700Y-MS',
    'GMW3399M-ST-S-CR1100T/860Y-MS',
    'GMW3399M-ST-S-CR1300T/1030Y-MS',
    'GMW3399M-ST-S-CR1500T/1200Y-MS',
  ],
  'ms-264 cold rolled': [
    'MS264-025HK',
    'MS264-025SK',
    'MS264-030HK',
    'MS264-030SK',
    'MS264-035HK',
    'MS264-035SK',
    'MS264-040HK',
    'MS264-040SK',
    'MS264-045HK',
    'MS264-045XK',
    'MS264-050XK',
    'MS264-060XK',
    'MS264-070XK',
    'MS264-080XK',
    'MS264-120XK',
    'MS264-190MT',
    'MS264-220MT',
    'MS264-490DT',
    'MS264-590DT',
    'MS264-590PT',
    'MS264-590TT',
    'MS264-780DT',
    'MS264-780PT',
    'MS264-780TT',
  ],
  'ms cold rolled': [
    'MS-67',
    'MS-210A',
    'MS-210B',
    'MS-501A',
    'MS-501B',
    'MS-2485A',
    'MS-2485B',
    'MS-3414',
  ],
  'ms.50002 cold rolled': [
    'MS.50002 BHC180Y290T',
    'MS.50002 BHC210Y310T',
    'MS.50002 BHC240Y340T',
    'MS.50002 BHC280Y3800T',
    'MS.50002 CR01',
    'MS.50002 CR02',
    'MS.50002 CR04',
    'MS.50002 CR05',
    'MS.50002 CR06',
    'MS.50002 DPC290Y490T',
    'MS.50002 DPC340Y590T',
    'MS.50002 DPC420Y780T HF',
    'MS.50002 DPC420Y780T',
    'MS.50002 DPC550Y980T',
    'MS.50002 DPC700Y980T',
    'MS.50002 DPC950Y1180T',
    'MS.50002 DPH330Y580T',
    'MS.50002 FBC440Y580T',
    'MS.50002 LAC210Y310T',
    'MS.50002 LAC240Y320T',
    'MS.50002 LAC270Y330T',
    'MS.50002 LAC300Y370T',
    'MS.50002 LAC340Y410T',
    'MS.50002 LAC380Y450T',
    'MS.50002 LAC420Y480T',
    'MS.50002 LAC500Y560T',
    'MS.50002 LAC550Y620T',
    'MS.50002 LAC830Y860T',
    'MS.50002 MTC1030Y1300T',
    'MS.50002 MTC1200Y1500T',
    'MS.50002 TRC400Y690T',
    'MS.50002 TRC440Y780T',
  ],
  'wsb-m1a215 hsla': [
    'WSB-M1A215-F1 Grade 220',
    'WSB-M1A215-F1 Grade 250',
    'WSB-M1A215-F1 Grade 300',
    'WSB-M1A215-F1 Grade 350',
    'WSB-M1A215-F1 Grade 400',
    'WSB-M1A215-F1 Grade 450',
    'WSB-M1A215-F1 Grade 500',
    'WSB-M1A215-F1 Grade 550',
    'WSB-M1A215-F1 Grade 600',
    'WSB-M1A215-F1 Grade 700',
    'WSB-M1A215-F1 Grade 800',
    'WSB-M1A215-F1 Grade 1000',
  ],
  'wse-m1a321 laminate sound deadening vibration damping': [
    'WSD-M1A337-A1',
    'WSD-M1A337-A2',
    'WSD-M1A337-A3',
    'WSD-M1A337-A4',
    'WSD-M1A337-A5',
    'WSE-M1A321-A1',
    'WSE-M1A321-A2',
  ],
  'wsd-m1a333 cold rolled': ['WSD-M1A333-A2', 'WSD-M1A333-A3', 'WSD-M1A333-A4'],
  'wss-m1a341 bake hardened / dent resistant': [
    'WSS-M1A341-A1',
    'WSS-M1A341-A2',
    'WSS-M1A341-A3',
    'WSS-M1A341-A4',
    'WSS-M1A341-A5',
    'WSS-M1A341-A6',
    'WSS-M1A341-A7',
    'WSS-M1A341-A8',
    'WSS-M1A341-A9',
    'WSS-M1A341-A10',
  ],
  'wss-m1a345 cold rolled': ['WSS-M1A345-A1', 'WSS-M1A345-A2', 'WSS-M1A345-A3', 'WSS-M1A345-A4'],
  'wss-m1a347 cold rolled hsla': [
    'WSS-M1A347-A1',
    'WSS-M1A347-A2',
    'WSS-M1A347-A3',
    'WSS-M1A347-A4',
    'WSS-M1A347-A5',
    'WSS-M1A347-A6',
  ],
  'wss-m1a348 cold rolled dual phase': [
    'WSS-M1A348-A1',
    'WSS-M1A348-A2',
    'WSS-M1A348-A3',
    'WSS-M1A348-A4',
    'WSS-M1A348-A5',
    'WSS-M1A348-A6',
    'WSS-M1A348-A7',
    'WSS-M1A348-A8',
    'WSS-M1A348-A9',
  ],
  'wss-m1a351 cold rolled trip': ['WSS-M1A351-A1', 'WSS-M1A351-A2', 'WSS-M1A351-A3'],
  'wss-m1a368 cold rolled': [
    'WSS-M1A368-A11',
    'WSS-M1A368-A13',
    'WSS-M1A368-A14',
    'WSS-M1A368-A15',
    'WSS-M1A368-A21',
    'WSS-M1A368-A22',
    'WSS-M1A368-A23',
    'WSS-M1A368-A24',
    'WSS-M1A368-A25',
    'WSS-M1A368-A31',
    'WSS-M1A368-A32',
    'WSS-M1A368-A41',
    'WSS-M1A368-A42',
    'WSS-M1A368-A43',
    'WSS-M1A368-A51',
    'WSS-M1A368-A52',
    'WSS-M1A368-A53',
    'WSS-M1A368-A61',
    'WSS-M1A368-A62',
    'WSS-M1A368-A71',
    'WSS-M1A368-A72',
    'WSS-M1A368-A81',
    'WSS-M1A368-A82',
    'WSS-M1A368-A83',
    'WSS-M1A368-A84',
    'WSS-M1A368-A91',
    'WSS-M1A368-A92',
    'WSS-M1A368-Al2',
  ],
  'wss-m1a367 cold rolled': [
    'WSS-M1A367-A11',
    'WSS-M1A367-A13',
    'WSS-M1A367-A14',
    'WSS-M1A367-A21',
    'WSS-M1A367-A22',
    'WSS-M1A367-A23',
    'WSS-M1A367-A24',
    'WSS-M1A367-A25',
    'WSS-M1A367-A33',
    'WSS-M1A367-A34',
    'WSS-M1A367-A35',
    'WSS-M1A367-A36',
    'WSS-M1A367-A37',
    'WSS-M1A367-A38',
    'WSS-M1A367-A39',
    'WSS-M1A367-A40',
    'WSS-M1A367-A44',
    'WSS-M1A367-A45',
    'WSS-M1A367-A46',
    'WSS-M1A367-A47',
    'WSS-M1A367-A48',
    'WSS-M1A367-A49',
    'WSS-M1A367-A50',
    'WSS-M1A367-Al2',
  ],
  'wss-m1a365 cold rolled': [
    'WSS-M1A365-A1l',
    'WSS-M1A365-A13',
    'WSS-M1A365-A14',
    'WSS-M1A365-A15',
    'WSS-M1A365-Al2',
  ],
  'astm1008 cold rolled': [
    'ASTM1008-BHS26',
    'ASTM1008-BHS31',
    'ASTM1008-BHS35',
    'ASTM1008-BHS41',
    'ASTM1008-BHS44',
    'ASTM1008-CS TYPE A',
    'ASTM1008-CS TYPE B',
    'ASTM1008-CS TYPE C',
    'ASTM1008-DDS',
    'ASTM1008-DS TYPE A',
    'ASTM1008-DS TYPE B',
    'ASTM1008-EDDS',
    'ASTM1008-HSLAS-F50',
    'ASTM1008-HSLAS-F60',
    'ASTM1008-HSLAS-F70',
    'ASTM1008-HSLAS-F80',
    'ASTM1008-HSLAS45 CLASS 1,2',
    'ASTM1008-HSLAS50 CLASS 1,2',
    'ASTM1008-HSLAS55 CLASS 1,2',
    'ASTM1008-HSLAS60 CLASS 1,2',
    'ASTM1008-HSLAS65 CLASS 1,2',
    'ASTM1008-HSLAS70 CLASS 1,2',
    'ASTM1008-SS25',
    'ASTM1008-SS30',
    'ASTM1008-SS33 TYPE 1,2',
    'ASTM1008-SS40 TYPE 1,2',
    'ASTM1008-SS50',
    'ASTM1008-SS60',
    'ASTM1008-SS70',
    'ASTM1008-SS80',
  ],
  'astm 506 cold rolled': ['ASTM 506 4130', 'ASTM 506 4140', 'ASTM 506 4150'],
  'astm 507 cold rolled': ['ASTM 507 4130', 'ASTM 507 4140', 'ASTM 507 4150'],
  'tsg3100g toyota jis': [
    'SPC270A',
    'SPC270BH',
    'SPC270C',
    'SPC270D',
    'SPC270E',
    'SPC270F',
    'SPC340',
    'SPC340BH',
    'SPC340HR',
    'SPC370',
    'SPC370HR',
    'SPC390',
    'SPC390HR',
    'SPC440',
    'SPC440HR',
    'SPC490',
    'SPC490HR',
    'SPC540',
    'SPC590',
    'SPC590DU',
    'SPC590HR',
    'SPC780DU',
    'SPC980DU',
    'SPC1180DU',
  ],
  'm2020 nissan mild': ['SP129', 'SP121', 'SP122', 'SP123', 'SP124', 'SP125'],
  'm2032 nissan jis': [
    'SP131-340',
    'SP131-370',
    'SP132-340',
    'SP132-370',
    'SP135-340',
    'SP151-540',
    'SP151-590',
    'SP152-440',
    'SP153-590',
    'SP153-780',
    'SP153-980',
    'SP153-1180',
    'SP153Z-590',
    'SP154-590',
  ],
  'ms82-1212 mitsubishi jis': [
    'MJSC270C',
    'MJSC270D',
    'MJSC270E',
    'MJSC270F',
    'MJSC270G',
    'MJSC270H',
    'MJSC340H',
    'MJSC340P',
    'MJSC340W',
    'MJSC370P',
    'MJSC390P',
    'MJSC390W',
    'MJSC440P',
    'MJSC440W',
    'MJSC590R',
    'MJSC590Y',
    'MJSC780Y',
    'MJSC980Y',
    'MJSC1180Y',
  ],
  'ms121-64, ms121-65 hyundai/kia jis': [
    'SPC-BH',
    'SPCC',
    'SPCD',
    'SPCE / SPCEN',
    'SPCSD',
    'SPCUD',
    'SPRC 340-BH',
    'SPRC 340',
    'SPRC 340E',
    'SPRC 340ES',
    'SPRC 370',
    'SPRC 390',
    'SPRC 390HR',
    'SPRC 440',
    'SPRC 440E',
    'SPRC 490',
    'SPRC 490DP',
    'SPRC 540',
    'SPRC 590',
    'SPRC 590DP',
    'SPRC 590RA',
    'SPRC 780',
    'SPRC 980',
    'SPRC 1180',
    'SPRC 1180Y',
  ],
  'gis 1503 subaru jis': [
    'SPC260G',
    'SPC270C',
    'SPC270D',
    'SPC270E',
    'SPC270F',
    'SPC270H',
    'SPC340C',
    'SPC340H',
    'SPC340P',
    'SPC370C',
    'SPC370P',
    'SPC390C',
    'SPC390P',
    'SPC440C',
    'SPC440P',
    'SPC490',
    'SPC540R',
    'SPC590C',
    'SPC590P',
    'SPC590Y',
    'SPC780Y',
    'SPC980Y',
    'SPC1180',
  ],
  'c052-04 honda jis ': [
    'JSC260G',
    'JSC270**B**',
    'JSC270A',
    'JSC270C',
    'JSC270D',
    'JSC270E',
    'JSC270F',
    'JSC340H',
    'JSC340P',
    'JSC340W',
    'JSC370P',
    'JSC370W',
    'JSC390P',
    'JSC390W',
    'JSC440P',
    'JSC440W',
    'JSC490W',
    'JSC590R',
    'JSC590Y',
    'JSC780Y',
    'JSC980Y',
    'JSC1180Y',
  ],
  'din en10130 cold rolled': ['DC01', 'DC03', 'DC04', 'DC05', 'DC06', 'DC07'],
  'din en10336 cold rolled with higher yield': ['HC600X'],
  'din en10338 cold rolled multiphase': [
    'HCT450X',
    'HCT500X',
    'HCT600C',
    'HCT600X',
    'HCT690T',
    'HCT780C',
    'HCT780T',
    'HCT780X',
    'HCT980C',
    'HCT980X',
  ],
  'din en10268 cold rolled with higher yield': [
    'HC180B',
    'HC180P',
    'HC220B',
    'HC220I',
    'HC220P',
    'HC220Y',
    'HC260B',
    'HC260I',
    'HC260LA',
    'HC260P',
    'HC260Y',
    'HC300B',
    'HC300I',
    'HC300LA',
    'HC300P',
    'HC340LA',
    'HC380LA',
    'HC420LA',
  ],
  'volkswagen vw 50060-1 dual-phase': [
    'VW 50060 HC260X',
    'VW 50060 HC300X',
    'VW 50060 HC340X',
    'VW 50060 HC450X',
    'VW 50060 HC600X',
    'VW 50060 HC660X',
  ],
  'volkswagen vw 50060-3 complex phase': ['VW 50060 HC500C', 'VW 50060 HC600C', 'VW 50060 HC660C'],
  'volkswagen vw 50060-5 trip': ['VW 50060 HC410T', 'VW 50060 HC470T'],
  'tesla mild steel': [
    'TM-2011 CR1-140-270-S',
    'TM-2011 CR2-140-270-S',
    'TM-2011 CR3-140-270-S',
    'TM-2011 CR4-140-270-S',
    'TM-2011 CR5-110-260-S',
  ],
  'tesla hsla': [
    'TM-2011 CR-340-410-LA-S',
    'TM-2011 CR-420-480-LA-S',
    'TM-2011 CR-460-520-LA-S',
    'TM-2011 CR-500-560-LA-S',
    'TM-2011 CR-550-650-LA-S',
  ],
  'tesla bake hardenable': [
    'TM-2011 CR-180-290-BH-S',
    'TM-2011 CR-240-340-BH-S',
    'TM-2011 CR-300-390-BH-S',
  ],
  'tesla dual phase': [
    'TM-2011 CR-290-490-DP-S',
    'TM-2011 CR-330-590-DP-S',
    'TM-2011 CR-440-780-DP-S',
    'TM-2011 CR-590-980-DP-S',
    'TM-2011 CR-700-980-DP-S',
    'TM-2011 CR-900-1180-DP-S',
    'TM-2011 CR-1150-1270-DP-S',
  ],
  'tesla martensite': ['TM-2011 CR-1200-1500-MS-S', 'TM-2011 CR-1400-1700-MS-S'],
  'tesla trip steel': [
    'TM-2011 CR-400-690-TR',
    'TM-2011 CR-450-780-TR',
    'TM-2011 CR-600-980-TR',
    'TM-2011 CR-850-1180-TR',
  ],
  'tesla complex phase': [
    'TM-2011 CR-570-780-CP',
    'TM-2011 CR-600-980-CP',
    'TM-2011 CR-700-1050-CP',
    'TM-2011 CR-850-1180-CP',
  ],
};
