import { capitalizeFirstLetter, DeploymentRegion } from '@reibus/reibus-core-utils';

import { euSteel_gradesWithAutomotive } from './euGrades';
import { steel_gradesWithAutomotive } from './gradesAutomotive';

import type { FormatKey, GradeCategories, GradeKey, SteelGrades } from './types';

export const steel_gradesByFormSubcategoryDesignation = {
  COIL: {
    HR: {
      'astm a36': ['A36'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR38',
        'SS GR40',
        'SS GR44',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR65',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR42',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '5140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600', 'DP 780'],
    },
    HRPO: {
      'astm a36': ['A36'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR40',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR80',
      ],
      'high strength low alloy': [
        // "HSLA GR40",
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600'],
    },
    CR: {
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': ['SS GR25', 'SS GR30', 'SS GR33', 'SS GR40', 'SS GR50', 'SS GR80'],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': ['BH 180', 'BH 210', 'BH 250', 'BH 280', 'BH 300', 'BH 340'],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 690', 'DP 780', 'DP 980', 'DP 1180'],
    },
    GALVANIZED: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR60',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
    },
    ELECTROGALVANIZED: {
      'commercial / drawing steel': ['CS', 'DS', 'EDDS', 'IF'],
      'high strength low alloy': ['HSLA GR36', 'HSLA GR40', 'HSLA GR45', 'HSLA GR50', 'HSLA GR80'],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 220',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 690', 'DP 700', 'DP 780', 'DP 980', 'DP 1180'],
    },
    GALVANNEAL: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': [
        'DP 260',
        'DP 450',
        'DP 590',
        'DP 600',
        'DP 780',
        'DP 800',
        'DP 980',
        'DP 1180',
      ],
    },
    GALVALUME: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'IF'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40', 'SS GR50', 'SS GR55', 'SS GR80'],
      'sae j403 (carbon grades)': ['1040'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    ALUMINIZED: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40'],
      'high strength low alloy': ['HSLA GR45', 'HSLA GR50', 'HSLA GR60'],
      'sae j403 (carbon grades)': ['1040'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    STAINLESS: {
      ferritic: [
        '409',
        '409 UF®',
        '409 Ni',
        '410',
        '410S',
        '11 Cr-Cb™',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        // 'THERMAK® 17',
        '420',
        '430',
        '430 UF®',
        '430Ti',
        '434',
        '435M',
        '436',
        // "436 / 435 Mod",
        '439',
        '441',
        '13-4 SR®',
        '3Cr12',
        // '18 SR®',
        // '436L UF®',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
        '410 Cb',
      ],
      austenitic: [
        'NITRONIC® 30',
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304L',
        '304/304L',
        '304H',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '348',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'PH 15-7 Mo®',
        // 'Other'
      ],
      duplex: [
        // 'NITRONIC® 19D',
        'LDX2101',
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'Nit19D',
      ],
      alloys: [
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
  },
  SHEET: {
    HR: {
      'astm a36': ['A36'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR38',
        'SS GR40',
        'SS GR44',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR65',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR42',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '5140', '6150'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780'],
    },
    HRPO: {
      'astm a36': ['A36'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR40',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR80',
      ],
      'high strength low alloy': [
        // "HSLA GR40",
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '6150'],
      'dual phase': ['DP 590', 'DP 600'],
    },
    CR: {
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': ['SS GR25', 'SS GR30', 'SS GR33', 'SS GR40', 'SS GR50', 'SS GR80'],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'bake hardenable': ['BH 180', 'BH 210', 'BH 250', 'BH 280', 'BH 300', 'BH 340'],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 690', 'DP 780', 'DP 980', 'DP 1180'],
    },
    GALVANIZED: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR60',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
    },
    ELECTROGALVANIZED: {
      'commercial / drawing steel': ['CS', 'DS', 'EDDS', 'IF'],
      'high strength low alloy': ['HSLA GR36', 'HSLA GR40', 'HSLA GR45', 'HSLA GR50', 'HSLA GR80'],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 220',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 690', 'DP 700', 'DP 780', 'DP 980', 'DP 1180'],
    },
    GALVANNEAL: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '10B38',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
    },
    GALVALUME: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'IF'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40', 'SS GR50', 'SS GR55', 'SS GR80'],
      'sae j403 (carbon grades)': ['1040'],
    },
    ALUMINIZED: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40'],
      'high strength low alloy': ['HSLA GR45', 'HSLA GR50', 'HSLA GR60'],
      'sae j403 (carbon grades)': ['1040'],
    },
    STAINLESS: {
      ferritic: [
        '409',
        '409 UF®',
        '409 Ni',
        '410',
        '410S',
        '11 Cr-Cb™',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        // 'THERMAK® 17',
        '430',
        '430 UF®',
        '430Ti',
        '434',
        '435M',
        '436',
        // "436 / 435 Mod",
        '439',
        '441',
        '13-4 SR®',
        '3Cr12',
        // '18 SR®',
        // '436L UF®',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
        '410 Cb',
      ],
      austenitic: [
        'NITRONIC® 30',
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304L',
        '304/304L',
        '304H',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '348',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'PH 15-7 Mo®',
        // 'Other'
      ],
      duplex: [
        // 'NITRONIC® 19D',
        'LDX2101',
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'Nit19D',
      ],
      alloys: [
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
  },
  PLATE: {
    HR: {
      'astm a36': ['A36', 'A36 MOD'],
      'astm a514': ['A514 Gr B', 'A514 Gr S'],
      'astm a516': ['GR55', 'GR60', 'GR65', 'GR70'],
      'astm a572': ['GR42', 'GR50', 'GR60', 'GR65'],
      'astm a573': ['GR65', 'GR70', 'GR70N'],
      'astm a588': ['Grade A', 'Grade B', 'Grade C'],
      'astm a656': ['GR50', 'GR60', 'GR70', 'GR80', 'GR100'],
      'astm a786': ['A786'],
      'abrasion resistant': [
        'AR 200',
        'AR 235',
        'AR 400',
        'AR 450',
        'AR 500',
        'AR 550',
        'AR 600',
        'AR 650',
        'AR200',
        'AR235',
        'AR400',
        'AR450',
        'AR500',
        'AR550',
        'AR600',
        'AR650',
      ],
      'sae j403 (carbon grades)': ['1020', '1045', '1060'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    STAINLESS: {
      ferritic: [
        '409',
        '409 UF®',
        '409 Ni',
        '410',
        '410S',
        '11 Cr-Cb™',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        // 'THERMAK® 17',
        '430',
        '430 UF®',
        '430Ti',
        '434',
        '435M',
        '436',
        // "436 / 435 Mod",
        '439',
        '441',
        '13-4 SR®',
        '3Cr12',
        // '18 SR®',
        // '436L UF®',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
        '410 Cb',
      ],
      austenitic: [
        'NITRONIC® 30',
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304L',
        '304/304L',
        '304H',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '321H',
        '347',
        '347H',
        '348',
        '904L',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'PH 15-7 Mo®',
        // 'Other'
      ],
      duplex: [
        // 'NITRONIC® 19D',
        'LDX2101',
        '2205 Duplex',
        '2202 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'Nit19D',
      ],
      alloys: [
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
  },
};

export const grades_by_popularity: GradeKey[] = [
  'CS',
  'CQ',
  'CSB',
  'SS GR30',
  'SS GR33',
  'SS GR37',
  'SS GR40',
  'SS GR50',
  'SS GR55',
  'SS GR60',
  'SS GR70',
  'SS GR80',
  'FS-B',
  'EDDS',
  'XDDS',
  'DDS',
  'IF',
  'DS/DQ',
  'DS',
  'DQ',
  'TISTDQ',
  '1001',
  '1006',
  '1008',
  '1010',
  '1010M',
  '1015',
  '1017',
  '1018',
  'SAE 1018',
  '1020',
  '1025',
  '1026',
  '1035',
  '1040',
  '1045',
  '1050',
  '1070',
  '1075',
  '1095',
  'HSLA GR35',
  'HSLA GR40',
  'HSLA GR45',
  'HSLA GR50',
  'HSLA GR55',
  'HSLA GR60',
  'HSLA GR65',
  'HSLA GR70',
  'HSLA GR80',
  'A36',
  'A514H',
  'A588',
  'A606.4',
  'AJ04',
  'A516-70N',
  'A572-50',
  'A51670AR',
  'A514S',
  'A514B',
  'A633C',
  'AR400',
  'AR450',
  'A514Q',
  'A572-55',
  'A656-80',
  'AR500',
];

export const grades_sorted_by_hardness: GradeKey[] = [
  'EDDS',
  'XDDS',
  'DDS',
  'IF',
  'DS/DQ',
  'TISTDQ',
  'DSB',
  'CS',
  'CQ',
  'CSB',
  'FSB',
  '1006',
  '1006CQ',
  '1008',
  '1010',
  '1010M',
  '1015mod',
  '1017',
  '1018',
  'SAE 1018',
  '1020',
  '1025',
  '1026',
  '1045',
  'GR30',
  'GR33',
  '33MAX',
  '1035',
  '1040',
  'GR40',
  'HSLA45',
  'GR50',
  'HSLA50',
  'HSLA55',
  'GR55',
  'GR60',
  'HSLA60',
  'HSLA65',
  'GR70',
  'HSLA70',
  'GR80',
  'HSLA80',
  'HS',
  'HSLA',
  'SS',
  'SS439 2D',
  '130KSI',
  '060XK',
  '340XF',
  'CAT 1E0577',
  'A36',
  'A514H',
  'A588',
  'A606.4',
  'AJ04',
  'A516-70N',
  'A572-50',
  'A51670AR',
  'A514S',
  'A514B',
  'A633C',
  'AR400',
  'AR450',
  'A514Q',
  'A572-55',
  'A656-80',
  'AR500',
];

export const getStainlessGradeFilters = (format: FormatKey, pageFilter) => {
  const stainlessGradesObj: GradeCategories =
    steel_gradesByFormSubcategoryDesignation[format].STAINLESS;
  // if pageFilter does not contain any numbers then it will be the whole family of grades
  if (!pageFilter.match(/(\d+)/)) {
    return { materialGrade: stainlessGradesObj[pageFilter] };
  }

  // convert pageFilter to array
  const filterArray = pageFilter.length > 1 ? pageFilter.split('-') : [pageFilter];

  const checkGradeForFilterRequirements = grade => {
    // grade must start with the same number of at least one of the pagefilters and must be 3 digits long
    const hasFilterRequirements = filterArray.findIndex(
      num => grade.startsWith(num) && grade.match(/(\d+)/)[0].length === 3
    );
    return hasFilterRequirements !== -1;
  };

  const gradesToFilterBy: GradeKey[] = [];
  const gradeFamilies = Object.values(stainlessGradesObj);

  gradeFamilies.forEach(family => {
    const familyGradesToFilterBy = family.filter(checkGradeForFilterRequirements);
    gradesToFilterBy.push(...familyGradesToFilterBy);
  });

  return { materialGrade: gradesToFilterBy };
};

// Cleanup
export const getGradeStyles = (format, subcategory: string | string[], grades) => {
  const resolvedSteelGrades =
    process.env.REGION === 'NA' ? steel_gradesWithAutomotive : euSteel_gradesWithAutomotive;
  let gradeStyleOptions: [string, unknown][] = [];
  if (resolvedSteelGrades[format?.toUpperCase()]) {
    // Items like structural LPs and rebar have grades but no subcategory
    const gradeCategory = Array.isArray(subcategory)
      ? subcategory[0].toUpperCase()
      : subcategory?.toUpperCase() || 'STAINLESS';
    gradeStyleOptions = Object.entries(resolvedSteelGrades[format?.toUpperCase()][gradeCategory]);
  }
  return (
    gradeStyleOptions
      // @ts-ignore
      .filter(([, value]) => value.map(grade => grade).some(grade => grades.includes(grade)))
      .map(gradeStyle => {
        const [key] = gradeStyle;
        return key;
      })
  );
};

export const getSteelGradeFilters = (format: FormatKey, pageFilter) => {
  const steelGradesObj = steel_gradesByFormSubcategoryDesignation[format].HR[pageFilter];
  return { materialGrade: steelGradesObj };
};

export const casedGradesForRender = (arrayOfGrades: GradeKey[]) => {
  const checkForCapCase = val =>
    val.toUpperCase().includes('GRADE') ||
    val.toUpperCase().includes('DUPLEX') ||
    val.toUpperCase().includes('ALLOY');
  return arrayOfGrades.map(value => {
    // @ts-ignore
    if (value === 'No Grade Pipe') {
      return value;
    }
    if (checkForCapCase(value)) {
      return value
        .split(' ')
        .map(word => (checkForCapCase(word) ? capitalizeFirstLetter(word) : word.toUpperCase()))
        .join(' ');
    }
    return value.toUpperCase();
  });
};

export const gradesByDeploymentRegion: Record<DeploymentRegion, SteelGrades> = {
  NA: steel_gradesWithAutomotive,
  EU: euSteel_gradesWithAutomotive,
};
