import type { GradeCategories } from '../types';

export const hotRolledGradesSteel: Partial<GradeCategories> = {
  'gmw2m hot rolled': ['GMW2M-ST-S-HR0', 'GMW2M-ST-S-HR1', 'GMW2M-ST-S-HR2', 'GMW2M-ST-S-HR3'],
  'gmw3032m hot rolled hsla': [
    'GMW3032M-ST-S-HR240LA',
    'GMW3032M-ST-S-HR270LA',
    'GMW3032M-ST-S-HR300LA',
    'GMW3032M-ST-S-HR340LA',
    'GMW3032M-ST-S-HR380LA',
    'GMW3032M-ST-S-HR420LA',
    'GMW3032M-ST-S-HR500LA',
    'GMW3032M-ST-S-HR550LA',
    'GMW3032M-ST-S-HR650LA',
    'GMW3032M-ST-S-HR700LA',
  ],
  'gmw3399m hot rolled dual phase': [
    'GMW3399M-ST-S-HR580T/340Y-DP',
    'GMW3399M-ST-S-HR780T/450Y-DP',
  ],
  'gmw3399m hot rolled martensitic': [
    'GMW3399M-ST-S-HR1200T/900Y-MS',
    'GMW3399M-ST-S-HR1400T/1050Y-MS',
  ],
  'ms-264 hot rolled': [
    'MS264-025SK',
    'MS264-030SK',
    'MS264-035SK',
    'MS264-040SK',
    'MS264-045XK',
    'MS264-050XK',
    'MS264-060XK',
    'MS264-070XK',
    'MS264-080XK',
    'MS264-120XK',
  ],
  'ms hot rolled': ['MS-501A', 'MS-501B', 'MS-3413', 'MS-2485A', 'MS-2485B', 'MS-210A', 'MS-210B'],
  'ms.50002 hot rolled': [
    'MS.50002 FBH440Y580T',
    'MS.50002 HR11',
    'MS.50002 HR12',
    'MS.50002 HR13',
    'MS.50002 LAH210Y310T',
    'MS.50002 LAH240Y320T',
    'MS.50002 LAH270Y330T',
    'MS.50002 LAH300Y370T',
    'MS.50002 LAH340Y410T',
    'MS.50002 LAH380Y450T',
    'MS.50002 LAH420Y480T',
    'MS.50002 LAH500Y560T',
    'MS.50002 LAH550Y620T',
  ],
  'wsb-m1a215 hot rolled hsla': [
    'WSB-M1A215-E1 Grade 250',
    'WSB-M1A215-E1 Grade 300',
    'WSB-M1A215-E1 Grade 350',
    'WSB-M1A215-E1 Grade 400',
    'WSB-M1A215-E1 Grade 450',
    'WSB-M1A215-E1 Grade 500',
    'WSB-M1A215-E1 Grade 550',
    'WSB-M1A215-E1 Grade 700',
    'WSB-M1A215-E1 Grade 1000',
  ],
  'wsd-m1a333 hot rolled low carbon': ['WSD-M1A333-A1'],
  'wss-m1a344 hot rolled low carbon': ['WSS-M1A344-A1', 'WSS-M1A344-A2'],
  'wss-m1a346 hot rolled hsla': [
    'WSS-M1A346-A1',
    'WSS-M1A346-A2',
    'WSS-M1A346-A3',
    'WSS-M1A346-A4',
    'WSS-M1A346-A5',
    'WSS-M1A346-A6',
  ],
  'wss-m1a356 hot rolled': ['WSS-M1A356-A1', 'WSS-M1A356-A2', 'WSS-M1A356-A3'],
  'wss-m1a368 hot rolled - advanced high strength': [
    'WSS-M1A368-A31',
    'WSS-M1A368-A32',
    'WSS-M1A368-A41',
    'WSS-M1A368-A42',
    'WSS-M1A368-A43',
    'WSS-M1A368-A71',
    'WSS-M1A368-A72',
    'WSS-M1A368-A91',
    'WSS-M1A368-A92',
  ],
  'wss-m1a367 hot rolled high strength': [
    'WSS-M1A367-A44',
    'WSS-M1A367-A45',
    'WSS-M1A367-A46',
    'WSS-M1A367-A47',
    'WSS-M1A367-A48',
    'WSS-M1A367-A49',
    'WSS-M1A367-A50',
  ],
  'wss-m1a365 hot rolled': ['WSS-M1A365-A20', 'WSS-M1A365-A21', 'WSS-M1A365-A22', 'WSS-M1A365-A23'],
  'astm1011 hot rolled': [
    'ASTM1011-CS TYPE A',
    'ASTM1011-CS TYPE B',
    'ASTM1011-CS TYPE C',
    'ASTM1011-CS TYPE D',
    'ASTM1011-DS TYPE A',
    'ASTM1011-DS TYPE B',
    'ASTM1011-HSLAS-F50',
    'ASTM1011-HSLAS-F60',
    'ASTM1011-HSLAS-F70',
    'ASTM1011-HSLAS-F80',
    'ASTM1011-HSLAS45 CLASS 1,2',
    'ASTM1011-HSLAS50 CLASS 1,2',
    'ASTM1011-HSLAS55 CLASS 1,2',
    'ASTM1011-HSLAS60 CLASS 1,2',
    'ASTM1011-HSLAS65 CLASS 1,2',
    'ASTM1011-HSLAS70 CLASS 1,2',
    'ASTM1011-SS30',
    'ASTM1011-SS33',
    'ASTM1011-SS36 TYPE 1,2',
    'ASTM1011-SS40',
    'ASTM1011-SS45',
    'ASTM1011-SS50',
    'ASTM1011-SS55',
    'ASTM1011-SS60',
    'ASTM1011-SS70',
    'ASTM1011-SS80',
    'ASTM1011-UHSS90',
    'ASTM1011-UHSS100',
  ],
  'astm 506 hot rolled': ['ASTM 506 4130', 'ASTM 506 4140', 'ASTM 506 4150'],
  'astm 507 hot rolled': ['ASTM 507 4130', 'ASTM 507 4140', 'ASTM 507 4150'],
  'tsg3100g toyota hot rolled jis': [
    'SPH270C',
    'SPH270D',
    'SPH270E',
    'SPH310',
    'SPH370',
    'SPH400',
    'SPH440',
    'SPH440HY',
    'SPH440SF',
    'SPH490',
    'SPH490HY',
    'SPH490SF',
    'SPH540',
    'SPH540HY',
    'SPH590DU',
    'SPH590SF',
    'SPH780',
    'SPH780DU',
    'SPH780SF',
  ],
  'm2032 nissan hot rolled jis': [
    'SP211',
    'SP212',
    'SP221P',
    'SP222P',
    'SP223P',
    'SP231-370',
    'SP231-440',
    'SP231-440F',
    'SP251-540',
    'SP252-540',
    'SP253-590',
    'SP254-590',
  ],
  'ms82-1218 mitsubishi hot rolled jis': [
    'MJSH270C',
    'MJSH270D',
    'MJSH270E',
    'MJSH310W',
    'MJSH370W',
    'MJSH400J',
    'MJSH400W',
    'MJSH440A',
    'MJSH440B',
    'MJSH440J',
    'MJSH440R',
    'MJSH440W',
  ],
  'ms121-30, ms121-63 hyundai/kia hot rolled jis': [
    'SAPH 310',
    'SAPH 370',
    'SAPH 400',
    'SAPH 440',
    'SAPH 540',
    'SAPH 590',
    'SAPH 590DP',
    'SAPH 780',
    'SAPH 780DP',
    'SPHC',
    'SPHD',
    'SPHE',
  ],
  'gis 1503 subaru hot rolled jis': [
    'SPH270C',
    'SPH270D',
    'SPH270E',
    'SPH370W',
    'SPH400W',
    'SPH440W',
    'SPH490W',
    'SPH540W',
    'SPH540Y',
    'SPH590R',
    'SPH590Y',
    'SPH780R',
    'SPH780Y',
  ],
  'c071-04 honda hot rolled jis': [
    'JSH270D',
    'JSH270E',
    'JSH270H',
    'JSH310P',
    'JSH390P',
    'JSH390W',
    'JSH400W',
    'JSH490W',
    'JSH540P',
    'JSH540W',
    'JSH590R',
    'JSH780R',
    'JSH780Y',
  ],
  'din en 10111 hot rolled': ['DC11', 'DC12', 'DC13', 'DC14'],
  'din en 10149 hot rolled high strength': [
    'S315MC',
    'S355MC',
    'S420MC',
    'S460MC',
    'S500MC',
    'S550MC',
    'S600MC',
    'S650MC',
    'S700MC',
  ],
  'din en 10025 hot rolled structural': ['S185', 'S235JR', 'S275JR', 'S355JR'],
  'din en 10338 hot rolled multi-phase': [
    'HDT450F',
    'HDT560F',
    'HDT580X',
    'HDT750C',
    'HDT780C',
    'HDT950C',
    'HDT1200M',
  ],
  'volkswagen vw 50060-2 hot rolled dual-phase': ['VW 50060 HD330X'],
  'volkswagen vw 50060-4 hot rolled complex phase': [
    'VW 50060 HD620C',
    'VW 50060 HD680C',
    'VW 50060 HD720C',
    'VW 50060 HD900M',
  ],
  'tesla hot rolled pickled (mild)': ['TM-2011 HR2-180-270-S', 'TM-2011 HR3-180-270-S'],
  'tesla hot rolled pickled (hsla)': [
    'TM-2011 HR-340-420-LA-S',
    'TM-2011 HR-420-480-LA-S',
    'TM-2011 HR-460-520-LA-S',
    'TM-2011 HR-500-560-LA-S',
    'TM-2011 HR-550-610-LA-S',
    'TM-2011 HR-650-700-LA-S',
    'TM-2011 HR-700-750-LA-S',
  ],
  'tesla hot rolled pickled (dual phase)': ['TM-2011 HR-330-580-DP-S'],
  'tesla hot rolled pickled (martensite)': ['TM-2011 HR-900-1180-MS-S'],
  'tesla hot rolled pickled (complex phase)': ['TM-2011 HR-660-760-CP'],
};
