import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import Link from 'components/SingleSpaLink';

import { SmallDropDown } from './SmallDropDownV2';

const useStyles = makeStyles()(() => ({
  logout: {
    cursor: 'pointer',
  },
}));

interface LogOutDropdownProps {
  onClickAway: (e) => void;
}

const LogOutDropdown = ({ onClickAway }: LogOutDropdownProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation('dashboard-nav');

  return (
    <ul aria-label="Navigation Menu" onClick={onClickAway}>
      <li>
        <Link to="/logout">
          <Typography className={classes.logout}>{t('logout')}</Typography>
        </Link>
      </li>
    </ul>
  );
};

export default SmallDropDown(LogOutDropdown);
