import { useState } from 'react';

import { ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useCurrentUser } from '@reibus/frontend-utility';
import { useTranslation } from 'react-i18next';
import { navigateToUrl } from 'single-spa';
import { makeStyles } from 'tss-react/mui';

import SingleSpaLink from 'components/SingleSpaLink';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';

const useDropdownStyles = makeStyles()(() => ({
  noMargin: {
    margin: 0,
  },
  link: {
    textDecoration: 'none',
    padding: '15px 0',
    height: '100%',
    color: '#4f4f4f',
    width: '100%',
    '&:hover': {
      color: '#5d0549',
      textDecoration: 'none',
    },
  },
  tab: {
    margin: 0,
    padding: '0 22px',
    minWidth: '229px',
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#4f4f4f',
  },
  primaryTab: {
    '&:hover': {
      backgroundColor: '#f2f2f2',
      cursor: 'pointer',
      color: '#5d0549',
    },
  },
  secondaryTab: {
    '&:hover': {
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
  },
  expandable: {
    '&:hover': {
      border: '1px solid #707070',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  dropdownExtension: {
    display: 'none',
    position: 'absolute',
    minWidth: '229px',
    height: '140px',
    backgroundColor: '#f2f2f2',
    zIndex: 2,
    left: '100%',
    top: 0,
    width: '100%',
    minHeight: '100%',
    padding: '13px 0',
    borderLeft: '1px solid #707070',
  },
  lineBlocker: {
    display: 'none',
    width: '1px',
    height: '46px',
    position: 'absolute',
    right: '-1px',
    backgroundColor: '#f2f2f2',
    zIndex: 2,
  },
}));

type ChildObject = {
  title: string;
  link: string;
};

type Props = {
  expandable: boolean;
  link: string;
  title: string;
  childrenObjs?: ChildObject[];
  displayAsLink: boolean;
};

export const ExpandedNavTab = ({ expandable, link, title, childrenObjs, displayAsLink }: Props) => {
  const { data: currentUser } = useCurrentUser();
  const isLoggedIn = !!currentUser;
  const { classes, cx } = useDropdownStyles();
  const { t } = useTranslation('dashboard-nav');
  const [hover, setHover] = useState(false);

  return (
    <>
      <li
        className={cx(classes.tab, classes.primaryTab, { [classes.expandable]: expandable })}
        onClick={
          !expandable && !displayAsLink
            ? () => {
                trackEvent(TOP_MENU_CLICK_EVENT, { title, url: link });
                navigateToUrl(link);
              }
            : undefined
        }
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {displayAsLink ? (
          <SingleSpaLink
            to={link}
            className={classes.link}
            onClick={() => trackEvent(TOP_MENU_CLICK_EVENT, { title, url: link })}
          >
            <Typography className={classes.noMargin} variant="h6">
              {title}
            </Typography>
          </SingleSpaLink>
        ) : (
          <Typography className={classes.noMargin} variant="h6">
            {title}
          </Typography>
        )}
        {expandable ? <ChevronRight fontSize="small" /> : null}
        <div
          className={classes.dropdownExtension}
          style={{ display: hover && expandable ? 'block' : 'none' }}
        >
          {childrenObjs?.map(({ title, link }, index) => {
            return (
              <Typography
                key={index}
                onClick={() => {
                  trackEvent(TOP_MENU_CLICK_EVENT, { title, url: link });
                  navigateToUrl(isLoggedIn ? link : '/login', {
                    state: {
                      from: link,
                    },
                  });
                }}
                className={cx(classes.tab, classes.secondaryTab)}
              >
                {t(title)}
              </Typography>
            );
          })}
        </div>
        <div
          className={classes.lineBlocker}
          style={{ display: hover && expandable ? 'block' : 'none' }}
        />
      </li>
    </>
  );
};
