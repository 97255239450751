import { Typography } from '@mui/material';
import { useUserPreferences, useCurrentUser } from '@reibus/frontend-utility';
import {
  convertMeasurements,
  createRFQTitle,
  displayFormattingValue,
  tFormatUnits,
  valuesFormatList,
} from '@reibus/reibus-core-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { navigateToUrl } from 'single-spa';
import { makeStyles } from 'tss-react/mui';

import PrimeBadge from 'components/icons/PrimeBadge';
import { FeaturedListing, CountryCode, ShapeType, Subcategory } from 'types';
import { calculateOriginLocation } from 'utils/calculateOriginLocation';

const useStyles = makeStyles()({
  singleLine: {
    display: 'flex',
    marginBottom: '0',
  },
  subtitle1: {
    fontSize: '14px',
    color: '#090a0dde',
    fontWeight: 700,
    marginBottom: '0',
  },
  subtitle2: {
    fontSize: '14px',
    color: '#090a0dde',
    marginBottom: '0',
  },
  body1: {
    fontSize: '14px',
    color: '#090a0dde',
  },
  body2: {
    fontSize: '14px',
    color: '#828282',
    marginRight: '3px',
  },
  button: {
    fontSize: '14px',
    color: '#811869',
    fontWeight: 900,
    textTransform: 'capitalize',
  },
  h6: {
    color: '#090a0dde',
  },
  featuredProduct: {
    margin: '14px 16px',
  },
  featuredProduct__cardWrapper: {
    display: 'flex',
  },
  featuredProduct__card: {
    width: '220px',
    height: '184px',
    padding: '16px',
    border: '1px solid #eeeeee',
    borderRadius: '8px',
    marginRight: '16px',
    cursor: 'pointer',
  },
  featuredProduct__card__contents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
});

type FeaturedProductProps = {
  featuredListings: FeaturedListing[];
  isListing?: boolean;
};

const FeaturedProduct = ({ featuredListings, isListing = false }: FeaturedProductProps) => {
  const { t } = useTranslation(['dashboard-nav', 'units', 'listings']);
  const { t: tEnums } = useTranslation('enums');
  const { shippingLocation, measurementSystem } = useUserPreferences();
  const { data: currentUser } = useCurrentUser();
  const isLoggedIn = !!currentUser;

  const { classes } = useStyles();
  const handleCardClick = (url: string) => {
    navigateToUrl(isLoggedIn ? url : '/login', {
      state: {
        from: window.location.pathname,
      },
    });
  };
  const featuredWithoutRestrictedExport = featuredListings.filter(
    ({ restrictedExportList = [] }) => {
      return !restrictedExportList.includes(shippingLocation as CountryCode);
    }
  );
  const { generalizeLocationInEuListingsToPreserveAnonymity = false } = useFlags();
  const isEu = process.env.REGION === 'EU';

  return featuredWithoutRestrictedExport.length ? (
    <div className={classes.featuredProduct}>
      <Typography className={classes.h6} variant="h6">
        {t('dashboard-nav:featured.title')}
      </Typography>
      <div className={classes.featuredProduct__cardWrapper}>
        {featuredWithoutRestrictedExport.map(
          (
            {
              category,
              format,
              listingType,
              location,
              lotType,
              name,
              metricName,
              shapeType,
              subcategory,
              tagNumber,
              totalLength,
              weight,
            },
            index
          ) => {
            const { totalLength: lengthFormat, weight: weightFormat } = valuesFormatList.listings;
            const convertedWeight = convertMeasurements(weight || 0, 'weight', measurementSystem);
            const finalWeight = displayFormattingValue(convertedWeight, weightFormat);
            const convertedLength = convertMeasurements(
              totalLength || 0,
              'lenft',
              measurementSystem
            );
            const finalLength = displayFormattingValue(convertedLength, lengthFormat);
            const shouldAnonymise =
              isEu &&
              Boolean(generalizeLocationInEuListingsToPreserveAnonymity) &&
              !!location.address.anonymousLocation;
            const lotLocation = calculateOriginLocation({
              anonymousLocation: location.address.anonymousLocation,
              region: location.address.region,
              country: location.address.country,
              shouldAnonymise,
              isCountryLocation: false,
              isEu,
              countryTarget: 'code',
            });

            return (
              <div
                className={classes.featuredProduct__card}
                key={`${name}-card-${index}`}
                onClick={() => handleCardClick(`/show/listings/${tagNumber}`)}
              >
                <div
                  className={classes.featuredProduct__card__contents}
                  data-testid="feature-product-wrapper"
                >
                  <Typography className={classes.subtitle1} variant="subtitle1">
                    {createRFQTitle(
                      category,
                      // @ts-ignore
                      subcategory as Subcategory,
                      format,
                      [shapeType as ShapeType],
                      tEnums
                    )}
                  </Typography>
                  {isListing && lotType === 'PR' && <PrimeBadge />}
                  <Typography className={classes.subtitle2} variant="subtitle2">
                    {measurementSystem === 'IMPERIAL' ? name : metricName}
                  </Typography>
                  <div className={classes.singleLine}>
                    <Typography className={classes.body2} variant="body2">
                      {isListing && !['PIPE', 'TUBE'].includes(format)
                        ? t('dashboard-nav:featured.weight')
                        : t('dashboard-nav:featured.quantity')}
                      <span>: </span>
                    </Typography>
                    <Typography className={classes.body1} variant="body1">
                      {isListing && !['PIPE', 'TUBE'].includes(format)
                        ? `${finalWeight} ${t(tFormatUnits('weight', measurementSystem))}`
                        : `${finalLength} ${t(tFormatUnits('lenft', measurementSystem))}`}
                    </Typography>
                  </div>
                  <div className={classes.singleLine}>
                    <Typography className={classes.body2} variant="body2">
                      {isListing
                        ? t('dashboard-nav:featured.location')
                        : t('dashboard-nav:featured.biddingDeadline')}
                      <span>: </span>
                    </Typography>
                    <Typography className={classes.body1} variant="body1">
                      {location.address.country ? lotLocation : location.address.region}
                    </Typography>
                  </div>
                  {isListing && listingType !== 'BUYOUT' && (
                    <Typography className={classes.button} variant="button">
                      {t('dashboard-nav:featured.bestOffer')}
                    </Typography>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FeaturedProduct;
