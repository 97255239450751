import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';
import { makeStyles } from 'tss-react/mui';

import { checkLAWhiteListLink } from 'components/routing/RedirectLimitedAccessUsers';

import DashboardNavV2 from './DashboardNavWithContext';
import LimitedHeader from './LimitedHeaderWithContext';
import LogoOnlyHeader from './LogoOnlyHeader';
import MaintenanceBanner from './MaintenanceBanner';
import { updateHeaderStylesForEnvironment } from './updateHeaderStylesForEnvironment';

const useStyles = makeStyles()(() => {
  const styles = {
    root: {
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#5D0549',
    },
  };

  return updateHeaderStylesForEnvironment(styles);
});

const Header = () => {
  const { rm9225EnableAutoTranslate } = useFlags();
  const { classes } = useStyles();
  const {
    location: { pathname },
  } = window;

  if (checkLAWhiteListLink(pathname)) {
    return <LimitedHeader />;
  }

  if (pathname.includes('/admin/edit-bid') || pathname.includes('/admin/auctions/edit-bid')) {
    return <LogoOnlyHeader />;
  }

  return (
    <>
      <Helmet htmlAttributes={{ translate: !rm9225EnableAutoTranslate ? 'no' : 'yes' }} />
      <MaintenanceBanner />
      <header className={classes.root}>
        <DashboardNavV2 />
      </header>
    </>
  );
};

export default Header;
