import React from 'react';

import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import Root from './root.component';
import { loadI18n } from './utils/i18n';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary(_err, _info, _props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const bootstrap = [loadI18n, lifecycles.bootstrap];

const unstyleRoot = async () => {
  const spaNavContainer = document.querySelector('#spa-nav-container');
  spaNavContainer?.classList.remove('spa-nav-container');
};

export const unmount = [unstyleRoot, lifecycles.unmount];

export const { mount } = lifecycles;
