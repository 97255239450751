/** @jsxImportSource @emotion/react */

import * as React from 'react';

import { Theme } from '@emotion/react';
import { Interpolation } from '@emotion/serialize';
import { navigateToUrl } from 'single-spa';

type SingleSpaLinkProps = {
  children: React.ReactNode;
  to: string;
  className?: string;
  css?: Interpolation<Theme>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  style?: React.CSSProperties;
};

const SingleSpaLink = ({ children, to, className, css, style, onClick }: SingleSpaLinkProps) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    if (onClick) {
      onClick(e);
    }
    e.preventDefault();
    navigateToUrl(to);
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
    <a className={className} onClick={handleClick} css={css} style={style} href={to}>
      {children}
    </a>
  );
};

export default SingleSpaLink;
