import { adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const primary = 'rgba(9, 10, 13, 0.7)';
const f1 = '3.052rem';
const f2 = '2.441rem';
const f3 = '1.953rem';
const f4 = '1.563rem';
const f5 = '1.25rem';
const f6 = '1rem';
const f7 = '0.8rem';

const theme = createTheme({
  ...adaptV4Theme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 720,
        lg: 1440,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: [`Montserrat`, `arial`, `sans-serif`].join(','),
      h1: {
        fontSize: f1,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h2: {
        fontSize: f2,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h3: {
        fontSize: f3,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h4: {
        fontSize: f4,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h5: {
        fontSize: f5,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      h6: {
        fontSize: f6,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
      },
      body1: { fontSize: f6, fontWeight: 400, lineHeight: 1.5, letterSpacing: '' },
      body2: {
        fontSize: f7,
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '',
      },
      subtitle1: {
        fontSize: f5,
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '1.45rem',
        letterSpacing: '-0.01562em',
      },
      // using this on rfq review card for the labels
      subtitle2: {
        fontSize: f6,
        fontWeight: 400,
        lineHeight: 1,
        marginBottom: '0.5rem',
        letterSpacing: '-0.01562em',
      },
    },
    overrides: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '1rem',
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#5d0549',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#2d6ccb',
        contrastText: '#fff',
      },
      error: {
        main: '#BB121A',
        contrastText: '#fff',
      },
      text: {
        primary,
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  }),
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            overflow: 'visible',
            whiteSpace: 'normal',
            marginBottom: '1rem',
            lineHeight: 1,
          },
        },
      },
    },
  },
});

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 744,
    md: 1080,
    lg: 1440,
    xl: 1536,
  },
};

export default theme;
