import {
  hotRolledGradesSteel,
  galvannealedSteel,
  coldRolledSteel,
  galvanizedSteel,
  aluminizedSteel,
  electroGalvanizedSteel,
} from './automotive';

import type { FormatKey, GradeCategories, SteelGrades, SubcategoryKey } from './types';

const allSteelSheetGradeFamilies: Partial<GradeCategories> = {
  'csa g40.21': [
    '38W / 260W',
    '38WT / 260W',
    '44W / 300W',
    '44WT / 300WT',
    '50A / 350A',
    '50AT / 350AT',
    '50W / 350W',
    '50WT / 350WT',
    '60W / 410W',
    '60WT / 410WT',
    '70A / 480A',
    '70AT / 480AT',
    '70W / 480W',
    '70WT / 480WT',
  ],
};

const allSteelPlateGradeFamilies: Partial<GradeCategories> = {
  'csa g40.21': [
    '38W / 260W',
    '38WT / 260W',
    '44W / 300W',
    '44WT / 300WT',
    '50A / 350A',
    '50AT / 350AT',
    '50W / 350W',
    '50WT / 350WT',
    '60W / 410W',
    '60WT / 410WT',
    '70A / 480A',
    '70AT / 480AT',
    '70W / 480W',
    '70WT / 480WT',
  ],
};

export const steel_gradesWithAutomotive: SteelGrades = {
  COIL: {
    HR: {
      'astm a36': ['A36'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['A786'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR38',
        'SS GR40',
        'SS GR44',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR65',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR42',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '5140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600', 'DP 780'],
      ...hotRolledGradesSteel,
    },
    HRPO: {
      'astm a36': ['A36'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['A786'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR40',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR80',
      ],
      'high strength low alloy': [
        // "HSLA GR40",
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600'],
      ...hotRolledGradesSteel,
    },
    CR: {
      'astm a424': ['Type I', 'Type II CS', 'Type II DS', 'Type III'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'DS-B',
        'DS',
        'EDDS',
        'EDDS+',
        'FH',
        'FS',
        'IF',
      ],
      'structural steel': ['SS GR25', 'SS GR30', 'SS GR33', 'SS GR40', 'SS GR50', 'SS GR80'],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '830R',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        '5120',
        'M1044',
        'RA 830',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        '27MnCrB5',
        'BH 180',
        'BH 210',
        'BH 250',
        'BH 280',
        'BH 300',
        'BH 340',
        'BHB',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 690', 'DP 780', 'DP 980', 'DP 1180'],
      ...coldRolledSteel,
    },
    GALVANIZED: {
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'DS',
        'EDDS',
        'EDDS+',
        'FS-A',
        'FS-B',
        'FS',
        'IF',
      ],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR60',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
      ...galvanizedSteel,
    },
    ELECTROGALVANIZED: {
      'commercial / drawing steel': ['CS', 'DS', 'EDDS', 'IF'],
      'high strength low alloy': ['HSLA GR36', 'HSLA GR40', 'HSLA GR45', 'HSLA GR50', 'HSLA GR80'],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 220',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 690', 'DP 700', 'DP 780', 'DP 980', 'DP 1180'],
      ...electroGalvanizedSteel,
    },
    GALVANNEAL: {
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'DS',
        'EDDS',
        'EDDS+',
        'FS-A',
        'FS-B',
        'FS',
        'IF',
      ],
      'structural steel': [
        'SCGA 440',
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': [
        'DP 260',
        'DP 450',
        'DP 590',
        'DP 600',
        'DP 780',
        'DP 800',
        'DP 980',
        'DP 1180',
      ],
      ...galvannealedSteel,
    },
    GALVALUME: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'IF'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40', 'SS GR50', 'SS GR55', 'SS GR80'],
      'sae j403 (carbon grades)': [
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1040',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    ALUMINIZED: {
      'commercial / drawing steel': [
        'CS',
        'CS-A',
        'CS-B',
        'CS-C',
        'DS',
        'FS',
        'DS-B',
        'FS-B',
        'DDS',
        'EDDS',
      ],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40'],
      'high strength low alloy': ['HSLA GR45', 'HSLA GR50', 'HSLA GR60'],
      'sae j403 (carbon grades)': [
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1040',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...aluminizedSteel,
    },
    STAINLESS: {
      ferritic: [
        '3Cr12',
        '11 Cr-Cb™',
        '13-4 SR®',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        '409 Ni',
        '409 UF®',
        '409',
        '410',
        '410S',
        '430 UF®',
        '420',
        '430',
        '430Ti',
        '434',
        '435M',
        '436',
        '439',
        '441',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // '18 SR®',
        // '436L UF®',
        // 'THERMAK® 17',
        // "436 / 435 Mod",
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410 Cb',
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
      ],
      austenitic: [
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304H',
        '304L',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '348',
        'NITRONIC® 30',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'Other'
        // 'PH 15-7 Mo®',
      ],
      duplex: [
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'LDX2101',
        'Nit19D',
        // 'NITRONIC® 19D',
      ],
      alloys: [
        '12Mn',
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
    TIN_PLATE: {
      'astm b545': [
        'ASTM DR7.5',
        'ASTM DR8.5',
        'ASTM DR8',
        'ASTM DR9',
        'ASTM DR520',
        'ASTM DR550',
        'ASTM T1',
        'ASTM T2',
        'ASTM T3',
        'ASTM T4',
        'ASTM T5',
      ],
    },
  },
  SHEET: {
    HR: {
      'astm a36': ['A36'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['A786'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR38',
        'SS GR40',
        'SS GR44',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR65',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR42',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '5140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600', 'DP 780'],
      ...allSteelSheetGradeFamilies,
      ...hotRolledGradesSteel,
    },
    HRPO: {
      'astm a36': ['A36'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['A786'],
      'commercial / drawing steel': ['CS', 'DS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR36',
        'SS GR40',
        'SS GR45',
        'SS GR50',
        'SS GR55',
        'SS GR80',
      ],
      'high strength low alloy': [
        // "HSLA GR40",
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR65',
        'HSLA GR70',
        'HSLA GR80',
        'HSLA GR90',
        'HSLA GR100',
        'HSLA GR110',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j404 (alloy grades)': ['4130', '4140', '6150'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'dual phase': ['DP 590', 'DP 600'],
      ...allSteelSheetGradeFamilies,
      ...hotRolledGradesSteel,
    },
    CR: {
      'astm a424': ['Type I', 'Type II CS', 'Type II DS', 'Type III'],
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'DS-B',
        'DS',
        'EDDS',
        'EDDS+',
        'FH',
        'FS',
        'IF',
      ],
      'structural steel': ['SS GR25', 'SS GR30', 'SS GR33', 'SS GR40', 'SS GR50', 'SS GR80'],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR50F',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR60F',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '830R',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        '5120',
        'M1044',
        'RA 830',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        '27MnCrB5',
        'BH 180',
        'BH 210',
        'BH 250',
        'BH 280',
        'BH 300',
        'BH 340',
        'BHB',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 690', 'DP 780', 'DP 980', 'DP 1180'],
      ...allSteelSheetGradeFamilies,
      ...coldRolledSteel,
    },
    GALVANIZED: {
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'DS',
        'EDDS',
        'EDDS+',
        'FS-A',
        'FS-B',
        'FS',
        'IF',
      ],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR60',
        'SS GR70',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
      ...allSteelSheetGradeFamilies,
      ...galvanizedSteel,
    },
    ELECTROGALVANIZED: {
      'commercial / drawing steel': ['CS', 'DS', 'EDDS', 'IF'],
      'high strength low alloy': ['HSLA GR36', 'HSLA GR40', 'HSLA GR45', 'HSLA GR50', 'HSLA GR80'],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 220',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 690', 'DP 700', 'DP 780', 'DP 980', 'DP 1180'],
      ...allSteelSheetGradeFamilies,
      ...electroGalvanizedSteel,
    },
    GALVANNEAL: {
      'commercial / drawing steel': [
        'CS-A',
        'CS-B',
        'CS-C',
        'CS',
        'DDS',
        'EDDS',
        'EDDS+',
        'FS-A',
        'FS-B',
        'FS',
        'IF',
      ],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1010M',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1026',
        '1035',
        '1038',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
        'M1044',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
      ...allSteelSheetGradeFamilies,
      ...galvannealedSteel,
    },
    GALVALUME: {
      'commercial / drawing steel': ['CS', 'FS', 'DS', 'IF'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40', 'SS GR50', 'SS GR55', 'SS GR80'],
      'sae j403 (carbon grades)': [
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1040',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...allSteelSheetGradeFamilies,
    },
    ALUMINIZED: {
      'commercial / drawing steel': ['CS', 'CS-A', 'CS-B', 'CS-C', 'FS', 'FS-B', 'DDS', 'EDDS'],
      'structural steel': ['SS GR33', 'SS GR37', 'SS GR40'],
      'high strength low alloy': ['HSLA GR45', 'HSLA GR50', 'HSLA GR60'],
      'sae j403 (carbon grades)': [
        '1001',
        '1002',
        '1003',
        '1004',
        '1005',
        '1006',
        '1007',
        '1008',
        '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1040',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...allSteelSheetGradeFamilies,
      ...aluminizedSteel,
    },
    STAINLESS: {
      ferritic: [
        '3Cr12',
        '11 Cr-Cb™',
        '13-4 SR®',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        '409 Ni',
        '409 UF®',
        '409',
        '410',
        '410S',
        '430 UF®',
        '430',
        '430Ti',
        '434',
        '435M',
        '436',
        '439',
        '441',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // '18 SR®',
        // '436L UF®',
        // 'THERMAK® 17',
        // "436 / 435 Mod",
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410 Cb',
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
      ],
      austenitic: [
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304H',
        '304L',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '348',
        'NITRONIC® 30',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'Other'
        // 'PH 15-7 Mo®',
      ],
      duplex: [
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'LDX2101',
        'Nit19D',
        // 'NITRONIC® 19D',
      ],
      alloys: [
        '12Mn',
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
    TIN_PLATE: {
      'astm b545': [
        'ASTM DR7.5',
        'ASTM DR8.5',
        'ASTM DR8',
        'ASTM DR9',
        'ASTM DR520',
        'ASTM DR550',
        'ASTM T1',
        'ASTM T2',
        'ASTM T3',
        'ASTM T4',
        'ASTM T5',
      ],
    },
  },
  PLATE: {
    HR: {
      'astm a36': ['A36', 'A36 MOD'],
      'aisi 4340': ['AISI 4340'],
      'astm a131': ['Grade A', 'Grade B', 'Grade D', 'Grade E'],
      'astm a283': ['Grade C', 'Grade D'],
      'astm a285': ['Grade A', 'Grade B', 'Grade C'],
      'astm a514': ['A', 'A514 Gr B', 'A514 Gr S', 'E', 'F', 'H', 'P', 'Q', 'S'],
      'astm a515': ['Grade 60', 'Grade 65', 'Grade 70'],
      'astm a516': ['GR55', 'GR60', 'GR65', 'GR70'],
      'astm a537': ['Class 1', 'Class 2', 'Class 3'],
      'astm a572': ['GR42', 'GR50', 'GR60', 'GR65', 'GR80'],
      'astm a573': ['GR65', 'GR70', 'GR70N'],
      'astm a588': ['Grade A', 'Grade B', 'Grade C'],
      'astm a612': ['A612'],
      'astm a656': ['GR50', 'GR60', 'GR70', 'GR80', 'GR100'],
      'astm a709': ['Grade 36', 'Grade 50', 'Grade 50W'],
      'astm a710': ['Grade A', 'Grade B'],
      'astm a786 (floor plate)': ['A786'],
      'astm a830': ['1008', '1020', '1030', '1045'],
      'astm a871': ['Grade 60', 'Grade 65'],
      'abrasion resistant': [
        'AR 200',
        'AR 235',
        'AR 400',
        'AR 450',
        'AR 500',
        'AR 550',
        'AR 600',
        'AR 650',
        'Hardox® 400',
        'Hardox® 450',
        'Hardox® 500',
        'Hardox® 550',
        'Hardox® 600',
        'X120Mn12',
      ],
      'sae j403 (carbon grades)': ['1020', '1045', '1060'],
      'sae j404 (alloy grades)': ['4140'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...allSteelPlateGradeFamilies,
      ...hotRolledGradesSteel,
    },
    STAINLESS: {
      ferritic: [
        '3Cr12',
        '11 Cr-Cb™',
        '13-4 SR®',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        '409 Ni',
        '409 UF®',
        '409',
        '410',
        '410S',
        '430 UF®',
        '430',
        '430Ti',
        '434',
        '435M',
        '436',
        '439',
        '441',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // '18 SR®',
        // '436L UF®',
        // 'THERMAK® 17',
        // "436 / 435 Mod",
        // "Aluminized 409/439",
      ],
      martensitic: [
        '410 Cb',
        '410',
        '420',
        '431',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
      ],
      austenitic: [
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304H',
        '304L',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '321H',
        '347H',
        '348',
        '904L',
        'NITRONIC® 30',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'Other'
        // 'PH 15-7 Mo®',
      ],
      duplex: [
        '2202 Duplex',
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'LDX2101',
        'Nit19D',
        // 'NITRONIC® 19D',
      ],
      alloys: [
        '12Mn',
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
  },
};

const steelGalvanizedSortWeights = [
  'astm a653 hot dipped',
  'bake hardenable',
  'commercial / drawing steel',
  'dent resistant',
  'dual phase',
  'high strength low alloy',
  'structural steel',
];

type WeighedGrade = (typeof steelGalvanizedSortWeights)[number];

export const sortGrades = (
  keys: WeighedGrade[],
  format: FormatKey,
  subcategory: SubcategoryKey
) => {
  if (!['COIL', 'SHEET'].includes(format) || subcategory !== 'GALVANIZED') {
    return keys.sort((a, b) => a.localeCompare(b));
  }

  const upperGrades = keys
    .filter(grade => steelGalvanizedSortWeights.includes(grade))
    .sort((a, b) => steelGalvanizedSortWeights.indexOf(a) - steelGalvanizedSortWeights.indexOf(b));
  const lowerGrades = keys
    .filter(grade => !steelGalvanizedSortWeights.includes(grade))
    .sort((a, b) => a.localeCompare(b));

  return [...upperGrades, ...lowerGrades];
};
