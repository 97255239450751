import { NavContextProvider } from './context';
import DashboardNav from './DashboardNavV2';

const DashboardNavWithContext = () => {
  return (
    <NavContextProvider>
      <DashboardNav />
    </NavContextProvider>
  );
};

export default DashboardNavWithContext;
