import * as React from 'react';

import { SupportedLocale } from '@reibus/reibus-core-utils/dist/src/types/locale';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Flag from 'components/icons/flags/Flag';
import QAFlag from 'components/icons/flags/QAFlag';

type LocaleInfo = {
  label: string;
  icon: React.ReactNode;
};

export const useSupportedLocales = (): Partial<Record<SupportedLocale, LocaleInfo>> => {
  const flags = useFlags();
  const { rm16600AddEsMxSupport = false, i18NSv = false, i18NDeDe = false } = flags;
  const supportedLocales = {
    'en-US': {
      label: ' US English',
      icon: <Flag country="USA" />,
    },
  };

  if (!rm16600AddEsMxSupport) {
    supportedLocales['en-GB'] = {
      label: ' UK English',
      icon: <Flag country="GBR" />,
    };

    if (i18NDeDe) {
      supportedLocales['de-DE'] = {
        label: ' Deutsch',
        icon: <Flag country="DEU" />,
      };
    }

    supportedLocales['es-ES'] = {
      label: ' Español',
      icon: <Flag country="ESP" />,
    };

    supportedLocales['fr-FR'] = {
      label: ' Français',
      icon: <Flag country="FRA" />,
    };

    if (i18NSv) {
      supportedLocales.sv = {
        label: ' Svenska',
        icon: <Flag country="SWE" />,
      };
    }

    supportedLocales['it-IT'] = {
      label: ' Italiano',
      icon: <Flag country="ITA" />,
    };
  } else {
    supportedLocales['es-MX'] = {
      label: ' Español (México)',
      icon: <Flag country="MEX" />,
    };
  }

  if (process.env.BUILD_STAGE !== 'production') {
    supportedLocales['qa-XX'] = {
      label: 'QA',
      icon: <QAFlag />,
    };
  }

  return supportedLocales;
};
