import * as React from 'react';

import { useCurrentUser, useUserSession } from '@reibus/frontend-utility';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export default function useLaunchDarklyIdentify() {
  const ldClient = useLDClient();
  const { data: currentUser } = useCurrentUser();
  const { data: userSession } = useUserSession();

  React.useEffect(() => {
    const context = currentUser
      ? {
          key: currentUser.email,
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          secondary: currentUser.company.name,
          custom: {
            isReibus: userSession?.isReibusUser ?? false,
          },
        }
      : {
          key: 'anonymous',
        };

    ldClient?.identify(context);
  }, [currentUser]);
}
