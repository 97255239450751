import {
  hotRolledGradesSteel,
  galvannealedSteel,
  coldRolledSteel,
  galvanizedSteel,
  aluminizedSteel,
  electroGalvanizedSteel,
} from './automotive';

import type { SteelGrades } from './types';

// NOTE: Still using NA automotive grades for EU (not yet updated with EU automotive grades in Notion as of 2/10/22)
export const euSteel_gradesWithAutomotive: SteelGrades = {
  COIL: {
    FLOOR_PLATE: {
      uncategorized: ['LLOYDS GRADE A', 'S235J', 'S275J', 'S355J', 'S355MC', 'S500MC'],
    },
    HR: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['ASTM A786 (Floor Plate)'],
      'en 10111 (commercial / drawing grades)': ['DD11', 'DD12', 'DD13', 'DD14'],
      'en 10149 (hsla grades)': [
        'S240',
        'S280',
        'S315',
        'S320',
        'S355',
        'S420',
        'S460',
        'S500',
        'S550',
        'S600',
      ],
      'en 10025 (structural grades)': ['S185', 'S235 [Corten/Weathering Steel]', 'S355'],
      'en 10083 (high carbon)': [
        'C18',
        'C20',
        'C22',
        'C30',
        'C35',
        'C40',
        'C45',
        'C50',
        'C55',
        'C60',
        'C67',
        'C70',
        'C75',
        'C80',
      ],
      'en 10207 (pressure vessels)': ['P235S', 'P265S', 'P275SL'],
      'en 10028 (pressure vessels)': [
        '16Mo3',
        'P235GH',
        'P265GH',
        'P275',
        'P295GH',
        'P355',
        'P420',
      ],
      'en 10120': ['P245NB', 'P265NB', 'P310NB', 'P355NB'],
      'en 10149': ['S235J', 'S275J', 'S355J', 'S355MC', 'S500MC'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5', 'S355J2', 'S355J0', 'S235J2', 'S235J0', 'S275J2', 'S275J0'],
      ...hotRolledGradesSteel,
    },
    HRPO: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['ASTM A786 (Floor Plate)'],
      'en 10111 (commercial / drawing grades)': ['DD11', 'DD12', 'DD13', 'DD14'],
      'en 10149 (hsla grades)': [
        'S240',
        'S280',
        'S315',
        'S320',
        'S355',
        'S420',
        'S460',
        'S500',
        'S550',
        'S600',
      ],
      'en 10025 (structural grades)': ['S185', 'S235 [Corten/Weathering Steel]', 'S355'],
      'en 10083 (high carbon)': [
        'C18',
        'C20',
        'C22',
        'C30',
        'C35',
        'C40',
        'C45',
        'C50',
        'C55',
        'C60',
        'C67',
        'C70',
        'C75',
        'C80',
      ],
      'en 10207 (pressure vessels)': ['P235S', 'P265S', 'P275SL'],
      'en 10028 (pressure vessels)': [
        '16Mo3',
        'P235GH',
        'P265GH',
        'P275',
        'P295GH',
        'P355',
        'P420',
      ],
      'en 10120': ['P245NB', 'P265NB', 'P310NB', 'P355NB'],
      'en 10149': ['S235J', 'S275J', 'S355J', 'S355MC', 'S500MC'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...hotRolledGradesSteel,
    },
    CR: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'en 10130': [
        'DC01',
        'DC02',
        'DC03',
        'DC04',
        'DC05',
        'DC06',
        'DC07',
        'HC180CA',
        'HC220CA',
        'HC240CA',
        'HC260CA',
      ],
      'en 10268': [
        'HC260LA',
        'HC300LA',
        'HC340LA',
        'HC380LA',
        'HC420LA',
        'HC460LA',
        'HC500LA',
        'HC550LA',
      ],
      'en 10209 (enameling)': ['DC01EK', 'DC03EK', 'DC04EK', 'DC05EK', 'DC06EK', 'DC07EK'],
      'en 10209 (decarburized)': ['DC03ED', 'DC04ED', 'Solfer'],
      'en 10209': ['HC300EK'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...coldRolledSteel,
    },
    ELECTROGALVANIZED: {
      'en 10152': ['DC01', 'DC03', 'DC04', 'DC05', 'DC06', 'DC07'],
      'en 10268': ['HC260LA', 'HC300LA', 'HC340LA', 'HC380LA', 'HC420LA', 'HC460LA', 'HC500LA'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...electroGalvanizedSteel,
    },
    GALVANIZED: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'HX460LAD',
        'HX500LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S390GD',
        'S420GD',
        'S450GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...galvanizedSteel,
    },
    GALVALUME: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'HX220YD',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
    },
    ALUMINIZED: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX55D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...aluminizedSteel,
    },
    // Still using NA grades for galvanneal (not yet updated with EU grades in Notion as of 2/9/22)
    GALVANNEAL: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
      ...galvannealedSteel,
    },
    ZM: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'HX460LAD',
        'HX500LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S390GD',
        'S420GD',
        'S450GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    JVD: {
      'en 10131': [
        'DC01',
        'DC03',
        'DC04',
        'DC05',
        'DC06',
        'H240LA',
        'H260LA',
        'HC180',
        'HC180CA',
        'HC200',
        'HC220',
        'HC220CA',
        'HC240',
        'HC260CA',
        'HC300LA',
        'HC340LA',
        'HC380LA',
        'HC420LA',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    STAINLESS: {
      ferritic: [
        '1.4003',
        '1.4016',
        '1.4113',
        '1.4372',
        '1.4376',
        '1.4509',
        '1.4510',
        '1.4511',
        '1.4512',
        '1.4513',
        '1.4520',
        '1.4521',
        '1.4589',
        '1.4622',
        '1.4713',
        '1.4742',
        '1.4762',
      ],
      martensitic: [
        '1.4016',
        '1.4021',
        '1.4024',
        '1.4028',
        '1.4031',
        '1.4034',
        '1.4057',
        '1.4110',
        '1.4113',
        '1.4116',
        '1.4122',
        '1.4162',
        '1.4362',
        '1.4662',
      ],
      austenitic: [
        '1.4301',
        '1.4303',
        '1.4306',
        '1.4307',
        '1.4310',
        '1.4311',
        '1.4318',
        '1.4372',
        '1.4376',
        '1.4401',
        '1.4404',
        '1.4432',
        '1.4435',
        '1.4436',
        '1.4438',
        '1.4466',
        '1.4501',
        '1.4509',
        '1.4510',
        '1.4512',
        '1.4520',
        '1.4521',
        '1.4529',
        '1.4539',
        '1.4541',
        '1.4547',
        '1.4550',
        '1.4571',
        '1.4589',
        '1.4652',
        '1.4828',
        '1.4841',
        '316Ti',
      ],
      duplex: ['1.4162', '1.4262', '1.4362', '1.4410', '1.4462', '1.4482', '1.4662'],
    },
    TIN_PLATE: {
      'astm b545': [
        'TH330',
        'TH360',
        'TH415',
        'TH435',
        'TH460',
        'TH480',
        'TH520',
        'TH550',
        'TH580',
        'TH620',
        'TS230',
        'TS245',
        'TS260',
        'TS275',
        'TS290',
        'TS340',
        'TS380',
        'TS500',
        'TS520',
        'TS550',
      ],
    },
    GALFAN: {
      uncategorized: [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'HX220YD',
        'HX260YD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
      ],
    },
  },
  SHEET: {
    FLOOR_PLATE: {
      uncategorized: ['LLOYDS GRADE A', 'S235J', 'S275J', 'S355J', 'S355MC', 'S500MC'],
    },
    HR: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['ASTM A786 (Floor Plate)'],
      'en 10111 (commercial / drawing grades)': ['DD11', 'DD12', 'DD13', 'DD14'],
      'en 10149 (hsla grades)': [
        'S240',
        'S280',
        'S315',
        'S320',
        'S355',
        'S420',
        'S460',
        'S500',
        'S550',
        'S600',
      ],
      'en 10025 (structural grades)': ['S185', 'S235 [Corten/Weathering Steel]', 'S355'],
      'en 10083 (high carbon)': [
        'C18',
        'C20',
        'C22',
        'C30',
        'C35',
        'C40',
        'C45',
        'C50',
        'C55',
        'C60',
        'C67',
        'C70',
        'C75',
        'C80',
      ],
      'en 10207 (pressure vessels)': ['P235S', 'P265S', 'P275SL'],
      'en 10028 (pressure vessels)': [
        '16Mo3',
        'P235GH',
        'P265GH',
        'P275',
        'P295GH',
        'P355',
        'P420',
      ],
      'en 10120': ['P245NB', 'P265NB', 'P310NB', 'P355NB'],
      'en 10149': ['S235J', 'S275J', 'S355J', 'S355J2+AR', 'S355MC', 'S500MC'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5', 'S355J2', 'S355J0', 'S235J2', 'S235J0', 'S275J2', 'S275J0'],
      ...hotRolledGradesSteel,
    },
    HRPO: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'astm a786 (floor plate)': ['ASTM A786 (Floor Plate)'],
      'en 10111 (commercial / drawing grades)': ['DD11', 'DD12', 'DD13', 'DD14'],
      'en 10149 (hsla grades)': [
        'S240',
        'S280',
        'S315',
        'S320',
        'S355',
        'S420',
        'S460',
        'S500',
        'S550',
        'S600',
      ],
      'en 10025 (structural grades)': ['S185', 'S235 [Corten/Weathering Steel]', 'S355'],
      'en 10083 (high carbon)': [
        'C18',
        'C20',
        'C22',
        'C30',
        'C35',
        'C40',
        'C45',
        'C50',
        'C55',
        'C60',
        'C67',
        'C70',
        'C75',
        'C80',
      ],
      'en 10207 (pressure vessels)': ['P235S', 'P265S', 'P275SL'],
      'en 10028 (pressure vessels)': [
        '16Mo3',
        'P235GH',
        'P265GH',
        'P275',
        'P295GH',
        'P355',
        'P420',
      ],
      'en 10120': ['P245NB', 'P265NB', 'P310NB', 'P355NB'],
      'en 10149': ['S235J', 'S275J', 'S355J', 'S355MC', 'S500MC'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...hotRolledGradesSteel,
    },
    CR: {
      'astm a606 (weathering steel)': ['Type 2', 'Type 4', 'Type 5'],
      'en 10130': [
        'DC01',
        'DC02',
        'DC03',
        'DC04',
        'DC05',
        'DC06',
        'DC07',
        'HC180CA',
        'HC220CA',
        'HC240CA',
        'HC260CA',
      ],
      'en 10268': [
        'HC260LA',
        'HC300LA',
        'HC340LA',
        'HC380LA',
        'HC420LA',
        'HC460LA',
        'HC500LA',
        'HC550LA',
      ],
      'en 10209 (enameling)': ['DC01EK', 'DC03EK', 'DC04EK', 'DC05EK', 'DC06EK', 'DC07EK'],
      'en 10209 (decarburized)': ['DC03ED', 'DC04ED', 'Solfer'],
      'en 10209': ['HC300EK'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...coldRolledSteel,
    },
    ELECTROGALVANIZED: {
      'en 10152': ['DC01', 'DC03', 'DC04', 'DC05', 'DC06', 'DC07'],
      'en 10268': ['HC260LA', 'HC300LA', 'HC340LA', 'HC380LA', 'HC420LA', 'HC460LA', 'HC500LA'],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...electroGalvanizedSteel,
    },
    GALVANIZED: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'HX460LAD',
        'HX500LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S390GD',
        'S420GD',
        'S450GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
      ...galvanizedSteel,
    },
    GALVALUME: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'HX220YD',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      uncategorized: ['16MnCr5'],
    },
    ALUMINIZED: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX55D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      ...aluminizedSteel,
    },
    // Still using NA grades for galvanneal (not yet updated with EU grades in Notion as of 2/9/22)
    GALVANNEAL: {
      'commercial / drawing steel': ['CS', 'FS', 'DDS', 'EDDS', 'IF'],
      'structural steel': [
        'SS GR30',
        'SS GR33',
        'SS GR37',
        'SS GR40',
        'SS GR50',
        'SS GR55',
        'SS GR57',
        'SS GR80',
      ],
      'high strength low alloy': [
        'HSLA GR40',
        'HSLA GR45',
        'HSLA GR50',
        'HSLA GR55',
        'HSLA GR60',
        'HSLA GR70',
        'HSLA GR80',
      ],
      'sae j403 (carbon grades)': [
        '10B38',
        '1001',
        '1004',
        '1005',
        '1006',
        '1008',
        '1010',
        '1010M',
        '1012',
        '1015',
        '1018',
        '1020',
        '1021',
        '1022',
        '1035',
        '1040',
        '1045',
        '1050',
        '1060',
        '1062',
        '1074',
        '1075',
        '1085',
        '1095',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
      'bake hardenable': [
        'BH 180',
        'BH 210',
        'BH 240',
        'BH 250',
        'BH 270',
        'BH 280',
        'BH 300',
        'BH 340',
      ],
      'dent resistant': ['DR 180', 'DR 190', 'DR 210'],
      'dual phase': ['DP 590', 'DP 600', 'DP 780', 'DP 800', 'DP 980', 'DP 1180'],
      ...galvannealedSteel,
    },
    ZM: {
      'en 10346': [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'DX57D',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
        'HX460LAD',
        'HX500LAD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'S390GD',
        'S420GD',
        'S450GD',
        'S550GD',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    JVD: {
      'en 10131': [
        'DC01',
        'DC03',
        'DC04',
        'DC05',
        'DC06',
        'H240LA',
        'H260LA',
        'HC180',
        'HC180CA',
        'HC200',
        'HC220',
        'HC220CA',
        'HC240',
        'HC260CA',
        'HC300LA',
        'HC340LA',
        'HC380LA',
        'HC420LA',
      ],
      'sae j2340': [
        '300XF',
        '300YF',
        '310X',
        '310XF',
        '310Y',
        '310YF',
        '340X',
        '340XF',
        '340Y',
        '340YF',
        '380X',
        '380XF',
        '380Y',
        '380YF',
        '410X',
        '410XF',
        '410Y',
        '410YF',
        '420XF',
        '420YF',
        '450X',
        '450XF',
        '450Y',
        '450YF',
        '480X',
        '480XF',
        '480Y',
        '480YF',
        '490XF',
        '490YF',
        '550X',
        '550XF',
        '550Y',
        '550YF',
        '620X',
        '620XF',
        '620Y',
        '620YF',
      ],
    },
    STAINLESS: {
      ferritic: [
        '1.4003',
        '1.4016',
        '1.4113',
        '1.4509',
        '1.4510',
        '1.4511',
        '1.4512',
        '1.4513',
        '1.4520',
        '1.4521',
        '1.4589',
        '1.4622',
        '1.4713',
        '1.4742',
        '1.4762',
      ],
      martensitic: [
        '1.4021',
        '1.4024',
        '1.4028',
        '1.4031',
        '1.4034',
        '1.4057',
        '1.4110',
        '1.4116',
        '1.4122',
      ],
      austenitic: [
        '1.4301',
        '1.4303',
        '1.4306',
        '1.4307',
        '1.4310',
        '1.4311',
        '1.4318',
        '1.4372',
        '1.4376',
        '1.4401',
        '1.4404',
        '1.4432',
        '1.4435',
        '1.4436',
        '1.4438',
        '1.4466',
        '1.4501',
        '1.4509',
        '1.4529',
        '1.4539',
        '1.4541',
        '1.4547',
        '1.4550',
        '1.4571',
        '1.4652',
        '1.4662',
        '1.4828',
        '1.4841',
        '316Ti',
      ],
      duplex: ['1.4162', '1.4262', '1.4362', '1.4410', '1.4462', '1.4482', '1.4662'],
    },
    TIN_PLATE: {
      'astm b545': [
        'TH330',
        'TH360',
        'TH415',
        'TH435',
        'TH460',
        'TH480',
        'TH520',
        'TH550',
        'TH580',
        'TH620',
        'TS230',
        'TS245',
        'TS260',
        'TS275',
        'TS290',
        'TS340',
        'TS380',
        'TS500',
        'TS520',
        'TS550',
      ],
    },
    GALFAN: {
      uncategorized: [
        'DX51D',
        'DX52D',
        'DX53D',
        'DX54D',
        'DX56D',
        'HX220YD',
        'HX260YD',
        'S220GD',
        'S250GD',
        'S280GD',
        'S320GD',
        'S350GD',
        'HX260LAD',
        'HX300LAD',
        'HX340LAD',
        'HX380LAD',
        'HX420LAD',
      ],
    },
  },
  PLATE: {
    STRUCTURAL: {
      'en 10025:2004': [
        '16MnCr5',
        '25CrMo4',
        '42CrMo4',
        'A36',
        'A283 Grade C',
        'A283 Grade D',
        'AH32',
        'AH36',
        'AH40',
        'API 2MT1',
        'C4E5',
        'DH32',
        'DH36',
        'DH40',
        'EH32',
        'EH36',
        'Gr A',
        'Gr B',
        'Gr D',
        'Gr E',
        'HB400',
        'HB450',
        'HB500',
        'P265GH',
        'P275NH',
        'P355NH',
        'P355NL',
        'P460NH',
        'P460NL',
        'S185',
        'S235J0',
        'S235J2',
        'S235J2+N',
        'S235JR',
        'S275J0',
        'S275J2',
        'S275J2+N',
        'S275JR',
        'S275NL',
        'S295',
        'S335',
        'S355J0',
        'S355J0W+N',
        'S355J2',
        'S355J2W+N',
        'S355J4W+N',
        'S355J5W+N',
        'S355K2W+N',
        'S355J2+N',
        'S355JR',
        'S355K2',
        'S355K2+N',
        'S355ML',
        'S355N',
        'S355NL',
        'S360',
        'S420J0W+N',
        'S420J2W+N',
        'S420K2W+N',
        'S420NL',
        'S460J0W+N',
        'S460J2W+N',
        'S460K2W+N',
        'S460N',
        'S460NL',
        'S690QL',
        'S700MC',
        'X120Mn',
      ],
    },
    HSLA: {
      'astm a786 (floor plate)': ['ASTM A786 (Floor Plate)'],
      'en 10025-6': [
        'S690 Q',
        'S690 QL',
        'S690 QL1',
        'S890 Q',
        'S890 QL',
        'S890 QL1',
        'S960 Q',
        'S960 QL',
        'Ultra 960',
      ],
    },
    WEAR_RESISTANT: {
      'en 10155:1993': [
        'Hardox® 400',
        'Hardox® 450',
        'Hardox® 500',
        'Hardox® 550',
        'Hardox® 600',
        'HB400',
        'HB450',
        'HB500',
        'HB550',
        'HB600',
        'S235J0W',
        'S235J2W',
        'S355J0W',
        'S355J0WP',
        'S355J2G1W',
        'S355J2G2W',
        'S355J2WP',
        'S355K2G1W',
        'S355K2G2W',
        'X120Mn12',
      ],
    },
    ARMOR: {
      hha: ['(HHA) - 500 Brinell (HB477-540)'],
      vhh: ['(VHH) - 550 Brinell (HB530-590)'],
      uhh: ['(UHH) - 600 Brinell (HB580-640)'],
      xhh: ['(XHH) - 650 Brinell (HB630-700)'],
      'en 10155:1993': [
        'Hardox® 400',
        'Hardox® 450',
        'Hardox® 500',
        'Hardox® 550',
        'Hardox® 600',
        'HB400',
        'HB450',
        'HB500',
        'HB550',
        'HB600',
        'X120Mn12',
      ],
    },
    PVQ: {
      'en 10207:2005': [
        '16 Mo 3',
        'AHP265',
        'ASTM A516 GR 60',
        'ASTM A516 GR 65',
        'ASTM A516 GR 70',
        'Grade 60',
        'Grade 65',
        'Grade 70',
        'P235GH',
        'P235S',
        'P265GH',
        'P265S',
        'P275NH',
        'P275NL1',
        'P275NL2',
        'P275S',
        'P295GH',
        'P355GH',
        'P355NH',
        'P355NL1',
        'P355NL2',
        'SHP235',
        'SHPL275',
      ],
    },
    MOLD_STEEL: {
      'w.nr': [
        '1.2083',
        '1.2085',
        '1.2099',
        '1.2311',
        '1.2312',
        '1.2316',
        '1.2343',
        '1.2714',
        '1.2738 mod HH',
        '1.2738 mod',
        '1.2738',
        '1.2767',
        '1.7218',
        '1.7225',
        '2333 ESR',
        '2343 ESR',
        '2714',
        '2738H',
        'C40',
      ],
      uncategorized: ['1.7131'],
    },
    TOOL_STEEL: {
      'w.nr': [
        '1.2343',
        '1.2344',
        '1.2347',
        '1.2355',
        '1.2363',
        '1.2379',
        '1.2510',
        '1.2714',
        '1.2767',
        '1.2842',
        '2333 ESR',
        '2343 ESR',
        '2714',
        '2738H',
        'C40',
      ],
    },
    // Still using NA grades for stainless steel (not yet updated with EU grades in Notion as of 2/9/22)
    STAINLESS: {
      ferritic: [
        '3Cr12',
        '11 Cr-Cb™',
        '13-4 SR®',
        '15 Cr-Cb®',
        '18 Cr-Cb™',
        '409 Ni',
        '409 UF®',
        '409',
        '410',
        '410S',
        '430 UF®',
        '430',
        '430Ti',
        '434',
        '435M',
        '436',
        '439',
        '441',
        '444',
        'CHROMESHIELD® 22',
        'CHROMESHIELD® 29 Mo',
        // '18 SR®',
        // '436L UF®',
        // 'THERMAK® 17',
        // "436 / 435 Mod",
        // "Aluminized 409/439",
      ],
      martensitic: [
        '1.4003',
        '1.4016',
        '1.4021',
        '1.4024',
        '1.4028',
        '1.4034',
        '1.4057',
        '1.4110',
        '1.4116',
        '1.4122',
        '1.4162',
        '1.4301',
        '1.4303',
        '1.4306',
        '1.4307',
        '1.4310',
        '1.4311',
        '1.4318',
        '1.4362',
        '1.4372',
        '1.4376',
        '1.4401',
        '1.4404',
        '1.4410',
        '1.4432',
        '1.4435',
        '1.4436',
        '1.4438',
        '1.4466',
        '1.4501',
        '1.4509',
        '1.4510',
        '1.4512',
        '1.4520',
        '1.4521',
        '1.4529',
        '1.4539',
        '1.4541',
        '1.4547',
        '1.4550',
        '1.4571',
        '1.4589',
        '1.4652',
        '1.4662',
        '410 Cb',
        '410',
        '420',
        '440',
        '440A',
        // '420 ULTRA HONE®',
        // "410",
      ],
      austenitic: [
        '201',
        '201LN',
        '301',
        '301LN',
        '304',
        '304/304L',
        '304H',
        '304L',
        '305',
        '309',
        '309S',
        '310',
        '310S',
        '316',
        '316L',
        '316Ti',
        '317',
        '317L',
        '321',
        '347',
        '348',
        'NITRONIC® 30',
      ],
      'precipitation hardening': [
        '15-5',
        '15-7',
        '17-4',
        '17-7',
        // 'Other'
        // 'PH 15-7 Mo®',
      ],
      duplex: [
        '2205 Duplex',
        '2207 Duplex',
        '2304 Duplex',
        '2507 Duplex',
        'LDX2101',
        'Nit19D',
        // 'NITRONIC® 19D',
      ],
      alloys: [
        'Alloy 20',
        'Alloy 22',
        'Alloy 200',
        'Alloy 201',
        'Alloy 400',
        'Alloy 405',
        'Alloy 600',
        'Alloy 625',
        'Alloy 800',
        'Alloy 800H',
        'Alloy 825',
        'B-2',
        'C-276',
      ],
    },
  },
};
