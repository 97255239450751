import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const NavCaret = () => {
  return (
    <KeyboardArrowDownIcon
      sx={{ fontSize: '.9rem', position: 'relative', left: '4px', top: '1.2px' }}
    />
  );
};
export default NavCaret;
