import * as React from 'react';

import { faBell } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, IconButton, Menu } from '@mui/material';
import { useCurrentUser } from '@reibus/frontend-utility';
import { makeStyles } from 'tss-react/mui';

import { useNotificationsContext } from './context';
import { useMarkAllAsRead, useNotificationCounts } from './hooks';
import NotificationsPanel from './NotificationsPanel';

const useStyles = makeStyles()({
  badgeStyle: {
    color: '#5D0549',
    backgroundColor: '#fff',
    fontSize: 10,
    fontWeight: 700,
    borderRadius: 8,
    minWidth: 16,
    height: 16,
    padding: 0,
  },
  root: {
    cursor: 'pointer',
  },
  wrapper: {
    marginLeft: '8px',
    marginRight: '33px',
  },
});

const Notifications = () => {
  const { mutate: markAllAsRead } = useMarkAllAsRead();
  const { anchorEl, open, openMenu, closeMenu } = useNotificationsContext();
  const { data: currentUser } = useCurrentUser();
  const {
    data: { unread },
  } = useNotificationCounts();
  const { classes } = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    openMenu(event.currentTarget);
  };

  return currentUser ? (
    <div className={classes.wrapper}>
      <IconButton size="small" onClick={handleClick}>
        <Badge
          badgeContent={unread}
          onClick={() => {
            closeMenu();
            markAllAsRead({ updateType: 'markAllAsRead' });
          }}
          max={9}
          classes={{
            badge: classes.badgeStyle,
            root: classes.root,
          }}
        >
          <FontAwesomeIcon icon={faBell} color="white" />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <NotificationsPanel />
      </Menu>
    </div>
  ) : null;
};

export default Notifications;
