import { useState } from 'react';

import { ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useCurrentUser } from '@reibus/frontend-utility';
import { useTranslation } from 'react-i18next';
import { navigateToUrl } from 'single-spa';
import { makeStyles } from 'tss-react/mui';

import NavCaret from 'components/icons/NavCaret';
import Button from 'components/library/Button';
import NonUserLink from 'components/validation/NonUserLink';
import useForceLogin from 'hooks/useForceLogin';
import { FeaturedListingsByFormat, FeaturedListing } from 'types';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';

import { useNavContext } from './context';
import { useStyles as useDashboardStyles } from './DashboardNavV2';
import FeaturedProduct from './FeaturedProduct';
import { LinkNavEntry, LotNavMenuEntry, NavEntrySubMenu } from './navMenuData';

// Custom style for Typography component
const useDropdownStyles = makeStyles()({
  noMargin: {
    margin: 0,
  },
  singleLine: {
    display: 'flex',
    marginBottom: '0',
  },
  subtitle1: {
    fontSize: '14px',
    color: '#090a0dde',
    fontWeight: 700,
    marginBottom: '0',
  },
  subtitle2: {
    fontSize: '14px',
    color: '#090a0dde',
    marginBottom: '0',
  },
  body1: {
    fontSize: '14px',
    color: '#090a0dde',
  },
  body2: {
    fontSize: '14px',
    color: '#828282',
    marginRight: '3px',
  },
  button: {
    fontSize: '14px',
    color: '#811869',
    fontWeight: 900,
    textTransform: 'capitalize',
  },
  h6: {
    color: '#090a0dde',
  },
  title: {
    marginTop: '10px',
    '& > h6': {
      marginLeft: 10,
    },
  },
  categoryContainer: {
    width: '225px',
    margin: '0',
    minHeight: '300px',
    paddingTop: '20px',
    paddingLeft: 0,
  },
  content: {
    position: 'relative',
    display: 'flex',
    marginRight: '20px',
  },
  featuredCards: {
    padding: '12px',
  },
  categories: {
    display: 'flex',
  },
  categoriesContent: {
    position: 'relative',
    bottom: '21px',
  },
  SubItems: {
    width: '100%',
    margin: 'initial',
    padding: 0,
  },
  longProducts: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subTitle: {
    fontWeight: 400,
    marginBottom: 0,
    '& a': {
      color: '#828282',
    },
  },
  menuSubcategorySubItems: {
    margin: 0,
    padding: 0,
  },
  categoryTab: {
    margin: 0,
    padding: '0 22px 0 44px',
    width: '100%',
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#4f4f4f',
  },
  categoryTabSelected: {
    backgroundColor: '#f2f2f2',
    color: '#5d0549',
  },
  menuItemLargeDropdown: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: '100%',
    zIndex: 1000,
    visibility: 'hidden',
    borderRadius: '3px',
    minHeight: '40vh',
    left: '5%',
    right: '5%',
    boxShadow: '0px 20px 35px 0px rgb(0 0 0 / 20%)',
    border: '1px solid rgba(238, 238, 238, 1)',
    borderTop: 'none',
  },
  makeVisible: {
    visibility: 'visible',
  },
  menuItemLargeDropdownContainer: {
    display: 'flex',
  },
  categoryOptions: {
    display: 'flex',
    width: 'calc(100% - 225px)',
  },
  menuSubcategory: {
    margin: '16px',
    minWidth: '105px',
    height: 'fit-content',
    '& li': {
      listStyle: 'none',
    },
    '& a': {
      color: '#828282',
      '&:hover': {
        color: '#0d908d',
      },
    },
  },
  menuSubcategory__title: {
    marginBottom: '32px',
    color: 'rgba(9, 10, 13, 0.87)',
    '& a': {
      marginBottom: '32px',
      color: 'rgba(9, 10, 13, 0.87)',
    },
  },
  menuItem__label: {
    color: '#fff',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    '&:hover': {
      cursor: 'default',
    },
    '& > a': {
      color: '#fff',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  categoryContainer__buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0',
  },
  categoryOptions__list: {
    borderLeft: '1px solid #f2f2f2',
    width: '100%',
    height: 'auto',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '16px 0 0 25px',
    '&:hover': {
      cursor: 'default',
    },
    '& a': {
      color: 'rgba(9, 10, 13, 0.87)',
      backgroundColor: 'transparent',
      textDecoration: 'none',
      '&:hover': {
        color: '#0d908d',
        textDecoration: 'underline',
        outlineWidth: 0,
      },
    },
  },
  categoryOptions__colContainer: {
    margin: '16px',
    display: 'flex',
  },
  categoryOptions__singleCol: {
    padding: 0,
    margin: '0 32px 0 0',
    '& a': {
      color: '#828282',
    },
  },
  categoryOptions__singleCol__item: {
    listStyle: 'none',
    '& p': {
      color: '#828282',
      '&:hover': {
        color: '#0d908d',
      },
    },
  },
});

type CategoryMenuProps = {
  title: string;
  isSelected: boolean;
  index: number;
  handleChangeCategory: (index: number) => void;
};

export type SingleCategory = LotNavMenuEntry & {
  featured?: FeaturedListing[];
};

type LargeDropdownMenuProps = {
  dropdownKey: string;
  categories: SingleCategory[];
  isListing: boolean;
  featuredListingsByFormat: FeaturedListingsByFormat;
};

type MenuSubcategoryProps = NavEntrySubMenu;

const validRegionLinkNavEntryFilter = ({ validRegions }: LinkNavEntry) => {
  if (!validRegions) {
    return true;
  }

  const arrValidRegions = Array.isArray(validRegions) ? validRegions : [validRegions];
  return arrValidRegions.includes(process.env.REGION);
};

const MenuSubcategory = ({ title, url, subItems }: MenuSubcategoryProps) => {
  const { classes } = useDropdownStyles();
  const { t } = useTranslation('dashboard-nav');

  const subItemsToDisplay = subItems.filter(validRegionLinkNavEntryFilter);

  return (
    <div className={classes.menuSubcategory}>
      <div className={classes.menuSubcategory__title}>
        {url ? (
          <Typography variant="h6">
            <NonUserLink
              to={url}
              label={t(title)}
              onClick={() =>
                trackEvent(TOP_MENU_CLICK_EVENT, {
                  title,
                  url,
                })
              }
            />
          </Typography>
        ) : (
          <Typography variant="h6">{t(title)}</Typography>
        )}
      </div>
      {(subItemsToDisplay?.length ?? 0) > 0 ? (
        <ul className={title ? classes.menuSubcategorySubItems : classes.SubItems}>
          {subItemsToDisplay.map(({ title, url, euUrl }, index) => {
            const filterUrl = process.env.REGION === 'EU' && title === 'coated' ? euUrl : url;
            return (
              <li key={`${title}${index}`} style={{ marginBottom: 'calc(1.45rem / 2)' }}>
                {filterUrl ? (
                  <Typography variant="h6" className={classes.subTitle}>
                    <NonUserLink
                      to={filterUrl}
                      label={t(title)}
                      onClick={() =>
                        trackEvent(TOP_MENU_CLICK_EVENT, {
                          title,
                          url: filterUrl,
                        })
                      }
                    />
                  </Typography>
                ) : (
                  <Typography>{t(title)}</Typography>
                )}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

// Category Menu \\

const CategoryMenu = ({
  title,
  isSelected = false,
  index,
  handleChangeCategory,
}: CategoryMenuProps) => {
  const { classes, cx } = useDropdownStyles();
  return (
    <li
      className={cx(classes.categoryTab, { [classes.categoryTabSelected]: isSelected })}
      onMouseEnter={() => handleChangeCategory(index)}
    >
      <Typography className={classes.noMargin} variant="h6">
        {title}
      </Typography>
      <ChevronRight fontSize="small" />
    </li>
  );
};

// Large Dropdown Menu \\

const LargeDropdownMenuV2 = ({
  dropdownKey,
  categories,
  isListing,
  featuredListingsByFormat,
}: LargeDropdownMenuProps) => {
  const { currentDropdown, setCurrentDropdown } = useNavContext();
  const [indexOfSelected, setIndexOfSelected] = useState(0);
  const { format, subGroups, navTitle, col1, col2 } = categories[indexOfSelected];
  const featuredListings = featuredListingsByFormat?.[format]
    ? [...featuredListingsByFormat[format]].splice(0, 2)
    : null;
  const { classes, cx } = useDropdownStyles();
  const { classes: dashClasses } = useDashboardStyles();
  const featuredStyles = (format === 'PLATE' || indexOfSelected === 3) && dropdownKey !== 'rfqs';
  const longProducts = format === 'LONG_PRODUCT';
  const { data: currentUser } = useCurrentUser();
  const isLoggedIn = !!currentUser;
  const { t } = useTranslation('dashboard-nav');

  const forceLogin = useForceLogin();

  return (
    <div
      id={`${dropdownKey === 'rfqs' ? 'rfqDropdown' : ''}`}
      className={dashClasses.menuItem}
      onClick={() => {
        setCurrentDropdown(dropdownKey);
      }}
      onMouseEnter={currentDropdown ? () => setCurrentDropdown(dropdownKey) : undefined}
    >
      <div className={classes.menuItem__label} onMouseEnter={() => setIndexOfSelected(0)}>
        <span>{t(dropdownKey)}</span>
        <NavCaret />
      </div>
      <div
        className={cx(classes.menuItemLargeDropdown, {
          [classes.makeVisible]: currentDropdown === dropdownKey,
        })}
      >
        <div className={classes.menuItemLargeDropdownContainer}>
          <ul className={classes.categoryContainer}>
            {categories.map(({ title }, index) => (
              <CategoryMenu
                key={`CategoryTile${index}`}
                title={t(title)}
                isSelected={index === indexOfSelected}
                index={index}
                handleChangeCategory={setIndexOfSelected}
              />
            ))}
            {dropdownKey === 'rfqs' ? (
              <div className={classes.categoryContainer__buttonContainer}>
                <Button
                  data-testid="createRFQButton"
                  kind="primary"
                  color="primary"
                  onClick={() => {
                    trackEvent('Create RFQ Button', { pageName: 'navBar' });
                    trackEvent(TOP_MENU_CLICK_EVENT, {
                      title: 'createRFQ',
                      url: '/app/requests-for-quote/create',
                    });
                    if (!isLoggedIn) {
                      forceLogin();
                      return;
                    }
                    navigateToUrl('/app/requests-for-quote/create');
                  }}
                >
                  {t('createRFQ')}
                </Button>
              </div>
            ) : null}
          </ul>
          <div className={featuredStyles ? classes.categories : classes.categoryOptions}>
            <div className={classes.categoryOptions__list}>
              {navTitle &&
                navTitle.map(({ title, url }, i) =>
                  url ? (
                    <div key={`${title}${i}`} className={classes.title}>
                      <Typography variant="h6">
                        {' '}
                        <NonUserLink
                          to={url}
                          label={t(title)}
                          onClick={() =>
                            trackEvent(TOP_MENU_CLICK_EVENT, {
                              title,
                              url,
                            })
                          }
                        />
                      </Typography>
                    </div>
                  ) : null
                )}
              <div className={featuredStyles ? classes.categoriesContent : classes.content}>
                {longProducts ? (
                  <div className={classes.longProducts}>
                    {(subGroups ?? []).map((props, index) => (
                      <MenuSubcategory {...props} key={`${props.title}${index}`} />
                    ))}
                  </div>
                ) : (
                  <>
                    {(subGroups ?? []).map((props, index) => (
                      <MenuSubcategory {...props} key={`${props.title}${index}`} />
                    ))}
                  </>
                )}

                {col1 ? (
                  <div className={classes.categoryOptions__colContainer}>
                    <ul className={classes.categoryOptions__singleCol}>
                      {col1.filter(validRegionLinkNavEntryFilter).map(({ title, url }, i) => {
                        return (
                          <li
                            key={`${title}-col1-${i}`}
                            className={classes.categoryOptions__singleCol__item}
                          >
                            {url ? (
                              <Typography>
                                <NonUserLink
                                  to={url}
                                  label={t(title)}
                                  onClick={() =>
                                    trackEvent(TOP_MENU_CLICK_EVENT, {
                                      title,
                                      url,
                                    })
                                  }
                                />
                              </Typography>
                            ) : (
                              <Typography>{t(title)}</Typography>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    {col2 ? (
                      <ul className={classes.categoryOptions__singleCol}>
                        {col2.filter(validRegionLinkNavEntryFilter).map(({ title, url }, i) => (
                          <li
                            key={`${title}-col2-${i}`}
                            className={classes.categoryOptions__singleCol__item}
                          >
                            {url ? (
                              <Typography>
                                <NonUserLink
                                  to={url}
                                  label={t(title)}
                                  onClick={() =>
                                    trackEvent(TOP_MENU_CLICK_EVENT, {
                                      title,
                                      url,
                                    })
                                  }
                                />
                              </Typography>
                            ) : (
                              <Typography>{t(title)}</Typography>
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                ) : null}
                {!featuredStyles ? (
                  <div>
                    {featuredListings ? (
                      <FeaturedProduct featuredListings={featuredListings} isListing={isListing} />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            {featuredStyles ? (
              <div className={classes.featuredCards}>
                {featuredListings ? (
                  <FeaturedProduct featuredListings={featuredListings} isListing={isListing} />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeDropdownMenuV2;
