import type { GradeCategories } from '../types';

export const galvanizedSteel: Partial<GradeCategories> = {
  'gmw2m hot dipped': [
    'GMW2M-ST-S-CR1',
    'GMW2M-ST-S-CR2',
    'GMW2M-ST-S-CR3',
    'GMW2M-ST-S-CR4',
    'GMW2M-ST-S-CR5',
    'GMW2M-ST-S-HR0',
    'GMW2M-ST-S-HR1',
    'GMW2M-ST-S-HR2',
    'GMW2M-ST-S-HR3',
  ],
  'gmw3032m bake hardenable / dent resistant': [
    'GMW3032M-ST-S-CR180B0',
    'GMW3032M-ST-S-CR180B2',
    'GMW3032M-ST-S-CR180P',
    'GMW3032M-ST-S-CR210B0',
    'GMW3032M-ST-S-CR210B2',
    'GMW3032M-ST-S-CR210P',
    'GMW3032M-ST-S-CR240B0',
    'GMW3032M-ST-S-CR240B2',
    'GMW3032M-ST-S-CR270B0',
    'GMW3032M-ST-S-CR270B2',
    'GMW3032M-ST-S-CR300B0',
    'GMW3032M-ST-S-CR300B2',
  ],
  'gmw3399m hot dipped dual phase': [
    'GMW3399M-ST-S-CR450T/250Y-DP',
    'GMW3399M-ST-S-CR490T/290Y-DP',
    'GMW3399M-ST-S-CR590T/280Y-DP',
    'GMW3399M-ST-S-CR590T/340Y-DP',
    'GMW3399M-ST-S-CR590T/420Y-DP',
    'GMW3399M-ST-S-CR690T/550Y-DP',
    'GMW3399M-ST-S-CR780T/420Y-DP',
    'GMW3399M-ST-S-CR780T/500Y-DP',
    'GMW3399M-ST-S-CR980T/550Y-DP',
    'GMW3399M-ST-S-CR980T/650Y-DP',
    'GMW3399M-ST-S-HR580T/330Y-DP',
    'GMW3399M-ST-S-HR780T/450Y-DP',
  ],
  'gmw3399m hot dipped multi phase': [
    'GMW3399M-ST-S-CR980T/700Y-MP-LCE',
    'GMW3399M-ST-S-CR980T/700Y-MP',
  ],
  'gmw3399m hot dipped trip': [
    'GMW3399M-ST-S-CR590T/380Y-TR',
    'GMW3399M-ST-S-CR690T/410Y-TR',
    'GMW3399M-ST-S-CR780T/440Y-TR',
  ],
  'gmw3399m hot dipped martensitic': [
    'GMW3399M-ST-S-HR1200T/900Y-MS',
    'GMW3399M-ST-S-HR1400T/1050Y-MS',
  ],
  'ms-6000 55 chrysler hot dipped': [
    'MS-6000 55V-025HK',
    'MS-6000 55V-025SK',
    'MS-6000 55V-030HK',
    'MS-6000 55V-030SK',
    'MS-6000 55V-035HK',
    'MS-6000 55V-035SK',
    'MS-6000 55V-040HK',
    'MS-6000 55V-040SK',
    'MS-6000 55V-045HK',
    'MS-6000 55V-045XK',
    'MS-6000 55V-050XK',
    'MS-6000 55V-060XK',
    'MS-6000 55V-070XK',
    'MS-6000 55V-080XK',
    'MS-6000 55V-120XK',
    'MS-6000 55V-190MT',
    'MS-6000 55V-220MT',
    'MS-6000 55V-490DT',
    'MS-6000 55V-590DT',
    'MS-6000 55V-590PT',
    'MS-6000 55V-780DT',
    'MS-6000 55V-980DT',
    'MS6000 55 CS',
    'MS6000 55 DDS',
    'MS6000 55 DS',
    'MS6000 55 EDDS',
  ],
  'ms-6000 66 chrysler hot dipped': [
    'MS-6000 66V-025HK',
    'MS-6000 66V-025SK',
    'MS-6000 66V-030HK',
    'MS-6000 66V-030SK',
    'MS-6000 66V-035HK',
    'MS-6000 66V-035SK',
    'MS-6000 66V-040HK',
    'MS-6000 66V-040SK',
    'MS-6000 66V-045HK',
    'MS-6000 66V-045XK',
    'MS-6000 66V-050XK',
    'MS-6000 66V-060XK',
    'MS-6000 66V-070XK',
    'MS-6000 66V-080XK',
    'MS-6000 66V-120XK',
    'MS-6000 66V-190MT',
    'MS-6000 66V-220MT',
    'MS-6000 66V-490DT',
    'MS-6000 66V-590DT',
    'MS-6000 66V-590PT',
    'MS-6000 66V-780DT',
    'MS-6000 66V-980DT',
    'MS6000 66 CS',
    'MS6000 66 DDS',
    'MS6000 66 DS',
    'MS6000 66 EDDS',
  ],
  'ms-6000 99 chrysler hot dipped': [
    'MS-6000 99V-025HK',
    'MS-6000 99V-025SK',
    'MS-6000 99V-030HK',
    'MS-6000 99V-030SK',
    'MS-6000 99V-035HK',
    'MS-6000 99V-035SK',
    'MS-6000 99V-040HK',
    'MS-6000 99V-040SK',
    'MS-6000 99V-045HK',
    'MS-6000 99V-045XK',
    'MS-6000 99V-050XK',
    'MS-6000 99V-060XK',
    'MS-6000 99V-070XK',
    'MS-6000 99V-080XK',
    'MS-6000 99V-120XK',
    'MS-6000 99V-190MT',
    'MS-6000 99V-220MT',
    'MS-6000 99V-490DT',
    'MS-6000 99V-590DT',
    'MS-6000 99V-590PT',
    'MS-6000 99V-780DT',
    'MS-6000 99V-980DT',
    'MS6000 99 CS',
    'MS6000 99 DDS',
    'MS6000 99 DS',
    'MS6000 99 EDDS',
  ],
  'ms.50002 hot dipped': [
    'MS.50002 BHC180Y290T GI50/50',
    'MS.50002 BHC180Y290T GI60/60',
    'MS.50002 BHC180Y290T GI70/70',
    'MS.50002 BHC180Y290T GI90/90',
    'MS.50002 BHC180Y290T GI98/98',
    'MS.50002 BHC210Y310T GI50/50',
    'MS.50002 BHC210Y310T GI60/60',
    'MS.50002 BHC210Y310T GI70/70',
    'MS.50002 BHC210Y310T GI90/90',
    'MS.50002 BHC210Y310T GI98/98',
    'MS.50002 BHC240Y340T GI50/50',
    'MS.50002 BHC240Y340T GI60/60',
    'MS.50002 BHC240Y340T GI70/70',
    'MS.50002 BHC240Y340T GI90/90',
    'MS.50002 BHC240Y340T GI98/98',
    'MS.50002 BHC280Y3800T GI50/50',
    'MS.50002 BHC280Y3800T GI60/60',
    'MS.50002 BHC280Y3800T GI70/70',
    'MS.50002 BHC280Y3800T GI90/90',
    'MS.50002 BHC280Y3800T GI98/98',
    'MS.50002 CR01 GI50/50',
    'MS.50002 CR01 GI60/60',
    'MS.50002 CR01 GI70/70',
    'MS.50002 CR01 GI90/90',
    'MS.50002 CR01 GI98/98',
    'MS.50002 CR02 GI50/50',
    'MS.50002 CR02 GI60/60',
    'MS.50002 CR02 GI70/70',
    'MS.50002 CR02 GI90/90',
    'MS.50002 CR02 GI98/98',
    'MS.50002 CR04 GI50/50',
    'MS.50002 CR04 GI60/60',
    'MS.50002 CR04 GI70/70',
    'MS.50002 CR04 GI90/90',
    'MS.50002 CR04 GI98/98',
    'MS.50002 CR05 GI50/50',
    'MS.50002 CR05 GI60/60',
    'MS.50002 CR05 GI70/70',
    'MS.50002 CR05 GI90/90',
    'MS.50002 CR05 GI98/98',
    'MS.50002 CR06 GI50/50',
    'MS.50002 CR06 GI60/60',
    'MS.50002 CR06 GI70/70',
    'MS.50002 CR06 GI90/90',
    'MS.50002 CR06 GI98/98',
    'MS.50002 DPC290Y490T GI50/50',
    'MS.50002 DPC290Y490T GI60/60',
    'MS.50002 DPC290Y490T GI70/70',
    'MS.50002 DPC290Y490T GI90/90',
    'MS.50002 DPC290Y490T GI98/98',
    'MS.50002 DPC340Y590T GI50/50',
    'MS.50002 DPC340Y590T GI60/60',
    'MS.50002 DPC340Y590T GI70/70',
    'MS.50002 DPC340Y590T GI90/90',
    'MS.50002 DPC340Y590T GI98/98',
    'MS.50002 DPC420Y780T GI50/50',
    'MS.50002 DPC420Y780T GI60/60',
    'MS.50002 DPC420Y780T GI70/70',
    'MS.50002 DPC420Y780T GI90/90',
    'MS.50002 DPC420Y780T GI98/98',
    'MS.50002 DPC420Y780T HF GI50/50',
    'MS.50002 DPC420Y780T HF GI60/60',
    'MS.50002 DPC420Y780T HF GI70/70',
    'MS.50002 DPC420Y780T HF GI90/90',
    'MS.50002 DPC420Y780T HF GI98/98',
    'MS.50002 DPC550Y980T GI50/50',
    'MS.50002 DPC550Y980T GI60/60',
    'MS.50002 DPC550Y980T GI70/70',
    'MS.50002 DPC550Y980T GI90/90',
    'MS.50002 DPC550Y980T GI98/98',
    'MS.50002 DPC700Y980T GI50/50',
    'MS.50002 DPC700Y980T GI60/60',
    'MS.50002 DPC700Y980T GI70/70',
    'MS.50002 DPC700Y980T GI90/90',
    'MS.50002 DPC700Y980T GI98/98',
    'MS.50002 DPC950Y1180T GI50/50',
    'MS.50002 DPC950Y1180T GI60/60',
    'MS.50002 DPC950Y1180T GI70/70',
    'MS.50002 DPC950Y1180T GI90/90',
    'MS.50002 DPC950Y1180T GI98/98',
    'MS.50002 DPH330Y580T GI50/50',
    'MS.50002 DPH330Y580T GI60/60',
    'MS.50002 DPH330Y580T GI70/70',
    'MS.50002 DPH330Y580T GI90/90',
    'MS.50002 DPH330Y580T GI98/98',
    'MS.50002 FBC440Y580T GI50/50',
    'MS.50002 FBC440Y580T GI60/60',
    'MS.50002 FBC440Y580T GI70/70',
    'MS.50002 FBC440Y580T GI90/90',
    'MS.50002 FBC440Y580T GI98/98',
    'MS.50002 FBH440Y580T GI50/50',
    'MS.50002 FBH440Y580T GI60/60',
    'MS.50002 FBH440Y580T GI70/70',
    'MS.50002 FBH440Y580T GI90/90',
    'MS.50002 FBH440Y580T GI98/98',
    'MS.50002 HR11 GI50/50',
    'MS.50002 HR11 GI60/60',
    'MS.50002 HR11 GI70/70',
    'MS.50002 HR11 GI90/90',
    'MS.50002 HR11 GI98/98',
    'MS.50002 HR12 GI50/50',
    'MS.50002 HR12 GI60/60',
    'MS.50002 HR12 GI70/70',
    'MS.50002 HR12 GI90/90',
    'MS.50002 HR12 GI98/98',
    'MS.50002 HR13 GI50/50',
    'MS.50002 HR13 GI60/60',
    'MS.50002 HR13 GI70/70',
    'MS.50002 HR13 GI90/90',
    'MS.50002 HR13 GI98/98',
    'MS.50002 LAC210Y310T GI50/50',
    'MS.50002 LAC210Y310T GI60/60',
    'MS.50002 LAC210Y310T GI70/70',
    'MS.50002 LAC210Y310T GI90/90',
    'MS.50002 LAC210Y310T GI98/98',
    'MS.50002 LAC240Y320T GI50/50',
    'MS.50002 LAC240Y320T GI60/60',
    'MS.50002 LAC240Y320T GI70/70',
    'MS.50002 LAC240Y320T GI90/90',
    'MS.50002 LAC240Y320T GI98/98',
    'MS.50002 LAC270Y330T GI50/50',
    'MS.50002 LAC270Y330T GI60/60',
    'MS.50002 LAC270Y330T GI70/70',
    'MS.50002 LAC270Y330T GI90/90',
    'MS.50002 LAC270Y330T GI98/98',
    'MS.50002 LAC300Y370T GI50/50',
    'MS.50002 LAC300Y370T GI60/60',
    'MS.50002 LAC300Y370T GI70/70',
    'MS.50002 LAC300Y370T GI90/90',
    'MS.50002 LAC300Y370T GI98/98',
    'MS.50002 LAC340Y410T GI50/50',
    'MS.50002 LAC340Y410T GI60/60',
    'MS.50002 LAC340Y410T GI70/70',
    'MS.50002 LAC340Y410T GI90/90',
    'MS.50002 LAC340Y410T GI98/98',
    'MS.50002 LAC380Y450T GI50/50',
    'MS.50002 LAC380Y450T GI60/60',
    'MS.50002 LAC380Y450T GI70/70',
    'MS.50002 LAC380Y450T GI90/90',
    'MS.50002 LAC380Y450T GI98/98',
    'MS.50002 LAC420Y480T GI50/50',
    'MS.50002 LAC420Y480T GI60/60',
    'MS.50002 LAC420Y480T GI70/70',
    'MS.50002 LAC420Y480T GI90/90',
    'MS.50002 LAC420Y480T GI98/98',
    'MS.50002 LAC500Y560T GI50/50',
    'MS.50002 LAC500Y560T GI60/60',
    'MS.50002 LAC500Y560T GI70/70',
    'MS.50002 LAC500Y560T GI90/90',
    'MS.50002 LAC500Y560T GI98/98',
    'MS.50002 LAC550Y620T GI50/50',
    'MS.50002 LAC550Y620T GI60/60',
    'MS.50002 LAC550Y620T GI70/70',
    'MS.50002 LAC550Y620T GI90/90',
    'MS.50002 LAC550Y620T GI98/98',
    'MS.50002 LAC830Y860T GI50/50',
    'MS.50002 LAC830Y860T GI60/60',
    'MS.50002 LAC830Y860T GI70/70',
    'MS.50002 LAC830Y860T GI90/90',
    'MS.50002 LAC830Y860T GI98/98',
    'MS.50002 LAH210Y310T GI50/50',
    'MS.50002 LAH210Y310T GI60/60',
    'MS.50002 LAH210Y310T GI70/70',
    'MS.50002 LAH210Y310T GI90/90',
    'MS.50002 LAH210Y310T GI98/98',
    'MS.50002 LAH240Y320T GI50/50',
    'MS.50002 LAH240Y320T GI60/60',
    'MS.50002 LAH240Y320T GI70/70',
    'MS.50002 LAH240Y320T GI90/90',
    'MS.50002 LAH240Y320T GI98/98',
    'MS.50002 LAH270Y330T GI50/50',
    'MS.50002 LAH270Y330T GI60/60',
    'MS.50002 LAH270Y330T GI70/70',
    'MS.50002 LAH270Y330T GI90/90',
    'MS.50002 LAH270Y330T GI98/98',
    'MS.50002 LAH300Y370T GI50/50',
    'MS.50002 LAH300Y370T GI60/60',
    'MS.50002 LAH300Y370T GI70/70',
    'MS.50002 LAH300Y370T GI90/90',
    'MS.50002 LAH300Y370T GI98/98',
    'MS.50002 LAH340Y410T GI50/50',
    'MS.50002 LAH340Y410T GI60/60',
    'MS.50002 LAH340Y410T GI70/70',
    'MS.50002 LAH340Y410T GI90/90',
    'MS.50002 LAH340Y410T GI98/98',
    'MS.50002 LAH380Y450T GI50/50',
    'MS.50002 LAH380Y450T GI60/60',
    'MS.50002 LAH380Y450T GI70/70',
    'MS.50002 LAH380Y450T GI90/90',
    'MS.50002 LAH380Y450T GI98/98',
    'MS.50002 LAH420Y480T GI50/50',
    'MS.50002 LAH420Y480T GI60/60',
    'MS.50002 LAH420Y480T GI70/70',
    'MS.50002 LAH420Y480T GI90/90',
    'MS.50002 LAH420Y480T GI98/98',
    'MS.50002 LAH500Y560T GI50/50',
    'MS.50002 LAH500Y560T GI60/60',
    'MS.50002 LAH500Y560T GI70/70',
    'MS.50002 LAH500Y560T GI90/90',
    'MS.50002 LAH500Y560T GI98/98',
    'MS.50002 LAH550Y620T GI50/50',
    'MS.50002 LAH550Y620T GI60/60',
    'MS.50002 LAH550Y620T GI70/70',
    'MS.50002 LAH550Y620T GI90/90',
    'MS.50002 LAH550Y620T GI98/98',
    'MS.50002 TRC400Y690T GI50/50',
    'MS.50002 TRC400Y690T GI60/60',
    'MS.50002 TRC400Y690T GI70/70',
    'MS.50002 TRC400Y690T GI90/90',
    'MS.50002 TRC400Y690T GI98/98',
    'MS.50002 TRC440Y780T GI50/50',
    'MS.50002 TRC440Y780T GI60/60',
    'MS.50002 TRC440Y780T GI70/70',
    'MS.50002 TRC440Y780T GI90/90',
    'MS.50002 TRC440Y780T GI98/98',
  ],
  'ford hot dipped hsla wsb-m1a215': [
    'WSB-M1A215-E1 Grade 250',
    'WSB-M1A215-E1 Grade 300',
    'WSB-M1A215-E1 Grade 350',
    'WSB-M1A215-E1 Grade 400',
    'WSB-M1A215-E1 Grade 450',
    'WSB-M1A215-E1 Grade 500',
    'WSB-M1A215-E1 Grade 550',
    'WSB-M1A215-E1 Grade 700',
    'WSB-M1A215-E1 Grade 1000',
    'WSB-M1A215-F1 Grade 220',
    'WSB-M1A215-F1 Grade 250',
    'WSB-M1A215-F1 Grade 300',
    'WSB-M1A215-F1 Grade 350',
    'WSB-M1A215-F1 Grade 400',
    'WSB-M1A215-F1 Grade 450',
    'WSB-M1A215-F1 Grade 500',
    'WSB-M1A215-F1 Grade 550',
    'WSB-M1A215-F1 Grade 600',
    'WSB-M1A215-F1 Grade 700',
    'WSB-M1A215-F1 Grade 800',
    'WSB-M1A215-F1 Grade 1000',
  ],
  'ford wsd-m1a333 hot dipped low carbon': [
    'WSD-M1A333-A1',
    'WSD-M1A333-A2',
    'WSD-M1A333-A3',
    'WSD-M1A333-A4',
  ],
  'ford wss-m1a341 bake hardenable & dent resistant': [
    'WSS-M1A341-A1',
    'WSS-M1A341-A2',
    'WSS-M1A341-A3',
    'WSS-M1A341-A4',
    'WSS-M1A341-A5',
    'WSS-M1A341-A6',
    'WSS-M1A341-A7',
    'WSS-M1A341-A8',
    'WSS-M1A341-A9',
    'WSS-M1A341-A10',
  ],
  'ford wss-m1a345-a (cr based) low carbon': [
    'WSS-M1A345-A1',
    'WSS-M1A345-A2',
    'WSS-M1A345-A3',
    'WSS-M1A345-A4',
  ],
  'ford wss-m1a355-a (hr based) low carbon': ['WSS-M1A355-A'],
  'ford wss-m1a346 (hr based) hsla steel': [
    'WSS-M1A346-A1',
    'WSS-M1A346-A2',
    'WSS-M1A346-A3',
    'WSS-M1A346-A4',
    'WSS-M1A346-A5',
    'WSS-M1A346-A6',
  ],
  'ford wss-m1a348 hot dipped dual phase': [
    'WSS-M1A348-A1',
    'WSS-M1A348-A2',
    'WSS-M1A348-A3',
    'WSS-M1A348-A4',
    'WSS-M1A348-A5',
    'WSS-M1A348-A6',
    'WSS-M1A348-A7',
    'WSS-M1A348-A8',
    'WSS-M1A348-A9',
  ],
  'ford wss-m1a351 hot dipped trip': ['WSS-M1A351-A1', 'WSS-M1A351-A2', 'WSS-M1A351-A3'],
  'wss-m1a356 hot dipped': ['WSS-M1A356-A1', 'WSS-M1A356-A2', 'WSS-M1A356-A3'],
  'wss-m1a365 hot dipped': [
    'WSS-M1A365-A11',
    'WSS-M1A365-A12',
    'WSS-M1A365-A13',
    'WSS-M1A365-A14',
    'WSS-M1A365-A15',
    'WSS-M1A365-A20',
    'WSS-M1A365-A21',
    'WSS-M1A365-A22',
    'WSS-M1A365-A23',
  ],
  'wss-m1a367 hot dipped': [
    'WSS-M1A367-A11',
    'WSS-M1A367-A12',
    'WSS-M1A367-A13',
    'WSS-M1A367-A14',
    'WSS-M1A367-A21',
    'WSS-M1A367-A22',
    'WSS-M1A367-A23',
    'WSS-M1A367-A24',
    'WSS-M1A367-A25',
    'WSS-M1A367-A33',
    'WSS-M1A367-A34',
    'WSS-M1A367-A35',
    'WSS-M1A367-A36',
    'WSS-M1A367-A37',
    'WSS-M1A367-A38',
    'WSS-M1A367-A39',
    'WSS-M1A367-A40',
    'WSS-M1A367-A44',
    'WSS-M1A367-A45',
    'WSS-M1A367-A46',
    'WSS-M1A367-A47',
    'WSS-M1A367-A48',
    'WSS-M1A367-A49',
    'WSS-M1A367-A50',
  ],
  'wss-m1a368 hot dipped': [
    'WSS-M 1A368-A52',
    'WSS-M1A368--A51',
    'WSS-M1A368-A11',
    'WSS-M1A368-A12',
    'WSS-M1A368-A13',
    'WSS-M1A368-A14',
    'WSS-M1A368-A15',
    'WSS-M1A368-A21',
    'WSS-M1A368-A22',
    'WSS-M1A368-A23',
    'WSS-M1A368-A24',
    'WSS-M1A368-A25',
    'WSS-M1A368-A31',
    'WSS-M1A368-A32',
    'WSS-M1A368-A41',
    'WSS-M1A368-A42',
    'WSS-M1A368-A43',
    'WSS-M1A368-A91',
    'WSS-M1A368-A92',
    'WSS-M1A368-AS3',
  ],
  'astm a653 hot dipped': [
    'ASTM653-BHS180',
    'ASTM653-BHS210',
    'ASTM653-BHS240',
    'ASTM653-BHS280',
    'ASTM653-BHS300',
    'ASTM653-CS TYPE A',
    'ASTM653-CS TYPE B',
    'ASTM653-CS TYPE C',
    'ASTM653-DDS',
    'ASTM653-EDDS',
    'ASTM653-FS TYPE A',
    'ASTM653-FS TYPE B',
    'ASTM653-HSLAS-F50',
    'ASTM653-HSLAS-F60',
    'ASTM653-HSLAS-F70',
    'ASTM653-HSLAS-F80',
    'ASTM653-HSLAS40',
    'ASTM653-HSLAS50',
    'ASTM653-HSLAS55 CLASS 1|2',
    'ASTM653-HSLAS60',
    'ASTM653-HSLAS70',
    'ASTM653-HSLAS80',
    'ASTM653-SS33',
    'ASTM653-SS37',
    'ASTM653-SS40',
    'ASTM653-SS50 CLASS 1|2|3|4',
    'ASTM653-SS55',
    'ASTM653-SS80 CLASS 1|2',
  ],
  'volkswagen vw 50111 hot dipped': [
    'VW 50111-St03 Z',
    'VW 50111-St05 Z',
    'VW 50111-St06 Z',
    'VW 50111-St07 Z',
    'VW 50111-ZStE 220 Z',
    'VW 50111-ZStE 260 Z',
    'VW 50111-ZStE 300 Z',
    'VW 50111-ZStE 340 Z',
  ],
  'volkswagen vw 50060 hot dipped dual phase': [
    'VW 50060-1-HC260XD',
    'VW 50060-1-HC300XD',
    'VW 50060-1-HC340XD',
    'VW 50060-1-HC450XD',
    'VW 50060-1-HC600XD',
    'VW 50060-1-HC660XD',
    'VW 50060-2-HD330XD',
    'VW 50060-3-HC500CD',
    'VW 50060-3-HC600CD',
    'VW 50060-3-HC700CD',
    'VW 50060-3-HC800CD',
    'VW 50060-4-HD620CD',
    'VW 50060-4-HD680CD',
    'VW 50060-4-HD820CD',
    'VW 50060-5-HC410TD',
  ],
  'din en10346 or en10327 hot dipped': [
    'DX51D+Z',
    'DX52D+Z',
    'DX53D+Z',
    'DX54D+Z',
    'DX56D+Z',
    'DX57D+Z',
  ],
  'din en10346 or en10292 high strength': [
    'HCT450X+Z',
    'HCT500X+Z',
    'HCT600C+Z',
    'HCT600X+Z',
    'HCT690T+Z',
    'HCT780C+Z',
    'HCT780T+Z',
    'HCT780X+Z',
    'HCT980C+Z',
    'HCT980X+Z',
    'HDT450F+Z',
    'HDT560F+Z',
    'HDT580X+Z',
    'HDT750C+Z',
    'HDT780C+Z',
    'HDT950C+Z',
    'HDT1200M+Z',
    'HX160YD+Z',
    'HX180BD+Z',
    'HX180YD+Z',
    'HX220BD+Z',
    'HX220PD+Z',
    'HX220YD+Z',
    'HX260BD+Z',
    'HX260LAD+Z',
    'HX260PD+Z',
    'HX260YD+Z',
    'HX300BD+Z',
    'HX300LAD+Z',
    'HX300YD+Z',
    'HX380LAD+Z',
    'HX420LAD+Z',
    'HX500LAD+Z',
  ],
  'tm-2011 hot dipped (mild)': [
    'TM-2011 CR1-140-270-S-GI60/60',
    'TM-2011 CR1-140-270-S-GI60/60',
    'TM-2011 CR3-140-270-S-GI60/60',
    'TM-2011 CR4-140-270-S-GI60/60',
    'TM-2011 CR5-110-260-S-GI60/60',
    'TM-2011 HR2-180-270-S-GI60/60',
    'TM-2011 HR3-180-270-S-GI60/60',
  ],
  'tm-2011 hot dipped (hsla)': [
    'TM-2011 CR-340-410-LA-S-GI60/60',
    'TM-2011 CR-420-480-LA-S-GI60/60',
    'TM-2011 CR-460-520-LA-S-GI60/60',
    'TM-2011 CR-500-560-LA-S-GI60/60',
    'TM-2011 CR-550-650-LA-S-GI60/60',
    'TM-2011 HR-340-420-LA-S-GI60/60',
    'TM-2011 HR-420-480-LA-S-GI60/60',
    'TM-2011 HR-460-520-LA-S-GI60/60',
    'TM-2011 HR-500-560-LA-S-GI60/60',
    'TM-2011 HR-550-610-LA-S-GI60/60',
    'TM-2011 HR-650-700-LA-S-GI60/60',
    'TM-2011 HR-700-750-LA-S-GI60/60',
  ],
  'tm-2011 hot dipped (bake hardenable)': [
    'TM-2011 CR-180-290-BH-S-GI60/60',
    'TM-2011 CR-180-340-BH-S-GI60/60',
    'TM-2011 CR-180-390-BH-S-GI60/60',
  ],
  'tm-2011 hot dipped (dual phase)': [
    'TM-2011 CR-290-490-DP-S-GI60/60',
    'TM-2011 CR-330-590-DP-S-GI60/60',
    'TM-2011 CR-440-780-DP-S-GI60/60',
    'TM-2011 CR-590-980-DP-S-GI60/60',
    'TM-2011 CR-700-980-DP-S-GI60/60',
    'TM-2011 CR-900-1180-DP-S-GI60/60',
    'TM-2011 CR-1150-1270-DP-S-GI60/60',
    'TM-2011 HR-330-580-DP-S-GI60/60',
  ],
  'tm-2011 hot dipped (trip steel)': [
    'TM-2011 CR-400-690-TR-GI60/60',
    'TM-2011 CR-450-780-TR-GI60/60',
    'TM-2011 CR-600-980-TR-GI60/60',
    'TM-2011 CR-850-1180-TR-GI60/60',
  ],
  'tm-2011 hot dipped (complex phase)': [
    'TM-2011 CR-570-780-CP-GI60/60',
    'TM-2011 CR-600-980-CP-GI60/60',
    'TM-2011 CR-700-1050-CP-GI60/60',
    'TM-2011 CR-850-1180-CP-GI60/60',
    'TM-2011 HR-660-760-CP-GI60/60',
  ],
};
