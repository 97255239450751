import { makeStyles } from 'tss-react/mui';

export interface StyleProps {
  width: number;
}

const useStyles = makeStyles<{ width: number }>()((_, { width }) => ({
  iconHeader: {
    display: 'block',
    width,
  },
}));

const BrandClearIcon = ({ width }: StyleProps) => {
  const { classes } = useStyles({ width: width ?? 207 });
  return (
    <img
      className={classes.iconHeader}
      src="https://res.cloudinary.com/reibus/image/upload/dcf9k06hltaj0ni8l3af.svg"
      alt="Reibus International Logo"
    />
  );
};

export default BrandClearIcon;
