import type { GradeCategories } from '../types';

export const electroGalvanizedSteel: Partial<GradeCategories> = {
  'ms-6000 55p chrysler': [
    'MS-6000 55P CS',
    'MS-6000 55P DDS',
    'MS-6000 55P DS',
    'MS-6000 55P EDDS',
    'MS-6000 55VP-025HK',
    'MS-6000 55VP-025SK',
    'MS-6000 55VP-030HK',
    'MS-6000 55VP-030SK',
    'MS-6000 55VP-035HK',
    'MS-6000 55VP-035SK',
    'MS-6000 55VP-040HK',
    'MS-6000 55VP-040SK',
    'MS-6000 55VP-045HK',
    'MS-6000 55VP-045XK',
    'MS-6000 55VP-050XK',
    'MS-6000 55VP-060XK',
    'MS-6000 55VP-070XK',
    'MS-6000 55VP-080XK',
    'MS-6000 55VP-120XK',
    'MS-6000 55VP-190MT',
    'MS-6000 55VP-220MT',
    'MS-6000 55VP-490DT',
    'MS-6000 55VP-590DT',
    'MS-6000 55VP-590PT',
    'MS-6000 55VP-780DT',
  ],
  'ford wsb-m1a215 hsla': [
    'WSB-M1A215-E1 Grade 250',
    'WSB-M1A215-E1 Grade 300',
    'WSB-M1A215-E1 Grade 350',
    'WSB-M1A215-E1 Grade 400',
    'WSB-M1A215-E1 Grade 450',
    'WSB-M1A215-E1 Grade 500',
    'WSB-M1A215-E1 Grade 550',
    'WSB-M1A215-E1 Grade 700',
    'WSB-M1A215-F1 Grade 220',
    'WSB-M1A215-F1 Grade 250',
    'WSB-M1A215-F1 Grade 300',
    'WSB-M1A215-F1 Grade 350',
    'WSB-M1A215-F1 Grade 400',
    'WSB-M1A215-F1 Grade 450',
    'WSB-M1A215-F1 Grade 500',
    'WSB-M1A215-F1 Grade 550',
    'WSB-M1A215-F1 Grade 600',
    'WSB-M1A215-F1 Grade 700',
    'WSB-M1A215-F1 Grade 800',
    'WSB-M1A215-F1 Grade 1000',
  ],
  'ford wss-m1a325 / wss-m1a343 zinc nickel': ['WSS-M1A325-B1', 'WSS-M1A343-A2'],
  'ford wsd-m1a333 low carbon': [
    'WSD-M1A333-A1',
    'WSD-M1A333-A2',
    'WSD-M1A333-A3',
    'WSD-M1A333-A4',
  ],
  'ford wss-m1a341 bake hardenable and dent resistant': [
    'WSS-M1A341-A1',
    'WSS-M1A341-A2',
    'WSS-M1A341-A3',
    'WSS-M1A341-A4',
    'WSS-M1A341-A5',
    'WSS-M1A341-A6',
    'WSS-M1A341-A7',
    'WSS-M1A341-A8',
    'WSS-M1A341-A9',
    'WSS-M1A341-A10',
  ],
  'ford wss-m1a345-a (cr based) low carbon': [
    'WSS-M1A345-A1',
    'WSS-M1A345-A2',
    'WSS-M1A345-A3',
    'WSS-M1A345-A4',
  ],
  'ford wss-m1a346 (hr based) hsla steel': [
    'WSS-M1A346-A1',
    'WSS-M1A346-A2',
    'WSS-M1A346-A3',
    'WSS-M1A346-A4',
    'WSS-M1A346-A5',
    'WSS-M1A346-A6',
  ],
  'ford wss-m1a347 (cr based) hsla steel': [
    'WSS-M1A347-A1',
    'WSS-M1A347-A2',
    'WSS-M1A347-A3',
    'WSS-M1A347-A4',
    'WSS-M1A347-A5',
    'WSS-M1A347-A6',
  ],
  'ford wss-m1a348 dual phase steel': [
    'WSS-M1A348-A1',
    'WSS-M1A348-A2',
    'WSS-M1A348-A3',
    'WSS-M1A348-A4',
    'WSS-M1A348-A5',
    'WSS-M1A348-A6',
    'WSS-M1A348-A7',
    'WSS-M1A348-A8',
    'WSS-M1A348-A9',
  ],
  'ford wss-m1a351 trip': ['WSS-M1A351-A1', 'WSS-M1A351-A2', 'WSS-M1A351-A3'],
  'wss-m1a355 low carbon': ['WSS-M1A355-A'],
  'wss-m1a368 advanced high strength': [
    'WSS-M1A368-A11',
    'WSS-M1A368-A12',
    'WSS-M1A368-A13',
    'WSS-M1A368-A14',
    'WSS-M1A368-A15',
    'WSS-M1A368-A21',
    'WSS-M1A368-A22',
    'WSS-M1A368-A23',
    'WSS-M1A368-A24',
    'WSS-M1A368-A25',
    'WSS-M1A368-A31',
    'WSS-M1A368-A32',
    'WSS-M1A368-A41',
    'WSS-M1A368-A42',
    'WSS-M1A368-A43',
    'WSS-M1A368-A51',
    'WSS-M1A368-A52',
    'WSS-M1A368-A53',
    'WSS-M1A368-A61',
    'WSS-M1A368-A62',
    'WSS-M1A368-A71',
    'WSS-M1A368-A72',
    'WSS-M1A368-A81',
    'WSS-M1A368-A82',
    'WSS-M1A368-A83',
    'WSS-M1A368-A84',
    'WSS-M1A368-A91',
    'WSS-M1A368-A92',
  ],
  'wss-m1a356 (hr based)': ['WSS-M1A356-A1', 'WSS-M1A356-A2', 'WSS-M1A356-A3'],
  'wss-m1a365 electro galvanized': [
    'WSS-M1A365-A11',
    'WSS-M1A365-A12',
    'WSS-M1A365-A13',
    'WSS-M1A365-A14',
    'WSS-M1A365-A15',
    'WSS-M1A365-A20',
    'WSS-M1A365-A21',
    'WSS-M1A365-A22',
    'WSS-M1A365-A23',
  ],
  'wss-m1a367 electro galvanized': [
    'WSS-M1A367-A11',
    'WSS-M1A367-A12',
    'WSS-M1A367-A13',
    'WSS-M1A367-A14',
    'WSS-M1A367-A21',
    'WSS-M1A367-A22',
    'WSS-M1A367-A23',
    'WSS-M1A367-A24',
    'WSS-M1A367-A25',
    'WSS-M1A367-A33',
    'WSS-M1A367-A34',
    'WSS-M1A367-A35',
    'WSS-M1A367-A36',
    'WSS-M1A367-A37',
    'WSS-M1A367-A38',
    'WSS-M1A367-A39',
    'WSS-M1A367-A40',
    'WSS-M1A367-A44',
    'WSS-M1A367-A45',
    'WSS-M1A367-A46',
    'WSS-M1A367-A47',
    'WSS-M1A367-A48',
    'WSS-M1A367-A49',
    'WSS-M1A367-A50',
  ],
  'din en10152 electro galvanized': [
    'DC01+ZE',
    'DC03+ZE',
    'DC04+ZE',
    'DC05+ZE',
    'DC06+ZE',
    'DC07+ZE',
  ],
  'din en10152 electro galvanized zinc nickel': [
    'DC01+ZN',
    'DC03+ZN',
    'DC04+ZN',
    'DC05+ZN',
    'DC06+ZN',
    'DC07+ZN',
  ],
  'din en10268 electro galvanized': [
    'HC180B+ZE',
    'HC180P+ZE',
    'HC180Y+ZE',
    'HC220B+ZE',
    'HC220I+ZE',
    'HC220P+ZE',
    'HC220Y+ZE',
    'HC260B+ZE',
    'HC260I+ZE',
    'HC260LA+ZE',
    'HC260P+ZE',
    'HC260Y+ZE',
    'HC300B+ZE',
    'HC300I+ZE',
    'HC300LA+ZE',
    'HC300P+ZE',
    'HC340LA+ZE',
    'HC380LA+ZE',
    'HC420LA+ZE',
  ],
  'din en10336 multiphase (cr base)': [
    'HCT450X+ZE',
    'HCT500X+ZE',
    'HCT600C+ZE',
    'HCT600X+ZE',
    'HCT690T+ZE',
    'HCT780C+ZE',
    'HCT780T+ZE',
    'HCT980C+ZE',
    'HCT980X+ZE',
  ],
  'din en10336 multiphase (hr base)': [
    'HDT450F+ZE',
    'HDT560F+ZE',
    'HDT750C+ZE',
    'HDT780X+ZE',
    'HDT950C+ZE',
    'HDT1200M+ZE',
  ],
  'volkswagen vw 50111': [
    'VW 501 11-St03 ZE',
    'VW 501 11-St05 ZE',
    'VW 501 11-St06 ZE',
    'VW 501 11-St07 ZE',
    'VW 501 11-ZStE 220 ZE',
    'VW 501 11-ZStE 260 ZE',
    'VW 501 11-ZStE 300 ZE',
    'VW 501 11-ZStE 340 ZE',
  ],
  'volkswagen vw 50060': [
    'VW 50060-1-HC260X+ZE',
    'VW 50060-1-HC300X+ZE',
    'VW 50060-1-HC340X+ZE',
    'VW 50060-1-HC450X+ZE',
    'VW 50060-1-HC600X+ZE',
    'VW 50060-1-HC660X+ZE',
    'VW 50060-2-HD330X+ZE',
    'VW 50060-3-HC500C+ZE',
    'VW 50060-3-HC600C+ZE',
    'VW 50060-3-HC700C+ZE',
    'VW 50060-4-HD620C+ZE',
    'VW 50060-4-HD680C+ZE',
    'VW 50060-4-HD820C+ZE',
    'VW 50060-5-HC410T+ZE',
  ],
  'general motors gmw2m': [
    'GMW2M-ST-S-CR1',
    'GMW2M-ST-S-CR2',
    'GMW2M-ST-S-CR3',
    'GMW2M-ST-S-CR4',
    'GMW2M-ST-S-CR5',
    'GMW2M-ST-S-HR0',
    'GMW2M-ST-S-HR1',
    'GMW2M-ST-S-HR2',
    'GMW2M-ST-S-HR3',
  ],
  'gm gmw3032m bake hardenable / dent resistant': [
    'GMW3032M-ST-S-CR180B0',
    'GMW3032M-ST-S-CR180B2',
    'GMW3032M-ST-S-CR180P',
    'GMW3032M-ST-S-CR210B0',
    'GMW3032M-ST-S-CR210B2',
    'GMW3032M-ST-S-CR210P',
    'GMW3032M-ST-S-CR240B0',
    'GMW3032M-ST-S-CR240B2',
    'GMW3032M-ST-S-CR270B0',
    'GMW3032M-ST-S-CR270B2',
    'GMW3032M-ST-S-CR300B0',
    'GMW3032M-ST-S-CR300B2',
  ],
  'gm gmw3032m hsla': [
    'GMW3032M-ST-S-CR270LA',
    'GMW3032M-ST-S-CR300LA',
    'GMW3032M-ST-S-CR340LA',
    'GMW3032M-ST-S-CR380LA',
    'GMW3032M-ST-S-CR420LA',
    'GMW3032M-ST-S-CR500LA',
    'GMW3032M-ST-S-CR550LA',
    'GMW3032M-ST-S-HR240LA',
    'GMW3032M-ST-S-HR270LA',
    'GMW3032M-ST-S-HR300LA',
    'GMW3032M-ST-S-HR340LA',
    'GMW3032M-ST-S-HR380LA',
    'GMW3032M-ST-S-HR420LA',
    'GMW3032M-ST-S-HR500LA',
    'GMW3032M-ST-S-HR550LA',
    'GMW3032M-ST-S-HR650LA',
    'GMW3032M-ST-S-HR700LA',
  ],
  'gm gmw3399m dual phase': [
    'GMW3399M-ST-S-CR450T/250Y-DP',
    'GMW3399M-ST-S-CR490T/290Y-DP',
    'GMW3399M-ST-S-CR590T/280Y-DP',
    'GMW3399M-ST-S-CR590T/340Y-DP',
    'GMW3399M-ST-S-CR590T/420Y-DP',
    'GMW3399M-ST-S-CR690T/550Y-DP',
    'GMW3399M-ST-S-CR780T/420Y-DP',
    'GMW3399M-ST-S-CR780T/500Y-DP',
    'GMW3399M-ST-S-CR980T/550Y-DP',
    'GMW3399M-ST-S-CR980T/650Y-DP',
    'GMW3399M-ST-S-HR580T/330Y-DP',
    'GMW3399M-ST-S-HR780T/450Y-DP',
  ],
  'gm gmw3399m multi phase': ['GMW3399M-ST-S-CR980T/700Y-MP', 'GMW3399M-ST-S-CR980T/700Y-MP-LCE'],
  'gm gmw3399m trip': [
    'GMW3399M-ST-S-CR590T/380Y-TR',
    'GMW3399M-ST-S-CR690T/410Y-TR',
    'GMW3399M-ST-S-CR780T/440Y-TR',
  ],
  'gm gmw3399m martensitic': ['GMW3399M-ST-S-HR1200T/900Y-MS', 'GMW3399M-ST-S-HR1400T/1050Y-MS'],
  'ms.50002 electro galvanized': [
    'MS.50002 MTC1030Y1300T EG60/60',
    'MS.50002 MTC1200Y1500T EG60/60',
  ],
};
