import * as React from 'react';

import { NotificationsContextProvider } from './context';
import Notifications from './Notifications';

const NotificationsWithContext = () => (
  <NotificationsContextProvider>
    <Notifications />
  </NotificationsContextProvider>
);

export default NotificationsWithContext;
