import { intervalToDuration } from 'date-fns';

type TimeUnit = 'minute' | 'hour' | 'month' | 'year' | 'day' | 'second';

const timeFormatted = (unit: TimeUnit, value) => {
  return { unit, elapsedTimeValue: value === 0 ? 1 : value };
};

export const elapsedTime = (startDate: Date, endDate: Date) => {
  const {
    years = 0,
    months = 0,
    days = 0,
    minutes = 0,
    hours = 0,
  } = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  if (years >= 1) {
    return timeFormatted('year', years);
  }

  if (months >= 1) {
    return timeFormatted('month', months);
  }

  if (days >= 1) {
    return timeFormatted('day', days);
  }

  if (hours >= 1) {
    return timeFormatted('hour', hours);
  }

  return timeFormatted('minute', minutes);
};

export const getElapsedTimeGroup = (hoursElapsedNotification: number, unit: TimeUnit): string => {
  if (unit === 'minute' || unit === 'second') {
    return 'Today';
  }

  if (unit === 'hour') {
    const date = new Date();
    const todayMidNight = new Date();
    todayMidNight.setHours(0, 0, 0, 0);

    const { hours: hoursElapsedToday = 0 } = intervalToDuration({
      start: todayMidNight,
      end: date,
    });

    const isToday = hoursElapsedToday - hoursElapsedNotification >= 0;

    return isToday ? 'Today' : 'Yesterday';
  }
  if (hoursElapsedNotification === 1 && unit === 'day') {
    return 'Yesterday';
  }
  return 'Older';
};
