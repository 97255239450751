import { makeStyles } from 'tss-react/mui';

import BrandClearIcon from 'components/icons/BrandClearIcon';

import { updateHeaderStylesForEnvironment } from './updateHeaderStylesForEnvironment';

const useStyles = makeStyles()(() => {
  const styles = {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    root: {
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#5d0549',
    },
  };

  return updateHeaderStylesForEnvironment(styles);
});

const LogoOnlyHeader = () => {
  const { classes } = useStyles();

  return (
    <header className={classes.root}>
      <div className={classes.wrapper}>
        <BrandClearIcon width={105} />
      </div>
    </header>
  );
};

export default LogoOnlyHeader;
