import {
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useUserPreferences, useUpdateUserPreferences } from '@reibus/frontend-utility';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from 'tss-react/mui';

import NavCaret from 'components/icons/NavCaret';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';

import { useSupportedLocales } from './supported-locales';

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
    padding: '0.688rem 0 0.688rem 0.625rem',
    '&:hover': {
      backgroundColor: '#3B0B31',
    },
  },
  input: {
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      color: 'white',
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
      top: '.1234rem',
      left: '-19px',
      '@media (max-width: 1440px)': {
        left: '-30px',
      },
    },
  },
  icon: {
    marginRight: 10,
    marginTop: 12,
    '& img': {
      minWidth: '26px',
    },
  },
  inputMarketing: {
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #5d0549',
      color: '#5d0549',
      marginLeft: 8,
      borderRadius: 8,
      backgroundColor: '#fff',
    },
  },
  iconMarketing: {
    marginLeft: 10,
  },
  typographyMarketing: {
    marginLeft: 8,
    marginTop: 4,
    fontSize: 13,
    fontWeight: 400,
  },
  inputHiddenText: {
    '& .MuiInputBase-input': {
      '& p': {
        display: 'none',
      },
    },
  },
}));

const LocalePicker = () => {
  const { preferredLocale } = useUserPreferences();
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const { classes, cx } = useStyles();
  const isCountryLabelDisplay = useMediaQuery('(max-width:1440px)');
  const { northAmericanSiteEuFeatures } = useFlags();
  const supportedLocales = useSupportedLocales();
  const locales = Object.keys(supportedLocales);

  const handleChange = event => {
    trackEvent(TOP_MENU_CLICK_EVENT, {
      title: `Locale Picker - ${event.target.value}`,
      url: 'N/A',
    });
    updateUserPreferences({
      preferredLocale: event.target.value,
    });
  };

  const hideLocalePicker =
    (process.env.REGION !== 'EU' && !northAmericanSiteEuFeatures) ||
    locales.length <= 1 ||
    !preferredLocale;

  return hideLocalePicker ? null : (
    <Grid container justifyContent="center" alignContent="center" className={classes.container}>
      <Grid item>
        <FormControl variant="standard">
          <Select
            variant="standard"
            value={preferredLocale}
            onChange={handleChange}
            IconComponent={NavCaret}
            input={
              <InputBase
                className={cx(classes.input, { [classes.inputHiddenText]: isCountryLabelDisplay })}
              />
            }
          >
            {locales.map(locale => {
              return (
                <MenuItem value={locale} key={`locale-picker-${locale}`}>
                  <span className={classes.icon}>{supportedLocales[locale].icon}</span>
                  <Typography variant="body2" className={classes.typographyMarketing}>
                    {supportedLocales[locale].label}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default LocalePicker;
