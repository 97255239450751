import * as React from 'react';

type ContextType = {
  currentDropdown?: string | null;
  setCurrentDropdown: (dropdown: string) => void;
  closeDropdown: () => void;
};

const NavContext = React.createContext<ContextType>(undefined);

function NavContextProvider({ children }) {
  const [currentDropdown, setCurrentDropdown] = React.useState<string | null>();
  const closeDropdown = () => setCurrentDropdown(null);

  return (
    <NavContext.Provider value={{ currentDropdown, setCurrentDropdown, closeDropdown }}>
      {children}
    </NavContext.Provider>
  );
}

function useNavContext() {
  const context = React.useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNavContext must be used within a NavContextProvider');
  }

  return context;
}

export { NavContextProvider, useNavContext };
