import { useCallback } from 'react';

import { navigateToUrl } from 'single-spa';

type Config = {
  redirectOnLogin?: boolean;
};

const useForceLogin = ({ redirectOnLogin = true }: Config = {}) => {
  const {
    location: { pathname, search },
  } = window;

  return useCallback(
    () =>
      redirectOnLogin
        ? navigateToUrl('/login', {
            state: { from: `${pathname}${search ?? ''}` },
          })
        : navigateToUrl('/login'),
    [search, pathname, redirectOnLogin]
  );
};

export default useForceLogin;
