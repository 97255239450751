import {
  Button as MuiButton,
  ButtonProps,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';

type ReibusButtonProps = NonTertiaryButtonProps | TertiaryButtonProps;

interface NonTertiaryButtonProps extends ButtonProps {
  kind?: 'primary' | 'secondary' | 'alternate' | 'quaternary';
  fill?: boolean;
}

interface TertiaryButtonProps extends ButtonProps {
  kind: 'tertiary';
  fill: boolean;
}

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: [`Montserrat`, `arial`, `sans-serif`].join(','),
    },
    palette: {
      primary: {
        main: '#2D6CCB',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#FFF',
        contrastText: '#2D6CCB',
      },
    },
  })
);

const CustomButton = withStyles(
  (props: ButtonProps) => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Button {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  ),
  {
    root: {
      minWidth: '7.5rem',
      height: '2.625rem',
      borderRadius: '8px',
      borderWidth: '2px !important',
      textTransform: 'none',
      fontWeight: 700,
      padding: '0rem 2rem',
    },
    label: {
      textTransform: 'none',
    },
  }
);

const useButtonStyles = makeStyles<
  ReibusButtonProps & Required<Pick<ReibusButtonProps, 'color' | 'fill'>>
>()((theme, { color, fill }) => ({
  root: {
    borderRadius: '0px',
    fontWeight: 700,
    fontSize: '16px',
    padding: '8px 16px',
    lineHeight: 1.5,
    textTransform: 'none',
  },
  primary: {
    '&:hover': {
      backgroundColor: `${theme.palette[color].main}D5`,
    },
    color: '#fff',
    borderRadius: '16px 0px',
  },
  secondary: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'unset',
    },
    padding: '8px 4px',
    // borderBottom: ({ color }) => `1px solid ${theme.palette[color].main}`,
  },
  alternate: {
    '&:hover': {
      backgroundColor: `${theme.palette[color].main}D5`,
    },
    color: '#fff',
  },
  tertiary: {
    backgroundColor: fill ? theme.palette[color].main : 'inherit',
    color: fill ? theme.palette[color].contrastText : '#707070',
    '&:hover': {
      backgroundColor: fill ? `${theme.palette[color].main}D5` : `${theme.palette[color].main}0A`,
    },
    fontSize: '12px',
  },
}));

const Button = ({
  children,
  kind = 'primary',
  fill = false,
  color = 'primary',
  ...rest
}: ReibusButtonProps) => {
  const { classes } = useButtonStyles({ fill, color, ...rest });

  if (kind === 'tertiary') {
    return (
      <MuiButton classes={{ root: `${classes.root} ${classes.tertiary}` }} color={color} {...rest}>
        {children}
      </MuiButton>
    );
  }

  if (kind === 'secondary') {
    return (
      <MuiButton
        classes={{ root: `${classes.root} ${classes.secondary}` }}
        variant="text"
        color={color}
        disableRipple
        disableFocusRipple
        {...rest}
      >
        {children}
      </MuiButton>
    );
  }

  if (kind === 'alternate') {
    return (
      <MuiButton
        classes={{ root: `${classes.root} ${classes.alternate}` }}
        variant="contained"
        color={color}
        {...rest}
      >
        {children}
      </MuiButton>
    );
  }

  if (kind === 'quaternary') {
    return (
      <CustomButton
        sx={{
          '&.Mui-disabled': {
            background: '#EDEEF0',
            color: '#868A8E',
          },
        }}
        color={color}
        {...rest}
      >
        {children}
      </CustomButton>
    );
  }

  return (
    <MuiButton
      classes={{ root: `${classes.root} ${classes.primary}` }}
      variant="contained"
      color={color}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
