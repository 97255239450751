import * as React from 'react';

type ContextType = {
  open: boolean;
  anchorEl: HTMLElement | null;
  openMenu: (anchor: HTMLElement) => void;
  closeMenu: () => void;
};

const NotificationsContext = React.createContext<ContextType>(undefined);

function NotificationsContextProvider({ children }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <NotificationsContext.Provider value={{ open, anchorEl, openMenu: setAnchorEl, closeMenu }}>
      {children}
    </NotificationsContext.Provider>
  );
}

function useNotificationsContext() {
  const context = React.useContext(NotificationsContext);

  if (context === undefined) {
    throw new Error('useNotificationsContext must be used within a NotificationsContextProvider');
  }

  return context;
}

export { NotificationsContextProvider, useNotificationsContext };
