import * as React from 'react';

import { makeStyles } from 'tss-react/mui';

import Flag, { CountryWithFlag } from 'components/icons/flags/Flag';
import NavCaret from 'components/icons/NavCaret';
import NonUserValidator from 'components/validation/NonUserValidator';

import { useNavContext } from './context';

const useStyles = makeStyles()(() => ({
  dropdownLabel: {
    color: '#fff',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    textTransform: 'capitalize',
  },
  menuItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px 14.5px',
    '&:hover': {
      background: '#3b0b31',
    },
  },
  smallDropdown: {
    position: 'absolute',
    top: '100%',
    zIndex: '11',
    visibility: 'hidden',
  },
  makeVisible: {
    visibility: 'visible',
  },
  componentWrapper: {
    paddingBottom: '33px',
    boxShadow: '0px 20px 35px 0px rgb(0 0 0 / 20%)',
    padding: '22px',
    backgroundColor: '#fff',
    flexGrow: 1,
    '& ul': {
      minWidth: '165px',
      margin: 0,
    },
    '& li': {
      listStyle: 'none',
      marginBottom: '11px',
      color: '#0d908d',
      '& a': {
        color: 'rgba(9, 10, 13, 0.87)',
        lineHeight: 1.25,
        '&:hover': {
          color: 'rgb(45, 108, 203)',
        },
      },
    },
  },
}));

export type SmallDropDownProps = {
  label: string;
  isShippingDropdown?: boolean;
  shippingLocation?: string;
};

export const SmallDropDown = <P extends object>(DropDownComponent: React.ComponentType<P>) => {
  // can't use const here due to react-hooks warning
  return function Wrapped({ label, isShippingDropdown, ...rest }: SmallDropDownProps & P) {
    const { currentDropdown, setCurrentDropdown } = useNavContext();
    const { classes, cx } = useStyles();

    return (
      <div
        className={classes.menuItem}
        onClick={() => {
          setCurrentDropdown(label);
        }}
        onMouseEnter={currentDropdown ? () => setCurrentDropdown(label) : undefined}
      >
        <div className={classes.dropdownLabel}>
          <span>{label}</span>
          <NavCaret />
          {isShippingDropdown ? <Flag country={rest.shippingLocation as CountryWithFlag} /> : null}
        </div>
        <div
          className={cx(classes.smallDropdown, {
            [classes.makeVisible]: currentDropdown === label,
          })}
        >
          <div className={classes.componentWrapper}>
            <NonUserValidator>
              <DropDownComponent {...(rest as P)} />
            </NonUserValidator>
          </div>
        </div>
      </div>
    );
  };
};
