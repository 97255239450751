import { useState } from 'react';

import { Box, Theme, ClickAwayListener } from '@mui/material';
import { useCurrentUser, useUserSession } from '@reibus/frontend-utility';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import BrandClearIcon from 'components/icons/BrandClearIcon';
import Link from 'components/SingleSpaLink';
import { useScreenSize } from 'hooks/useScreenSize';

import LocalePicker from './locale-picker';
import LogOutDropdown from './LogOutDropdown';
import { updateHeaderStylesForEnvironment } from './updateHeaderStylesForEnvironment';

const useStyles = makeStyles()((theme: Theme) => {
  const styles = {
    dashboardHeader: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
        paddingLeft: 0,
        padding: 0,
        marginLeft: theme.spacing(2),
      },
    },
    dropWrapper: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    logo: {
      minWidth: 105,
      marginRight: 8,
    },
    profile: {
      padding: 0,
    },
    language: {
      padding: 0,
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: -8,
      },
    },
    dashboard: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      '&:hover': {
        backgroundColor: '#3B0B31',
      },
    },
    dashboardLink: {
      textDecoration: 'none',
      color: '#fff',
      fontSize: 14,
      padding: theme.spacing(2),
      '&:hover': {
        textDecoration: 'none',
      },
    },
    root: {
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#5d0549',
    },
  };

  return updateHeaderStylesForEnvironment(styles);
});

const LimitedHeader = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('dashboard-nav');
  const [currentDropdown, setCurrentDropdown] = useState<string | null>(null);
  const { isDesktop } = useScreenSize();

  const { data: currentUser } = useCurrentUser();
  const { data: userSession } = useUserSession();

  const isLimitedAccessUser = userSession?.cognitoGroups.includes('limited-access');

  const userDisplayName = `${currentUser?.firstName} ${currentUser?.lastName}`.trim();

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    setCurrentDropdown(null);
    event.stopPropagation();
  };

  return (
    <header className={classes.root}>
      <div className={classes.dashboardHeader}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.wrapper}>
            <Box className={classes.logo}>
              <BrandClearIcon width={105} />
            </Box>
            <Box className={classes.dropWrapper}>
              {!isLimitedAccessUser && isDesktop ? (
                <Box className={classes.dashboard}>
                  <Link to="/app/dashboard" className={classes.dashboardLink}>
                    {t('dashboard')}
                  </Link>
                </Box>
              ) : null}
              <Box className={classes.language}>
                <LocalePicker />
              </Box>
              <Box className={classes.profile}>
                <LogOutDropdown
                  label={userDisplayName}
                  setCurrentDropdown={setCurrentDropdown}
                  currentDropdown={currentDropdown}
                  onClickAway={handleClickAway}
                />
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      </div>
    </header>
  );
};

export default LimitedHeader;
