import { countriesByCode } from '@reibus/reibus-core-utils';

import { AnonymousLocation } from 'types';
import { i18nSSR as i18n } from 'utils/i18n';

const COUNTRY_TARGETS = ['label', 'code', 'value'] as const;
export type CountryTarget = (typeof COUNTRY_TARGETS)[number];

const getCountryCode = (country: string) => countriesByCode[country]?.label ?? '-';
const getCountryByLabel = (country: string) =>
  i18n.t(`Country.${country}.label`, { ns: 'enums', defaultValue: '-' });

const getCountry = (country: string, countryTarget: CountryTarget) => {
  switch (countryTarget) {
    case 'label':
      return getCountryByLabel(country);
    case 'code':
      return getCountryCode(country);
    default:
      return country;
  }
};

type CalculateLotLocationParam = {
  anonymousLocation?: AnonymousLocation;
  country?: string | null;
  isCountryLocation: boolean;
  isEu: boolean;
  region?: string | null;
  shouldAnonymise: boolean;
  countryTarget: CountryTarget;
};

export const calculateOriginLocation = ({
  anonymousLocation,
  country,
  countryTarget,
  isCountryLocation,
  isEu,
  region,
  shouldAnonymise,
}: CalculateLotLocationParam) => {
  if (shouldAnonymise) {
    return i18n.t(`anonymousLocations.${anonymousLocation}`, {
      ns: 'listings',
      defaultValue: '-',
    });
  }

  if (isCountryLocation) {
    return getCountry(country ?? '-', countryTarget);
  }

  return !isEu && region ? `${region}, ${country}` : getCountry(country ?? '-', countryTarget);
};
