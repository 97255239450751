import * as React from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { navigateToUrl } from 'single-spa';
import { makeStyles } from 'tss-react/mui';

import SingleSpaLink from 'components/SingleSpaLink';

import { useNotificationsContext } from './context';
import { useMarkAsRead, useNotificationCounts } from './hooks';

const useStyles = makeStyles()({
  modalContainer: {},
  notificationContainer: {
    minHeight: 120,
    width: 500,
    borderBottom: '1px solid #EEEEEE',
    padding: '16px 0 16px 24px',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  leftContent: {
    width: 415,
    '& > *': {
      marginBottom: 6,
    },
  },
  titleUnread: {
    color: '#090A0D',
  },
  titleRead: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  action: {},
  badgeContainer: {
    width: 26,
  },
  tooltip: {
    backgroundColor: 'black',
  },
  tooltipArrow: {
    color: 'black',
  },
  hiddenBadge: {
    display: 'none',
    visibility: 'hidden',
  },
  badge: {
    width: 8,
    height: 8,
    margin: '9px auto',
    backgroundColor: '#5D0549',
    borderRadius: '50%',
  },
  text: {
    lineHeight: '120%',
  },
  body: {
    fontSize: '0.89rem',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '0.5rem',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1rem',
    margin: 0,
  },
  elapsedTimeFormatted: {
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0 0 0 .25rem',
    fontSize: '0.899rem',
  },
});

export type NotificationItemProps = {
  actions: {
    actionName: string;
    targetURL: string;
  }[];
  body: string;
  title: string;
  createdAt: string;
  id: string;
  isRead: boolean;
  elapsedTimeFormatted: string;
};

const NotificationItem = ({
  id,
  actions,
  body,
  title,
  elapsedTimeFormatted,
  isRead,
}: NotificationItemProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation('notifications');
  const { mutateAsync: markAsRead } = useMarkAsRead();
  const { closeMenu } = useNotificationsContext();

  const {
    data: { unread },
  } = useNotificationCounts();
  const isMessageRead = isRead || unread === 0;
  const markNotificationAsRead = async () => {
    const [{ targetURL }] = actions;
    const { hostname, pathname, search } = new URL(targetURL, window.location.origin);
    try {
      await markAsRead({ id, updateType: 'markAsClicked' });
      closeMenu();
      if (window.location.hostname !== hostname) {
        window.location.assign(targetURL);
      } else {
        navigateToUrl(pathname + search);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const triggerNotification = () => {
    markNotificationAsRead();
    closeMenu();
  };

  return (
    <div data-testid={`notification-item-${id}`} className={classes.notificationContainer}>
      <div className={classes.flexContainer}>
        <SingleSpaLink
          to="/#"
          className={classes.link}
          data-testid="notificationCard"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            triggerNotification();
          }}
        >
          <div className={cx(classes.leftContent, classes.text)}>
            <div
              className={cx(isMessageRead ? classes.titleRead : classes.titleUnread, classes.title)}
            >
              {title}
              <span className={classes.elapsedTimeFormatted}>{elapsedTimeFormatted}</span>
            </div>
            <p className={classes.body}>{body}</p>
            {actions
              ? actions.map((action, index) => (
                  <span className={classes.action} key={index}>
                    {action.actionName}
                  </span>
                ))
              : null}
          </div>
        </SingleSpaLink>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
        <div
          data-testid={`notification-badge-${id}`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            markNotificationAsRead();
          }}
          className={cx(classes.badgeContainer, { [classes.hiddenBadge]: isMessageRead })}
        >
          <Tooltip
            arrow
            title={`${t('markAsRead')}`}
            placement="left"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <div className={classes.badge} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
