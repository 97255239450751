import type { GradeCategories } from '../types';

export const galvannealedSteel: Partial<GradeCategories> = {
  'gmw2m galvannealed': [
    'GMW2M-ST-S-CR1',
    'GMW2M-ST-S-CR2',
    'GMW2M-ST-S-CR3',
    'GMW2M-ST-S-CR4',
    'GMW2M-ST-S-CR5',
    'GMW2M-ST-S-HR0',
    'GMW2M-ST-S-HR1',
    'GMW2M-ST-S-HR2',
    'GMW2M-ST-S-HR3',
  ],
  'gmw3032m bake hardenable / dent resistant': [
    'GMW3032M-ST-S-CR180B0',
    'GMW3032M-ST-S-CR180B2',
    'GMW3032M-ST-S-CR180P',
    'GMW3032M-ST-S-CR210B0',
    'GMW3032M-ST-S-CR210B2',
    'GMW3032M-ST-S-CR210P',
    'GMW3032M-ST-S-CR240B0',
    'GMW3032M-ST-S-CR240B2',
    'GMW3032M-ST-S-CR270B0',
    'GMW3032M-ST-S-CR270B2',
    'GMW3032M-ST-S-CR300B0',
    'GMW3032M-ST-S-CR300B2',
  ],
  'gmw3032m hsla': [
    'GMW3032M-ST-S-CR270LA',
    'GMW3032M-ST-S-CR300LA',
    'GMW3032M-ST-S-CR340LA',
    'GMW3032M-ST-S-CR380LA',
    'GMW3032M-ST-S-CR420LA',
    'GMW3032M-ST-S-CR500LA',
    'GMW3032M-ST-S-CR550LA',
    'GMW3032M-ST-S-HR240LA',
    'GMW3032M-ST-S-HR270LA',
    'GMW3032M-ST-S-HR300LA',
    'GMW3032M-ST-S-HR340LA',
    'GMW3032M-ST-S-HR380LA',
    'GMW3032M-ST-S-HR420LA',
    'GMW3032M-ST-S-HR500LA',
    'GMW3032M-ST-S-HR550LA',
    'GMW3032M-ST-S-HR650LA',
    'GMW3032M-ST-S-HR700LA',
  ],
  'gmw3399m dual phase': [
    'GMW3399M-ST-S-CR450T/250Y-DP',
    'GMW3399M-ST-S-CR490T/290Y-DP',
    'GMW3399M-ST-S-CR590T/280Y-DP',
    'GMW3399M-ST-S-CR590T/340Y-DP',
    'GMW3399M-ST-S-CR590T/420Y-DP',
    'GMW3399M-ST-S-CR690T/550Y-DP',
    'GMW3399M-ST-S-CR780T/420Y-DP',
    'GMW3399M-ST-S-CR780T/500Y-DP',
    'GMW3399M-ST-S-CR980T/550Y-DP',
    'GMW3399M-ST-S-CR980T/650Y-DP',
    'GMW3399M-ST-S-CR980T/650Y-DP',
    'GMW3399M-ST-S-HR580T/330Y-DP',
    'GMW3399M-ST-S-HR780T/450Y-DP',
  ],
  'gmw3399m trip': [
    'GMW3399M-ST-S-CR590T/380Y-TR',
    'GMW3399M-ST-S-CR690T/410Y-TR',
    'GMW3399M-ST-S-CR780T/440Y-TR',
  ],
  'gmw3399m martensitic': ['GMW3399M-ST-S-HR1200T/900Y-MS', 'GMW3399M-ST-S-HR1400T/1050Y-MS'],
  'gmw3399m multi-phase': ['GMW3399M-ST-S-CR980T/700Y-MP', 'GMW3399M-ST-S-CR980T/700Y-MP-LCE'],
  'ms-6000 44 chrysler': [
    'MS-6000 44VA-025HK',
    'MS-6000 44VA-025SK',
    'MS-6000 44VA-030HK',
    'MS-6000 44VA-030SK',
    'MS-6000 44VA-035HK',
    'MS-6000 44VA-035SK',
    'MS-6000 44VA-040HK',
    'MS-6000 44VA-040SK',
    'MS-6000 44VA-045HK',
    'MS-6000 44VA-045XK',
    'MS-6000 44VA-050XK',
    'MS-6000 44VA-060XK',
    'MS-6000 44VA-070XK',
    'MS-6000 44VA-080XK',
    'MS-6000 44VA-120XK',
    'MS-6000 44VA-190MT',
    'MS-6000 44VA-220MT',
    'MS-6000 44VA-490DT',
    'MS-6000 44VA-590DT',
    'MS-6000 44VA-590PT',
    'MS-6000 44VA-780DT',
    'MS-6000 44VA-980DT',
    'MS6000 44A CS',
    'MS6000 44A DDS',
    'MS6000 44A DS',
    'MS6000 44A EDDS',
  ],
  'ms.50002 galvannealed': [
    'MS.50002 BHC180Y290T GA45/45',
    'MS.50002 BHC210Y310T GA45/45',
    'MS.50002 BHC240Y340T GA45/45',
    'MS.50002 BHC280Y3800T GA45/45',
    'MS.50002 CR01 GA45/45',
    'MS.50002 CR02 GA45/45',
    'MS.50002 CR04 GA45/45',
    'MS.50002 CR05 GA45/45',
    'MS.50002 CR06 GA45/45',
    'MS.50002 DPC290Y490T GA45/45',
    'MS.50002 DPC340Y590T GA45/45',
    'MS.50002 DPC420Y780T GA45/45',
    'MS.50002 DPC420Y780T HF GA45/45',
    'MS.50002 DPC550Y980T GA45/45',
    'MS.50002 DPC700Y980T GA45/45',
    'MS.50002 DPC950Y1180T GA45/45',
    'MS.50002 DPH330Y580T GA45/45',
    'MS.50002 FBC440Y580T GA45/45',
    'MS.50002 FBH440Y580T GA45/45',
    'MS.50002 HR11 GA45/45',
    'MS.50002 HR12 GA45/45',
    'MS.50002 HR13 GA45/45',
    'MS.50002 LAC210Y310T GA45/45',
    'MS.50002 LAC240Y320T GA45/45',
    'MS.50002 LAC270Y330T GA45/45',
    'MS.50002 LAC300Y370T GA45/45',
    'MS.50002 LAC340Y410T GA45/45',
    'MS.50002 LAC380Y450T GA45/45',
    'MS.50002 LAC420Y480T GA45/45',
    'MS.50002 LAC500Y560T GA45/45',
    'MS.50002 LAC550Y620T GA45/45',
    'MS.50002 LAC830Y860T GA45/45',
    'MS.50002 LAH210Y310T GA45/45',
    'MS.50002 LAH240Y320T GA45/45',
    'MS.50002 LAH270Y330T GA45/45',
    'MS.50002 LAH300Y370T GA45/45',
    'MS.50002 LAH340Y410T GA45/45',
    'MS.50002 LAH380Y450T GA45/45',
    'MS.50002 LAH420Y480T GA45/45',
    'MS.50002 LAH500Y560T GA45/45',
    'MS.50002 LAH550Y620T GA45/45',
    'MS.50002 TRC400Y690T GA45/45',
    'MS.50002 TRC440Y780T GA45/45',
  ],
  'wsb-m1a215 galvannealed hsla': [
    'WSB-M1A215-E1 Grade 250',
    'WSB-M1A215-E1 Grade 300',
    'WSB-M1A215-E1 Grade 350',
    'WSB-M1A215-E1 Grade 400',
    'WSB-M1A215-E1 Grade 450',
    'WSB-M1A215-E1 Grade 500',
    'WSB-M1A215-E1 Grade 550',
    'WSB-M1A215-E1 Grade 700',
    'WSB-M1A215-E1 Grade 1000',
    'WSB-M1A215-F1 Grade 220',
    'WSB-M1A215-F1 Grade 250',
    'WSB-M1A215-F1 Grade 300',
    'WSB-M1A215-F1 Grade 350',
    'WSB-M1A215-F1 Grade 400',
    'WSB-M1A215-F1 Grade 450',
    'WSB-M1A215-F1 Grade 500',
    'WSB-M1A215-F1 Grade 550',
    'WSB-M1A215-F1 Grade 600',
    'WSB-M1A215-F1 Grade 700',
    'WSB-M1A215-F1 Grade 800',
    'WSB-M1A215-F1 Grade 1000',
  ],
  'wsd-m1a333 low carbon': ['WSD-M1A333-A1', 'WSD-M1A333-A2', 'WSD-M1A333-A3', 'WSD-M1A333-A4'],
  'wss-m1a341 bake hardenable': [
    'WSS-M1A341-A1',
    'WSS-M1A341-A2',
    'WSS-M1A341-A3',
    'WSS-M1A341-A4',
    'WSS-M1A341-A5',
    'WSS-M1A341-A6',
    'WSS-M1A341-A7',
    'WSS-M1A341-A8',
    'WSS-M1A341-A9',
  ],
  'wss-m1a345 low carbon': ['WSS-M1A345-A1', 'WSS-M1A345-A2', 'WSS-M1A345-A3', 'WSS-M1A345-A4'],
  'wss-m1a346 hsla': [
    'WSS-M1A346-A1',
    'WSS-M1A346-A2',
    'WSS-M1A346-A3',
    'WSS-M1A346-A4',
    'WSS-M1A346-A5',
    'WSS-M1A346-A6',
  ],
  'wss-m1a347 hsla': [
    'WSS-M1A347-A1',
    'WSS-M1A347-A2',
    'WSS-M1A347-A3',
    'WSS-M1A347-A4',
    'WSS-M1A347-A5',
    'WSS-M1A347-A6',
  ],
  'wss-m1a348 dual phase': [
    'WSS-M1A348-A1',
    'WSS-M1A348-A2',
    'WSS-M1A348-A3',
    'WSS-M1A348-A4',
    'WSS-M1A348-A5',
    'WSS-M1A348-A6',
    'WSS-M1A348-A7',
    'WSS-M1A348-A8',
    'WSS-M1A348-A9',
  ],
  'wss-m1a351 trip steel': ['WSS-M1A351-A1', 'WSS-M1A351-A2', 'WSS-M1A351-A3'],
  'wss-m1a355 low carbon': ['WSS-M1A355-A'],
  'wss-m1a356 hr based': ['WSS-M1A356-A1', 'WSS-M1A356-A2', 'WSS-M1A356-A3'],
  'wss-m1a365 galvannealed steel': [
    'WSS-M1A365-A11',
    'WSS-M1A365-A12',
    'WSS-M1A365-A13',
    'WSS-M1A365-A14',
    'WSS-M1A365-A15',
    'WSS-M1A365-A20',
    'WSS-M1A365-A21',
    'WSS-M1A365-A22',
    'WSS-M1A365-A23',
  ],
  'wss-m1a367 galvannealed steel': [
    'WSS-M1A367-A11',
    'WSS-M1A367-A12',
    'WSS-M1A367-A13',
    'WSS-M1A367-A14',
    'WSS-M1A367-A21',
    'WSS-M1A367-A22',
    'WSS-M1A367-A23',
    'WSS-M1A367-A24',
    'WSS-M1A367-A25',
    'WSS-M1A367-A33',
    'WSS-M1A367-A34',
    'WSS-M1A367-A35',
    'WSS-M1A367-A36',
    'WSS-M1A367-A37',
    'WSS-M1A367-A38',
    'WSS-M1A367-A39',
    'WSS-M1A367-A40',
    'WSS-M1A367-A44',
    'WSS-M1A367-A45',
    'WSS-M1A367-A46',
    'WSS-M1A367-A47',
    'WSS-M1A367-A48',
    'WSS-M1A367-A49',
    'WSS-M1A367-A50',
  ],
  'wss-m1a368 galvannealed steel': [
    'WSS-M1A368-A11',
    'WSS-M1A368-A12',
    'WSS-M1A368-A13',
    'WSS-M1A368-A14',
    'WSS-M1A368-A15',
    'WSS-M1A368-A21',
    'WSS-M1A368-A22',
    'WSS-M1A368-A23',
    'WSS-M1A368-A24',
    'WSS-M1A368-A25',
    'WSS-M1A368-A31',
    'WSS-M1A368-A32',
    'WSS-M1A368-A41',
    'WSS-M1A368-A42',
    'WSS-M1A368-A43',
    'WSS-M1A368-A51',
    'WSS-M1A368-A52',
    'WSS-M1A368-A53',
    'WSS-M1A368-A61',
    'WSS-M1A368-A62',
    'WSS-M1A368-A71',
    'WSS-M1A368-A72',
  ],
  'astm a653 galvannealed steel': [
    'ASTM653-BHS180',
    'ASTM653-BHS210',
    'ASTM653-BHS240',
    'ASTM653-BHS280',
    'ASTM653-BHS300',
    'ASTM653-CS TYPE A',
    'ASTM653-CS TYPE B',
    'ASTM653-CS TYPE C',
    'ASTM653-DDS',
    'ASTM653-EDDS',
    'ASTM653-FS TYPE A',
    'ASTM653-FS TYPE B',
    'ASTM653-HSLAS-F50',
    'ASTM653-HSLAS-F60',
    'ASTM653-HSLAS-F70',
    'ASTM653-HSLAS-F80',
    'ASTM653-HSLAS40',
    'ASTM653-HSLAS50',
    'ASTM653-HSLAS55 CLASS 1,2',
    'ASTM653-HSLAS60',
    'ASTM653-HSLAS70',
    'ASTM653-HSLAS80',
    'ASTM653-SS33',
    'ASTM653-SS37',
    'ASTM653-SS40',
    'ASTM653-SS50 CLASS 1,2,3,4',
    'ASTM653-SS55',
    'ASTM653-SS80 CLASS 1,2',
  ],
  'c071-04 honda': [
    'JAC260G',
    'JAC270A',
    'JAC270B',
    'JAC270C',
    'JAC270D',
    'JAC270E',
    'JAC270F',
    'JAC340H',
    'JAC340P',
    'JAC340W',
    'JAC390P',
    'JAC390W',
    'JAC440P',
    'JAC440W',
    'JAC590R',
    'JAC590Y',
    'JAC780R',
    'JAC780Y',
    'JAC980R',
    'JAC980Y',
    'JAH270C',
    'JAH270D',
    'JAH270E',
    'JAH370W',
    'JAH400W',
    'JAH440W',
  ],
  'gis 1503 subaru': [
    'GAC260G',
    'GAC270C',
    'GAC270D',
    'GAC270E',
    'GAC270F',
    'GAC340H',
    'GAC340P',
    'GAC340W',
    'GAC370W',
    'GAC390P',
    'GAC390W',
    'GAC440P',
    'GAC440W',
    'GAC590R',
    'GAH270C',
    'GAH270D',
    'GAH270E',
    'GAH370W',
    'GAH400W',
    'GAH440W',
    'GAH540W',
  ],
  'ms191-07 hyundai/kia': [
    'SGACC',
    'SGACD',
    'SGACE',
    'SGACSD',
    'SGACUD',
    'SGAFC 590DP',
    'SGAFC 590RA',
    'SGAFC 1180Y',
    'SGAHC',
    'SGAHD',
    'SGAHE',
    'SGAPH 310',
    'SGAPH 370',
    'SGAPH 400',
    'SGAPH 440',
    'SGARC 340-BH',
    'SGARC 340',
    'SGARC 340E',
    'SGARC 370',
    'SGARC 390',
    'SGARC 390E',
    'SGARC 440',
    'SGARC 440E',
  ],
  'ms82-1228 mitsubishi': [
    'MJAC270C',
    'MJAC270D',
    'MJAC270E',
    'MJAC270F',
    'MJAC270G',
    'MJAC340H',
    'MJAC340P',
    'MJAC340W',
    'MJAC390P',
    'MJAC390W',
    'MJAC440P',
    'MJAC440W',
    'MJAH270C',
    'MJAH270D',
    'MJAH270E',
    'MJAH310W',
    'MJAH370W',
    'MJAH400W',
    'MJAH440W',
  ],
  'm2027 nissan': [
    'SP781-340',
    'SP781-370',
    'SP781-390',
    'SP781-390',
    'SP781-440',
    'SP781, SP701',
    'SP781, SP701',
    'SP782-340',
    'SP782-440',
    'SP782, SP702',
    'SP782, SP702',
    'SP783-590',
    'SP783-780',
    'SP783-980',
    'SP783, SP703',
    'SP783, SP703',
    'SP784',
    'SP785-340',
    'SP789, SP709',
    'SP789, SP709',
    'SP791-370',
    'SP791-440',
    'SP791',
    'SP792',
    'SP793-590',
    'SP793',
  ],
  'tsg3109g toyota': [
    'SCGA270C',
    'SCGA270D',
    'SCGA270E',
    'SCGA270F',
    'SCGA340',
    'SCGA340HR',
    'SCGA370',
    'SCGA390',
    'SCGA390HR',
    'SCGA440',
    'SCGA440HR',
    'SCGA590',
    'SCGA590DU',
    'SCGA980DUB',
    'SHGA270C',
    'SHGA270D',
    'SHGA270E',
    'SHGA310',
    'SHGA370',
    'SHGA440',
    'SHGA440SF',
    'SHGA490SF',
    'SHGA590',
  ],
  'din en10346 or en10327': ['DX51D', 'DX52D', 'DX53D', 'DX54D', 'DX56D', 'DX57D'],
  'din en10346 or en10292 high strength': [
    'HCT450X+ZF',
    'HCT500X+ZF',
    'HCT600C+ZF',
    'HCT600X+ZF',
    'HCT690T+ZF',
    'HCT780C+ZF',
    'HCT780T+ZF',
    'HCT780X+ZF',
    'HCT980C+ZF',
    'HCT980X+ZF',
    'HDT450F+ZF',
    'HDT560F+ZF',
    'HDT580X+ZF',
    'HDT750C+ZF',
    'HDT780C+ZF',
    'HDT950C+ZF',
    'HDT1200M+ZF',
    'HX160YD+ZF',
    'HX180BD+ZF',
    'HX180YD+ZF',
    'HX220BD+ZF',
    'HX220PD+ZF',
    'HX220YD+ZF',
    'HX260BD+ZF',
    'HX260LAD+ZF',
    'HX260PD+ZF',
    'HX260YD+ZF',
    'HX300BD+ZF',
    'HX300LAD+ZF',
    'HX300YD+ZF',
    'HX380LAD+ZF',
    'HX420LAD+ZF',
    'HX500LAD+ZF',
  ],
  'volkswagen vw 50111': [
    'VW 501 11-St03 ZF100',
    'VW 501 11-St03 ZF140',
    'VW 501 11-St05 ZF100',
    'VW 501 11-St05 ZF140',
    'VW 501 11-St06 ZF100',
    'VW 501 11-St06 ZF140',
    'VW 501 11-St07 ZF100',
    'VW 501 11-St07 ZF140',
    'VW 501 11-ZStE 220 ZF100',
    'VW 501 11-ZStE 220 ZF140',
    'VW 501 11-ZStE 260 ZF100',
    'VW 501 11-ZStE 260 ZF140',
    'VW 501 11-ZStE 300 ZF100',
    'VW 501 11-ZStE 300 ZF140',
    'VW 501 11-ZStE 340 ZF100',
    'VW 501 11-ZStE 340 ZF140',
  ],
  'volkswagen vw 50060 dual phase/trip/complex phase': [
    'VW 50060-1-HC260XD ZF100',
    'VW 50060-1-HC260XD ZF140',
    'VW 50060-1-HC300XD ZF100',
    'VW 50060-1-HC300XD ZF140',
    'VW 50060-1-HC340XD ZF100',
    'VW 50060-1-HC340XD ZF140',
    'VW 50060-1-HC450XD ZF100',
    'VW 50060-1-HC450XD ZF140',
    'VW 50060-1-HC600XD ZF100',
    'VW 50060-1-HC600XD ZF140',
    'VW 50060-1-HC660XD ZF100',
    'VW 50060-1-HC660XD ZF140',
    'VW 50060-2-HC330XD ZF140',
    'VW 50060-2-HD330XD ZF100',
    'VW 50060-3-HC500CD ZF100',
    'VW 50060-3-HC500CD ZF140',
    'VW 50060-3-HC600CD ZF100',
    'VW 50060-3-HC600CD ZF140',
    'VW 50060-3-HC700CD ZF100',
    'VW 50060-3-HC700CD ZF140',
    'VW 50060-3-HC800CD ZF100',
    'VW 50060-3-HC800CD ZF140',
    'VW 50060-4-HD620CD ZF100',
    'VW 50060-4-HD620CD ZF140',
    'VW 50060-4-HD680CD ZF100',
    'VW 50060-4-HD680CD ZF140',
    'VW 50060-4-HD820CD ZF100',
    'VW 50060-4-HD820CD ZF140',
    'VW 50060-5-HC410TD ZF100',
    'VW 50060-5-HC410TD ZF140',
  ],
};
