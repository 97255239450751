import axios from 'axios';

export const getUserNotifications = async (
  Authorization: string,
  viewType: string,
  page: number = 1
): Promise<any> => {
  const url = `${process.env.API_URL}/notifications/all?&view=${viewType}&page=${page}`;
  const config = {
    headers: {
      Authorization,
    },
  };

  const result = await axios.get(url, config);
  return {
    source: result.config.url,
    ...result.data,
  };
};

export const markNotificationAsRead = async (
  Authorization: string,
  id: string,
  updateType: string
) => {
  const url = `${process.env.API_URL}/notifications`;
  const config = {
    headers: {
      Authorization,
    },
  };

  const request = {
    data: {
      id,
      updateType,
    },
  };

  const { data } = await axios.put(url, request, config);
  return data;
};

export const markAllNotificationsAsRead = async (
  Authorization: string,
  updateType = 'markAllAsViewed'
) => {
  const url = `${process.env.API_URL}/notifications`;
  const config = {
    headers: {
      Authorization,
    },
  };

  const request = {
    data: {
      updateType,
      platform: 'WEB',
    },
  };

  const { data } = await axios.put(url, request, config);
  return data;
};
