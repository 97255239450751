import { CSSProperties } from 'react';

import { useUserSession } from '@reibus/frontend-utility';

import Link from 'components/SingleSpaLink';

type Props = {
  to: string;
  label: React.ReactNode;
  className?: string;
  onClick?: (e) => void;
  style?: CSSProperties;
};

const NonUserLink = ({ to, className, onClick, label, style }: Props) => {
  const { data: userSession } = useUserSession();
  const isLoggedIn = !!userSession;

  const link = isLoggedIn ? to : '/login';

  const handleClick = e => {
    if (!isLoggedIn) {
      localStorage.setItem('verified', 'true');
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Link to={link} className={className} onClick={handleClick} style={style}>
      {label}
    </Link>
  );
};

export default NonUserLink;
