const COLOR_MAP = {
  development: '#2D9D78',
  qa: '#2D6CCB',
};

type Styles = Record<string, any>;

export const updateHeaderStylesForEnvironment = (styles: Styles) => {
  if (process.env.BUILD_STAGE !== 'production') {
    styles.root = {
      ...styles.root,
      backgroundColor: COLOR_MAP[process.env.BUILD_STAGE],
      position: 'relative',
      '&::before': {
        content: `"${process.env.BUILD_STAGE}"`,
        position: 'absolute',
        color: '#FFFFFF',
      },
    };
  }

  return styles;
};
