import { NavContextProvider } from './context';
import LimitedHeader from './LimitedHeader';

const LimitedHeaderWithContext = () => (
  <NavContextProvider>
    <LimitedHeader />
  </NavContextProvider>
);

export default LimitedHeaderWithContext;
