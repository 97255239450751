import * as React from 'react';

const FlagFromImg = (flag, width = 26, height = 17) => {
  if (!flag) {
    return null;
  }

  return <img src={flag} width={width} height={height} alt={flag} />;
};

export default FlagFromImg;
