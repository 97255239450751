import type { GradeCategories } from '../types';

export const aluminizedSteel: Partial<GradeCategories> = {
  'wss-m1a357-a1/wss-m1a358-a1 ford': ['WSS-M1A357-A1', 'WSS-M1A358-A1'],
  'wsd-m1a295 ford': ['WSD-M1A295-A1', 'WSD-M1A295-A2', 'WSD-M1A295-A3'],
  'chrysler ms-2792& ms-2792c': [
    'MS2792',
    'MS2792C',
    'MS2792V-025HK',
    'MS2792V-025SK',
    'MS2792V-030HK',
    'MS2792V-030SK',
    'MS2792V-035HK',
    'MS2792V-035SK',
    'MS2792V-040HK',
    'MS2792V-040SK',
    'MS2792V-045HK',
    'MS2792V-045XK',
    'MS2792V-050XK',
  ],
  'astm a875 galfan': ['ASTMA875 GALFAN'],
  'jis g3314 aluminized': ['JIS G3314'],
  '1500p aluminized': ['1500P'],
  'din en10346 or en10327 aluminized': [
    'DX51D+AZ',
    'DX52D+AZ',
    'DX53D+AZ',
    'DX54D+AZ',
    'DX56D+AZ',
    'DX57D+AZ',
  ],
  'din en10346 or en10292 high strength': [
    'HX160YD+AZ',
    'HX180BD+AZ',
    'HX180YD+AZ',
    'HX220BD+AZ',
    'HX220PD+AZ',
    'HX220YD+AZ',
    'HX260BD+AZ',
    'HX260LAD+AZ',
    'HX260PD+AZ',
    'HX260YD+AZ',
    'HX300BD+AZ',
    'HX300LAD+AZ',
    'HX300YD+AZ',
    'HX380LAD+AZ',
    'HX420LAD+AZ',
    'HX500LAD+AZ',
  ],
  'gmw2m aluminized': [
    'GMW2M-ST-S-CR1',
    'GMW2M-ST-S-CR2',
    'GMW2M-ST-S-CR3',
    'GMW2M-ST-S-CR4',
    'GMW2M-ST-S-CR5',
  ],
  'gmw3032m bake hardenable / dent resistant': [
    'GMW3032M-ST-S-CR180B0',
    'GMW3032M-ST-S-CR180B2',
    'GMW3032M-ST-S-CR180P',
    'GMW3032M-ST-S-CR210B0',
    'GMW3032M-ST-S-CR210B2',
    'GMW3032M-ST-S-CR210P',
    'GMW3032M-ST-S-CR240B0',
    'GMW3032M-ST-S-CR240B2',
    'GMW3032M-ST-S-CR270B0',
    'GMW3032M-ST-S-CR270B2',
    'GMW3032M-ST-S-CR300B0',
    'GMW3032M-ST-S-CR300B2',
  ],
  'astma463 aluminized': [
    'ASTMA463-BHS180',
    'ASTMA463-BHS210',
    'ASTMA463-BHS240',
    'ASTMA463-BHS280',
    'ASTMA463-BHS300',
    'ASTMA463-CS TYPE A',
    'ASTMA463-CS TYPE B',
    'ASTMA463-CS TYPE C',
    'ASTMA463-DDS',
    'ASTMA463-EDDS',
    'ASTMA463-FS TYPE A',
    'ASTMA463-FS TYPE B',
    'ASTMA463-HSLAS-F50',
    'ASTMA463-HSLAS-F60',
    'ASTMA463-HSLAS-F70',
    'ASTMA463-HSLAS-F80',
    'ASTMA463-HSLAS40',
    'ASTMA463-HSLAS50',
    'ASTMA463-HSLAS55 CLASS 1|2',
    'ASTMA463-HSLAS60',
    'ASTMA463-HSLAS70',
    'ASTMA463-HSLAS80',
    'ASTMA463-SS33',
    'ASTMA463-SS37',
    'ASTMA463-SS40',
    'ASTMA463-SS50 CLASS 1|2|3|4',
    'ASTMA463-SS55',
    'ASTMA463-SS80 CLASS 1|2',
  ],
  'ms.50002 aluminized': [
    'MS.50002 CR01 AS60/60',
    'MS.50002 CR02 AS60/60',
    'MS.50002 CR04 AS60/60',
    'MS.50002 CR05 AS60/60',
    'MS.50002 CR06 AS60/60',
  ],
};
