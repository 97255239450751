import * as React from 'react';

import { ThemeProvider } from '@mui/material';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { queryClient, ldClient, useUserPreferences } from '@reibus/frontend-utility';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Header from 'components/header';
import useLaunchDarklyIdentify from 'hooks/useLaunchDarklyIdentify';
import { i18nSSR as i18n } from 'utils/i18n';

import theme from './theme';

const generateClassName = createGenerateClassName({
  productionPrefix: 'rtn',
});

const TopNavWrapper = () => {
  const { preferredLocale } = useUserPreferences();

  React.useEffect(() => {
    i18n.changeLanguage(preferredLocale);
  }, [preferredLocale]);

  return <Header />;
};

const RenderApp = () => {
  useLaunchDarklyIdentify();

  React.useEffect(() => {
    const spaNavContainer = document.querySelector('#spa-nav-container');
    const spaMainWrapper = document.querySelector('#spa-main-wrapper');

    spaNavContainer?.classList.add('spa-nav-container');
    spaMainWrapper?.classList.add('spa-main-wrapper');
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <StylesProvider generateClassName={generateClassName}>
            <TopNavWrapper />
          </StylesProvider>
        </I18nextProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default function TopNavRoot() {
  return (
    <LDProvider clientSideID={process.env.LAUNCH_DARKLY_CLIENT_ID ?? ''} ldClient={ldClient}>
      <QueryClientProvider client={queryClient}>
        <RenderApp />
      </QueryClientProvider>
    </LDProvider>
  );
}
