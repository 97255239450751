import { useUserSession } from '@reibus/frontend-utility';
import axios from 'axios';
import { useQuery } from 'react-query';

import type { FeaturedListings } from 'types';

export const useFeaturedListings = () => {
  const { data: userSession } = useUserSession();

  return useQuery<FeaturedListings | null>(
    'featuredListings',
    async () => {
      const {
        data: { featuredListings },
      } = await axios.get(`${process.env.API_URL}/listings/featured`, {
        headers: {
          Authorization: userSession?.identityToken,
          'x-deployment-region': process.env.REGION ?? 'NA',
        },
      });

      return featuredListings;
    },
    {
      enabled: !!userSession,
      refetchOnWindowFocus: false,
    }
  );
};
