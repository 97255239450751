import { useEffect, useMemo } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useCurrentUser, useUserSession } from '@reibus/frontend-utility';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import BrandClearIcon from 'components/icons/BrandClearIcon';
import NavCaret from 'components/icons/NavCaret';
import Link from 'components/SingleSpaLink';
import NonUserLink from 'components/validation/NonUserLink';
import NonUserValidator from 'components/validation/NonUserValidator';
import { useFeaturedListings } from 'hooks/useFeaturedListings';
import useWindowHref from 'hooks/useWindowHref';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';
import { hasPermissionUserType } from 'utils/userTypes';

import { useNavContext } from './context';
import { ExpandedNavTab } from './ExpandedNavTab';
import LargeDropdownMenuV2 from './LargeDropDownMenuV2';
import LocalePicker from './locale-picker';
import { navMenuData } from './navMenuData';
import NoDropDownMenuItem from './NoDropDownMenuItem';
import Notifications from './notifications';
import ProfileMenu from './profile-menu';

const dashboardDropdownItems = [
  {
    expandable: false,
    title: 'overview',
    link: '/app/dashboard/',
  },
  {
    expandable: true,
    title: 'listings',
    link: '/app/dashboard/listings',
    childrenObjs: [
      {
        title: 'myListings',
        link: '/app/dashboard/listings',
      },
      {
        title: 'myListingsBids',
        link: '/app/dashboard/activity/offers',
      },
    ],
  },
  {
    expandable: true,
    title: 'rfqs',
    link: '/app/dashboard/requests-for-quote/view',
    childrenObjs: [
      {
        title: 'myRFQs',
        link: '/app/dashboard?tab=manageRFQs',
      },
      {
        title: 'myRFQBids',
        link: '/app/dashboard/requests-for-quote/bidding',
      },
    ],
  },
];

const [dashboardLink, listingsLink, rfqsLink] = dashboardDropdownItems;

const dashboardDropdownItemsV2 = [
  dashboardLink,
  {
    ...listingsLink,
    childrenObjs: [
      {
        title: 'myListings',
        link: '/app/dashboard/listings',
      },
      {
        title: 'myListingsBids',
        link: '/app/dashboard/listings/bids', // <--- changed in v2, can deprecate original after removing rm12759MyListingBidsRedesign
      },
    ],
  },
  rfqsLink,
];

const CSE_GROUP_LABEL = 'cse';
const LAM_GROUP_LABEL = 'lam';

export const useStyles = makeStyles()({
  dashboardHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderTop: 0,
    width: '100%',
  },
  topBarLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.594rem 0 1.5rem',
  },
  topBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuItemContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuItemContainer___right: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '0.798rem 0.594rem 0.518rem 0.594rem',
    '&:hover': {
      background: '#3b0b31',
    },
  },
  menuItem__label: {
    color: '#fff',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    '& a': {
      color: '#fff',
    },
  },
  dropDown: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    position: 'relative',
    backgroundColor: '#fff',
    '& ul': {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
  },
  menuItemSmallDropDown: {
    position: 'absolute',
    top: '100%',
    zIndex: 11,
    visibility: 'hidden',
  },
  makeVisible: {
    visibility: 'visible',
  },
  dropDownBuffer: {
    height: '13px',
  },
  dropDownShadow: {
    height: '170px',
    width: '229px',
    position: 'absolute',
    boxShadow: '0px 20px 35px 0px rgb(0 0 0 / 20%)',
    top: 0,
    right: 0,
    zIndex: -1,
  },
  dropDownSingleShadow: {
    width: '229px',
    position: 'absolute',
    boxShadow: '0px 20px 35px 0px rgb(0 0 0 / 20%)',
    top: 0,
    right: 0,
    zIndex: -1,
    height: '74px',
  },
});

const DashboardNav = () => {
  const { currentDropdown, setCurrentDropdown } = useNavContext();
  const { data: currentUser } = useCurrentUser();
  const isLoggedIn = !!currentUser;
  const { data: userSession } = useUserSession();
  const { data: featuredListings } = useFeaturedListings();
  const href = useWindowHref();
  const { classes, cx } = useStyles();

  const userDisplayName = currentUser
    ? `${capitalizeFirstLetter(currentUser.firstName)} ${capitalizeFirstLetter(
        currentUser.lastName
      )}`
    : '';

  const { t } = useTranslation('dashboard-nav');

  const { rm12759MyListingBidsRedesign = false, ct235QuickFreightQuote } = useFlags();

  const flaggedDashboardDropdownItems = useMemo(
    () => (rm12759MyListingBidsRedesign ? dashboardDropdownItemsV2 : dashboardDropdownItems),
    [rm12759MyListingBidsRedesign]
  );

  const { logisticsOnly: logisticsOnlyCustomer } = hasPermissionUserType(
    currentUser?.permission.userTypes ?? []
  );

  useEffect(() => {
    // Ensure Nav menu closes when navigating to a new page
    setCurrentDropdown(null);
  }, [href]);

  const handleClickAway = e => {
    e.stopPropagation();
    setCurrentDropdown(null);
  };

  const handleReibusLogoClick = () => {
    trackEvent(TOP_MENU_CLICK_EVENT, { title: 'Reibus Logo', url: '/app/dashboard' });
  };

  const logisticsDropdownItemsDefaultUser = [
    ...(ct235QuickFreightQuote
      ? [
          {
            expandable: false,
            title: 'quickFreightQuote',
            link: '/logistics/quick-freight-quote',
          },
        ]
      : []),
    {
      expandable: false,
      title: 'bookFreight',
      link: '/logistics/request-for-quote',
    },
    {
      expandable: false,
      title: 'shipmentVisibilityDashboard',
      link: '/logistics/shipment-visibility-dashboard',
    },
  ];

  const logisticsDropdownItemsCSEUser = [
    {
      expandable: false,
      title: 'capacitySourcingEngine',
      link: '/logistics/capacity-sourcing-engine',
    },
  ];

  const logisticsDropdownItemsLAMUser = [
    {
      expandable: false,
      title: 'logisticsQuoteManager',
      link: '/logistics/quote-manager',
    },
  ];

  const isCSEUser = userSession?.cognitoGroups.includes(CSE_GROUP_LABEL);
  const isLAMUser = userSession?.cognitoGroups.includes(LAM_GROUP_LABEL);
  const logisticsNavMenuLinks = logisticsDropdownItemsDefaultUser;
  if (isCSEUser) {
    logisticsNavMenuLinks.push(...logisticsDropdownItemsCSEUser);
  }

  if (isLAMUser) {
    logisticsNavMenuLinks.push(...logisticsDropdownItemsLAMUser);
  }

  return (
    <div className={classes.dashboardHeader}>
      <div className={classes.topBarLogo}>
        <NonUserLink
          to="/app/dashboard/"
          onClick={handleReibusLogoClick}
          label={<BrandClearIcon width={130} />}
        />
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.topBarContainer}>
          <div className={classes.menuItemContainer}>
            {!logisticsOnlyCustomer ? (
              <>
                {/* Dashboard Drop Down */}
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    setCurrentDropdown('dashboard');
                  }}
                  onMouseEnter={currentDropdown ? () => setCurrentDropdown('dashboard') : undefined}
                >
                  <div className={classes.menuItem__label}>
                    <span>{t('dashboard')}</span>
                    <NavCaret />
                  </div>
                  <div
                    className={cx(classes.menuItemSmallDropDown, {
                      [classes.makeVisible]: currentDropdown === 'dashboard',
                    })}
                  >
                    <div className={classes.dropDown} onClick={handleClickAway}>
                      <ul>
                        <div className={classes.dropDownBuffer}></div>
                        {flaggedDashboardDropdownItems.map(
                          ({ expandable, link, title, childrenObjs }, index) => {
                            return (
                              <ExpandedNavTab
                                key={index}
                                expandable={expandable}
                                link={isLoggedIn ? link : '/login'}
                                title={t(title)}
                                childrenObjs={childrenObjs}
                                displayAsLink={false}
                              />
                            );
                          }
                        )}
                        <div className={classes.dropDownBuffer}></div>
                        <div className={classes.dropDownShadow}></div>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Steel, Aluminum, Stainless and RFQs Drop Down */}
                {Object.keys(navMenuData).map((item, index) => (
                  <LargeDropdownMenuV2
                    // @ts-ignore
                    categories={navMenuData[item]}
                    dropdownKey={item}
                    isListing={item !== 'rfqs'}
                    featuredListingsByFormat={
                      featuredListings ? featuredListings[item.toUpperCase()] : null
                    }
                    key={`${item}-${index}`}
                  />
                ))}
                {/* Starts Logistics DropDown */}
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    setCurrentDropdown('logistics');
                  }}
                  onMouseEnter={currentDropdown ? () => setCurrentDropdown('logistics') : undefined}
                >
                  <div className={classes.menuItem__label}>
                    <span>{t('logistics')}</span>
                    <NavCaret />
                  </div>
                  <div
                    className={cx(classes.menuItemSmallDropDown, {
                      [classes.makeVisible]: currentDropdown === 'logistics',
                    })}
                  >
                    <div className={classes.dropDown}>
                      <ul>
                        <div className={classes.dropDownBuffer}></div>
                        {logisticsNavMenuLinks.map(({ expandable, link, title }, index) => {
                          return (
                            <ExpandedNavTab
                              key={index}
                              expandable={expandable}
                              link={isLoggedIn ? link : '/login'}
                              title={t(title)}
                              displayAsLink
                            />
                          );
                        })}
                        <div className={classes.dropDownBuffer}></div>
                        <div className={classes.dropDownSingleShadow}></div>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Ends Logistics DropDown */}
              </>
            ) : (
              <>
                <div
                  className={cx(classes.menuItem, classes.menuItem__label)}
                  onClick={() =>
                    trackEvent(TOP_MENU_CLICK_EVENT, {
                      title: 'shipmentVisibilityDashboard',
                      url: '/logistics/shipment-visibility-dashboard',
                    })
                  }
                >
                  <Link to="/logistics/shipment-visibility-dashboard">
                    {t('shipmentVisibilityDashboard')}
                  </Link>
                </div>
                <div
                  className={cx(classes.menuItem, classes.menuItem__label)}
                  onClick={() =>
                    trackEvent(TOP_MENU_CLICK_EVENT, {
                      title: 'quickFreightQuote',
                      url: '/logistics/ quick-freight-quote',
                    })
                  }
                >
                  <Link to="/logistics/ quick-freight-quote',">{t('quickFreightQuote')}</Link>
                </div>
                <div
                  className={cx(classes.menuItem, classes.menuItem__label)}
                  onClick={() =>
                    trackEvent(TOP_MENU_CLICK_EVENT, {
                      title: 'bookFreight',
                      url: '/logistics/request-for-quote',
                    })
                  }
                >
                  <Link to="/logistics/request-for-quote">{t('bookFreight')}</Link>
                </div>
              </>
            )}
          </div>
          <div className={cx(classes.menuItemContainer, classes.menuItemContainer___right)}>
            <LocalePicker />
            {isLoggedIn ? (
              <ProfileMenu label={userDisplayName} onClickAway={handleClickAway} />
            ) : (
              <NoDropDownMenuItem label={t('logIn')} />
            )}
            <NonUserValidator>{!logisticsOnlyCustomer ? <Notifications /> : null}</NonUserValidator>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DashboardNav;
