import { ISO31661Alpha3Code } from '@reibus/reibus-core-utils';

import FlagFromImg from './FlagFromImg';
import * as flagSvgs from './svgs';

type NorthAmericanCountryWithFlag = ISO31661Alpha3Code & ('USA' | 'CAN' | 'MEX');

type EuropeanCountryWithFlag = ISO31661Alpha3Code &
  (
    | 'ALB'
    | 'AND'
    | 'ARE'
    | 'AUT'
    | 'BEL'
    | 'BGR'
    | 'BIH'
    | 'CHE'
    | 'CYP'
    | 'CZE'
    | 'DEU'
    | 'DNK'
    | 'DZA'
    | 'EGY'
    | 'ESP'
    | 'EST'
    | 'FIN'
    | 'FRA'
    | 'GBR'
    | 'GRC'
    | 'HRV'
    | 'HUN'
    | 'IRL'
    | 'ISL'
    | 'ITA'
    | 'KOS'
    | 'LIE'
    | 'LTU'
    | 'LUX'
    | 'LVA'
    | 'MAR'
    | 'MDA'
    | 'MKD'
    | 'MLT'
    | 'MNE'
    | 'MRT'
    | 'NLD'
    | 'NOR'
    | 'POL'
    | 'PRT'
    | 'ROU'
    | 'SMR'
    | 'SRB'
    | 'SVK'
    | 'SVN'
    | 'SWE'
    | 'TUN'
    | 'TUR'
  );

export type CountryWithFlag = EuropeanCountryWithFlag | NorthAmericanCountryWithFlag;

type FlagProps = {
  country: CountryWithFlag;
};

// eslint-disable-next-line import/namespace
const Flag = ({ country }: FlagProps) => FlagFromImg(flagSvgs[country] ?? '');

export default Flag;
