import { useMediaQuery, Theme } from '@mui/material';

import { BREAKPOINTS } from '../theme';

// should migrate to reference breakpoints via theme after migrating to BLUE_STEEL_THEME
const { sm, md } = BREAKPOINTS.values;

type ScreenSizeResponse = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

// design system compliant hook to replace `src/utils/hooks/useScreenSize.ts`
export const useScreenSize = (): ScreenSizeResponse => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(sm));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between(sm, md));

  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };
};
