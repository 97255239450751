const capitalizeFirstLetter = (value: string = '') => {
  if (!value) {
    return value;
  }

  const lowerString = value.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
};

export default capitalizeFirstLetter;
