import { Typography, Divider } from '@mui/material';
import { useCurrentUser, useUserSession } from '@reibus/frontend-utility';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import Link from 'components/SingleSpaLink';
import { TOP_MENU_CLICK_EVENT } from 'utils/events';
import { trackEvent } from 'utils/mixpanel';

import { SmallDropDown } from '../SmallDropDownV2';

interface Props {
  onClickAway: (e) => void;
}

type ListItemProps = {
  title: string;
  url: string;
  className?: string;
  locationState?: { activeTab: number };
  ariaLabel?: string;
};

const ListItem = ({ title, url, className, ariaLabel, locationState }: ListItemProps) => {
  const { t } = useTranslation('dashboard-nav');
  const clickHandler = () =>
    trackEvent(TOP_MENU_CLICK_EVENT, {
      title,
      url,
    });

  return (
    <li onClick={clickHandler} className={className}>
      <Link to={url} aria-label={ariaLabel}>
        <Typography>{t(title)}</Typography>
      </Link>
    </li>
  );
};

const useStyles = makeStyles()({
  afterDivider: {
    marginTop: '10px',
  },
  navLogout: {
    cursor: 'pointer',
  },
});

const ProfileMenu = ({ onClickAway }: Props) => {
  const { classes } = useStyles();
  const { rlo36UploadListingsPage = false } = useFlags();

  const { data: currentUser } = useCurrentUser();
  const { data: userSession } = useUserSession();

  const isSuperAdmin =
    currentUser?.permission.systemRoles?.some(
      role => role === 'ReibusCompanyAdmin' || role === 'ReibusSuperAdmin'
    ) ?? false;
  const isListingsAdmin = userSession?.cognitoGroups.includes('listings-admin');

  const logisticsOnlyCustomer =
    currentUser?.permission?.userTypes?.includes('LogisticsOnlyCustomer');

  return (
    <ul aria-label="Navigation Menu" data-testid="profile-dropdown-nav-list" onClick={onClickAway}>
      <ListItem
        key="accountSettings"
        title="accountSettings"
        url="/app/dashboard/account?selected=preferences"
      />
      {!logisticsOnlyCustomer ? (
        <>
          <ListItem
            key="myProductLines"
            title="myProductLines"
            url="/app/dashboard/?tab=productlines"
          />
          {rlo36UploadListingsPage && !isListingsAdmin ? (
            <ListItem
              key="uploadListings"
              title="uploadListings"
              url={`/app/listings/management/company/${currentUser?.company?.id ?? ''}?upload=true`}
            />
          ) : null}
        </>
      ) : null}
      <Divider />
      {isSuperAdmin ? (
        <ListItem
          key="myAccounts"
          title="myAccounts"
          url="/app/dashboard/my-accounts"
          className={classes.afterDivider}
        />
      ) : null}
      <ListItem
        key={currentUser?.company?.name ?? ''}
        title={currentUser?.company?.name ?? ''}
        url={`/app/dashboard/company/profile/${currentUser?.company?.id ?? ''}`}
        className={classes.afterDivider}
        ariaLabel="Company profile"
        locationState={{ activeTab: 0 }}
      />
      <ListItem
        key="locations"
        title="locations"
        url={`/app/dashboard/company/locations/${currentUser?.company?.id ?? ''}`}
        locationState={{ activeTab: 2 }}
      />
      <ListItem
        key="members"
        title="members"
        url={`/app/dashboard/company/members/${currentUser?.company?.id ?? ''}`}
        locationState={{ activeTab: 3 }}
      />
      <ListItem key="logout" title="logout" url="/logout" className={classes.navLogout} />
    </ul>
  );
};

export default SmallDropDown(ProfileMenu);
